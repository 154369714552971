import React, { useContext, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';
// import { GoogleMap, useLoadScript, Autocomplete, Marker, Circle, } from '@react-google-maps/api';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';


const AddLocationPopup = () => {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60',
    libraries: ['places'],
  });

  const [location, setLocation] = useState({
    place: '',
    lat: '',
    lng: '',
  });

  const { 
    showAddLocationPopup, setShowAddLocationPopup,
  } = useContext(businessContext);

  const addAutocompleteRef = useRef(null);

  // Handle autocomplete load while adding
  const handleAddAutocompleteLoad = (autocomplete) => {
    addAutocompleteRef.current = autocomplete;
  };

  // Handle place changed while adding
  const handleAddPlaceChanged = () => {
    const place = addAutocompleteRef.current.getPlace();

    if (place && place.geometry) {
      setLocation((prevState) => ({
        ...prevState,
        place: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }));
    } else {
      alert('Please select a valid location from the suggestions.');
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      <Modal show={showAddLocationPopup} onHide={() => setShowAddLocationPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-5'>Add Location</h4>

          {/* Place */}
          <Autocomplete
            className='flex-grow-1'

            onLoad={handleAddAutocompleteLoad}

            onPlaceChanged={handleAddPlaceChanged}
          >
            <input type="search" className='form-control' placeholder="Search places here..." 
              value={location.place} 
              onChange={(e) => 
                setLocation((preValue) => ({ 
                  ...preValue, 
                  place: e.target.value, 
                  lat: '',
                  lng: '',
                }))
              }
            />
          </Autocomplete>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddLocationPopup;