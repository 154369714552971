import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { ApiContext } from "../../../../UseContext/ApiCall";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import { BASEAPIURL, BASEURL, BASE_URL_STATIC } from "../../../../../../config";
import toast from "react-hot-toast";
import VisibilityPostPopup from "./Visibility/VisibilityPostPopup";
import EditPostPopup from "./EditPost/EditPostPopup";
import ReportPost from "../../../Report/ReportPost";
import ServicePost from "../../../Service/ServicePost";
import { profileContext } from "../../../../UseContext/ProfileContext";
import { Link } from "react-router-dom";
import { BlockUnblockApi } from "../../../../UseContext/ApiHelpers";
import FollowRequest from "../../../../Button/FollowRequest";
import AddToCloseFriendsPopup from "../../../CloseFriends/AddToCloseFriendsPopup";



const ProfilePostThreeDotPopup = ({ iframeCode }) => {

  const [delOpen, setDelOpen] = useState(false);
  const [isNotificationSet, setIsNotificationSet] = useState(false);
  const [showEditPostPopup, setShowEditPostPopup] = useState(false);
  const [userid, setuserID]  = useState(null);
  const [status , setStatus] = useState(0); 

  const { 
    config, loggedInUserId,
    checkFavouriteUser,checkSnoozeUser, isUserSnooze,
    setIsUnfollow,getUnfollowUsers,
    checkBlocked, isBlocked,
    setShowReportPostPopup, setShowServicePopup,
    checkUnfollow, setShowVisibilityPostPopup,
    allDataPost, setallDataPost,
    setunHide, fetchFollowStatus,
    setShowAddToCloseFriendsPopup,
    getCloseFriendsStatus,

    showProfilePostThreeDotPopup, setShowProfilePostThreeDotPopup,
    setAddToCloseFriendsUserId,
    postsPopupData, setPostsPopupData,
  } = useContext(ApiContext);

  const {
    setProfilePostData
  } = useContext(profileContext);

  // Delete Profile Post 

  // const removeNestedIframes = (text) => {
  //   const regex = /<iframe.*?<\/iframe>/g;
  //   return text.replace(regex, '');
  // };

  const HidepostV2 = (postId) =>{
    setunHide((items) =>{
      return [...items, ...allDataPost.filter(item => item._id ===postId) ]
    })
     axios.post(`${BASEAPIURL}/post/hidepost`, { postId: postId }, config).then(res =>  {
          const updatedPosts =  allDataPost.map(post => 
          post._id ===  postId ? { hide: "true" , _id :  postId } : post
        );
          setallDataPost(updatedPosts); 
          setShowProfilePostThreeDotPopup(false)
         }
       
     ).catch(err =>{
        console.error(err)
      })
    
  };

  const handleBlockUnblock = async (otherUserId) => {
    const action = isBlocked ? 'unblock' : 'block'; 

    BlockUnblockApi(otherUserId, action, config).then(res => {
      toast.success(res.message);

      checkBlocked(otherUserId);
    })
    .catch(err => {
        console.log("error", err); 
    });
  };

  const Handlesnooze = async (ids)=>{
    console.log("ids", ids);

    try {
      const data =   await axios.post(`${BASEAPIURL}/post/mutenotificationforuser`, {snoozeId : ids }, config); 
      console.log( "dataxzw", data); 
      toast.success(data.data.message);
    } catch (error) {
      console.log(error); 
    };
  };

  const handleUnfollow = (otherUserId, accountPrivacy) => {
    const  action  =   status === 0 ?  "follow"  :   status ===  4  ? "follow"  :  ( status=== 3 ? "unfollow" : "unfollow") ; 
     let  updatedPosts =   allDataPost  ; 

   

     if(action === "follow" && accountPrivacy=== "public"){
        updatedPosts = allDataPost.map(post =>
        post.postedBy === otherUserId && post.follow !== 3 ? { ...post, follow: 1 } : post
      );
    

     }
     else if(action === "follow" && accountPrivacy=== "private"){
      updatedPosts = allDataPost.map(post =>
      post.postedBy === otherUserId && post.follow !== 3 ? { ...post, follow: 3 } : post
    );
    

   }else if(action === "unfollow" &&  accountPrivacy=== "private"){
    setDelOpen(true)
 

   }
     else{
         updatedPosts = allDataPost.map(post =>
        post.postedBy === otherUserId && post.follow !== 3 ? { ...post, follow: 2 } : post
      );


     }
     

    setallDataPost(updatedPosts)

    axios.post(`${BASEAPIURL}/user/followuser`, { followingID: otherUserId, action: action },config)
      .then(res => {
        console.log("res.data.message", res.data.status); 
        setStatus(res.data.status); 
        toast.success(res.data.message);
        
        setIsUnfollow(res.data.value);
        getUnfollowUsers(config);
      })
      .catch(err => {
         console.log(err); 

      });
  };

  const InterestedorNotintersted = async(entityId, interestStatus) =>{ 
    try {
       const  data = await axios.post(`${BASEAPIURL}/post/interestednotintersted`,{ entityId :entityId, interestStatus: interestStatus }, config ); 
        console.log("dataexist", data); 
        toast.success(data.data.message);
    
    } catch (error) {
       console.log(error); 
     
    }
  };


   
  useEffect(() => {
    if (postsPopupData) {
      checkFavouriteUser(postsPopupData.userId, config);
      checkSnoozeUser(postsPopupData.userId, config);
      checkBlocked(postsPopupData.userId);
      checkUnfollow(postsPopupData.userId);
      setProfilePostData(postsPopupData)
      fetchFollowStatus(postsPopupData.postedBy,  setStatus);
      setuserID(postsPopupData.postedBy)
    };
  }, [postsPopupData]);


  return (
    <>
      {/* Popup  */}
      <>
        {/* Add To Close Friends Popup */}
        <AddToCloseFriendsPopup />

        {/* Visibility Popup */}
        <VisibilityPostPopup postsPopupData={postsPopupData} />

        {/* Repost Post Popup */}
        <ReportPost postsPopupData={postsPopupData}   setShowProfilePostThreeDotPopup={setShowProfilePostThreeDotPopup}/>

        {/* Edit Post Popup */}
        <EditPostPopup showEditPostPopup={showEditPostPopup} setShowEditPostPopup={setShowEditPostPopup} setShowProfilePostThreeDotPopup={setShowProfilePostThreeDotPopup} postsPopupData={postsPopupData} setPostsPopupData={setPostsPopupData} />

        {/* Report Service Popup */}
        <ServicePost type='post' />
        
        {/* Follow Request Popup */}
        <FollowRequest delOpen={delOpen} setDelOpen={setDelOpen} setStatus={setStatus} status={status} otherUserId={userid} />
      </>

      {
        postsPopupData && postsPopupData.userId === loggedInUserId
        ?
        // It will show to logged in user popup
        <Modal show={showProfilePostThreeDotPopup} onHide={() => setShowProfilePostThreeDotPopup(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            {/* Add to album */}
            <div className="linkBox">
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/chat-icon5.png`} alt="" />
              </div>
              <div className="textBox">
                Add to album
                <span>Add image to album.</span>
              </div>
            </div>

            {/* Edit */}
            <div className="linkBox" onClick={() => setShowEditPostPopup(true)}>
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/pen.jpg`} alt="" />
              </div>
              <div className="textBox">
                Edit post
                <span>Update this post.</span>
              </div>
            </div>

            {/* Visibility */}
            <div className="linkBox" onClick={() => setShowVisibilityPostPopup(true)}>
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/chat-icon4.png`} alt="" />
              </div>
              <div className="textBox">
                Visibility
                <span>Want to change audience, who can see this post.</span>
              </div>
            </div>

            {/* Notification */}
            <div className="linkBox">
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/popup-icon2.png`} alt="" />
              </div>
              <div className="textBox">
                Don't show notification for this post
                <span>Do not notify when someone likes or comment to this post.</span>
              </div>
            </div>

            {/* Copy link */}
            <CopyToClipboard text={`${BASEURL}/showCopyLink/${postsPopupData.postId}`} onCopy={() => toast.success('copied')}>
              <div className='linkBox' style={{ cursor: 'pointer' }}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon8.png`} alt="" />
                </div>
                <div className="textBox">
                  Copy Link
                  <span>Copy this post link & share it with your friend's and more.</span>
                </div>
              </div>
            </CopyToClipboard>

            {/* Implant */}
            <CopyToClipboard text={iframeCode} onCopy={() => toast.success('implanted')}>
            <div className="linkBox">
              <div className="imgBox">
                {/* <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" /> */}
                <span className='implant-img'>{'</>'}</span>
              </div>
              <div className="textBox">
                Implant
                <span>Want to show this post in your website.</span>
              </div>
            </div>
            </CopyToClipboard>

            {/* Delete */}
            <div className="linkBox last">
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/chat-icon12.png`} alt="" />
              </div>

              <div className="textBox">
                Delete Post
                <span>Want to delete this post.</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        :
        postsPopupData && postsPopupData.userId !== loggedInUserId
        &&
        // It will show to other user popup
        <Modal show={showProfilePostThreeDotPopup} onHide={() => setShowProfilePostThreeDotPopup(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <h4 className="mb-0">Why am I seeing this post? <small><Link>know more</Link> </small></h4>

            <h5 className="mb-4">You follow ‘<span className="text-primary">{postsPopupData.fname}</span>’</h5>

            <section className="overflow-y-scroll" style={{ maxHeight: '75vh' }}>
              {/* Close Friends */}
              <div className="d-flex align-items-center gap-3 cursor-pointer" 
                onClick={() => {setShowAddToCloseFriendsPopup(true); getCloseFriendsStatus(postsPopupData.postedBy); setAddToCloseFriendsUserId(postsPopupData.postedBy)}}
              >
                <img src={`${BASE_URL_STATIC}/popup-icon1.png`} style={{ height: '20px', width: '20px' }} alt="icon" />
                  
                <div className="">
                  <h6 className="mb-0">Add <span className="text-primary">‘{postsPopupData.fname}</span>’ to your close friends</h6>
                  <small className="text-secondary">Add to your close friend if you want to see his/her post more.</small>
                </div>
              </div>

              <hr className="hr my-1" />

              {/* Block */}
              <div className="linkBox" onClick={() => handleBlockUnblock(postsPopupData.postedBy)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/icon/friendBlock.svg`} alt="friendBlock" />
                </div>
                <div className="textBox">
                  {isBlocked ? 'Unblock' : 'Block'} ‘{postsPopupData.fname}’
                  <span>Stop seeing or contact each other.</span>
                </div>
              </div>

              {/* Add to favourites */}
              {/* <>
                {
                  <div className="linkBox" onClick={() => handleCreateFavouriteUser(postsPopupData.userId, config)}>
                    <div className="imgBox">
                      <img src={`${BASE_URL_STATIC}/popup-icon1.png`} alt="" />
                    </div>
                    {
                      isFavouriteUser
                        ?
                        <div className="textBox">
                          Add ‘{postsPopupData.fname}’ to favourites
                          <span>Add to your favourites if you want to see his/her post more.</span>
                        </div>
                        :
                        <div className="textBox">
                          Remove ‘{postsPopupData.fname}’ from favourites
                          <span>Remove from your favourites. Want less post from his/her.</span>
                        </div>
                    }
                  </div>
                }
              </> */}

              {/* Set notification for user post */}
              <div className="linkBox" onClick={() => setIsNotificationSet(!isNotificationSet)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon9.png`} alt="" />
                </div>
                <div className="textBox">
                  {isNotificationSet ? 'Unset' : 'Set'} notification for ‘{postsPopupData.fname}’ post
                  <span>Want to {isNotificationSet ? 'stop notifying' : 'get notify'}  when ‘{postsPopupData.fname}’ post something</span>
                </div>
              </div>
                
              {/* Interested */}
              <div className="linkBox"   onClick={() =>InterestedorNotintersted(postsPopupData.postedBy, "interested")}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" />
                </div>

                <div className="textBox">
                  Intrested
                  <span>Want more post like this to see in your feed.</span>
                </div>
              </div>

              {/* Not Interested */}
              <div className="linkBox" onClick={() =>InterestedorNotintersted(postsPopupData.postedBy, "not interested")}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" />
                </div>

                <div className="textBox">
                  Not Intrested
                  <span>Want less post like this to see in your feed.</span>
                </div>
              </div>

              {/* Copy Link */}
              {postsPopupData.postId && <CopyToClipboard text={`${BASEURL}/showCopyLink/${postsPopupData.postId}`} onCopy={() => toast.success('copied')}>
                <div className={postsPopupData.userId === loggedInUserId ? `linkBox last` : `linkBox`} style={{ cursor: 'pointer' }}>
                  <div className="imgBox">
                    <img src={`${BASE_URL_STATIC}/popup-icon8.png`} alt="" />
                  </div>
                  <div className="textBox">
                    Copy Link
                    <span>
                      Copy this post link & share it with your friend's and more.
                    </span>
                  </div>
                </div>
              </CopyToClipboard>}

              {/* Implant */}
              <CopyToClipboard text={iframeCode} onCopy={() => toast.success('implanted')}>
              <div  className="linkBox" >
                <div className="imgBox">
                  {/* <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" /> */}
                  <span className='implant-img'>{'</>'}</span>
                </div>
                <div className="textBox">
                  Implant
                  <span>Want to show this post in your website.</span>
                </div>
              </div>
              </CopyToClipboard>

              {/* Put user snooze for 30 days */}
              <div className="linkBox" onClick={() =>  Handlesnooze(postsPopupData.postedBy)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon2.png`} alt="" />
                </div>
                <div className="textBox">
                  {
                    isUserSnooze
                      ?
                      `Put ‘${postsPopupData.fname}’ snooze for 31 Days`
                      :
                      `Remove ‘${postsPopupData.fname}’ from snooze`
                  }
                  <span>Stop seeing post for 30 days.</span>
                </div>
              </div>

              {/* Hide This Post */}
              <>
                {
                  
                  <div className="linkBox" onClick={() => HidepostV2(postsPopupData._id)}>
                    <div className="imgBox">
                      <img src={`${BASE_URL_STATIC}/popup-icon5.png`} alt="" />
                    </div>
                    <div className="textBox">
                        Hide This Post
                      <span>Don't want to see this post</span>
                    </div>
                  </div>
                }
              </>

              {/* Unfollow */}
              <div className="linkBox" onClick={() => handleUnfollow(postsPopupData.postedBy, postsPopupData.accountPrivacy)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon3.png`} alt="" />
                </div>
                <div className="textBox">
              
                  {status === 0 ?  "Follow"  :   status ===  4  ? "Followback"  :  ( status=== 3 ? "Requested" : "Unfollow")} ‘{postsPopupData.fname}’
                  <span>Stop seeing his/her post.</span>
                </div>
              </div>

              {/* Report */}
              <div className="linkBox" onClick={() =>{ setShowReportPostPopup(true); setShowProfilePostThreeDotPopup(false) }}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon7.png`} alt="" />
                </div>
                <div className="textBox">
                  Report Post
                  <span>Do you think this post is wrong, please let us know.</span>
                </div>
              </div>

              {/* Report Service */}
              <div className="linkBox" onClick={() => setShowServicePopup(true)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/pen.jpg`} alt="pen" />
                </div>
                <div className="textBox">
                  Report Service
                  <span>Any suggestion or improvement you think to do.</span>
                </div>
              </div>
            </section>
          </Modal.Body>
        </Modal>
      }
    </>
  );
};

export default ProfilePostThreeDotPopup;