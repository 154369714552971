import React, { useContext, useEffect } from 'react';
import AddUpdateAddress from '../Popup/Profile/AddUpdateAddress';
// import LeftBlock from '../Home/LeftBlock/LeftBlock';
import { BASE_URL_STATIC } from '../../../config';
import { ScApiContext } from '../useContext/softCornerContext';


const ProfileAddress = () => {

  const {
    setShowAddAddressPopup,
    setAddress,
    addresses,
    getAddresses,
    selectAddress,
    deleteAddress,
  } = useContext(ScApiContext);

  useEffect(() => {
    getAddresses();
  }, []);

  return (
    <>
      {/* Add Popup */}
      <AddUpdateAddress />



      {/* Address */}
      <section className='vh-100 overflow-y-scroll scrollbar-hidden px-3 px-sm-4 px-xl-5 py-5'>
        {/* Image */}
        <div className='text-center mt-5'>
          <img src={`${BASE_URL_STATIC}/emailbg.jpg`} className='rounded-4 shadow' style={{ height: '30vh', width: '100%' }} alt="emailbg" />
        </div>

        {/* Addresses */}
        <div className='my-5'>
          <h4 className='mb-3'>Select Address</h4>

          {/* Home */}
          <>
            {
              addresses && addresses.length > 0
              &&
              addresses.map((value, index) => (
                <label htmlFor="home" className='d-flex align-items-center gap-3 shadow rounded-4 mb-4 px-3 py-3' key={index}>
                  <input type="radio" className='fomr-control' onClick={() => selectAddress(value._id)} checked={value.selected === 'true'} />

                  <div className='d-flex flex-column align-items-center'>
                    <img src={`${BASE_URL_STATIC}/create-icon2.png`} style={{ height: '20px', width: '20px' }} alt="create-icon2" />
                    <span>{value.category}</span>
                  </div>

                  <h5 className='mb-0 me-auto'>{value.place}</h5>

                  {/* Edit */}
                  <img src={`/images/business/icons/edit-pen.svg`} className='cursor-pointer' style={{ height: '17px', width: '17px' }} alt="edit" 
                    onClick={() => {setShowAddAddressPopup(true); setAddress(value);}} 
                  />

                  {/* Delete */}
                  <img src={`/images/business/icons/delete-red.svg`} className='cursor-pointer' style={{ height: '23px', width: '23px' }} alt="delete"
                    onClick={() => deleteAddress(value._id)}  
                  />
                </label>
              ))
            }
          </>
        </div>

        {/* Add New Address */}
        <div className='text-center py-2 rounded-5 shadow mx-auto mb-4 mb-lg-0 cursor-pointer' style={{ width: '175px' }} 
          onClick={() => {setAddress({ category: '', place: '', lat: '', lng: '' }); setShowAddAddressPopup(true)}}
        >
          <img src={`${BASE_URL_STATIC}/SoftCorner/sc-addAlbum.png`} className='mb-2' alt="add" height={25} width={25} />
          <p>Add New Address</p>
        </div>
      </section>
    </>
  );
};

export default ProfileAddress;
