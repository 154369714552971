import React, { useState, useRef } from 'react';
import { dummyMusic } from '../../Data/EducationData';
import { Modal } from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import MusicPreview from './MusicPreview';

const MusicSelect = ({ setSelectedEmoji,location, setLocation, selectedEmoji ,setOpenEmoji,openEmoji, open, close }) => {
   const [currentAudio, setCurrentAudio] = useState(null);
   const [isPlaying, setIsPlaying] = useState(false);
   const audioRefs = useRef({});
   const [selectedMusic, setSelectedMusic] = useState([]);
   const [nextBtn, setNextBtn] = useState(false);

   const handlePlayPause = (id) => {
      if (currentAudio && currentAudio !== id) {
         audioRefs.current[currentAudio].audio.current.pause();
      }

      if (currentAudio === id && isPlaying) {
         audioRefs.current[id].audio.current.pause();
         setIsPlaying(false);
      } else {
         audioRefs.current[id].audio.current.play();
         setCurrentAudio(id);
         setIsPlaying(true);
      }
   };

   const handleSelectMusic = (item) => {
      if (selectedMusic.some(e => e.id === item.id)) {
         setSelectedMusic(selectedMusic.filter(e => e.id !== item.id));
      } else {
         setSelectedMusic([...selectedMusic, item]);
      }
   };

   return (
      <div>
         <Modal show={open} onHide={close} centered keyboard={false} className="create-call" backdrop="static">
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body>
               <div>
                  <div className="row">
                     <p className="col-1" style={{ cursor: "pointer" }} onClick={() => close()}>
                        <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                     </p>
                     <p className="col-11 fs-6 text-black fw-bold text-center mb-2">
                        Post Music
                     </p>
                  </div>



                  <div style={{ height: "50vh" }} className='overflow-hidden overflow-y-scroll scrollbar-hidden px-1 px-lg-3'>
                     {dummyMusic.map((item) => (
                        <div className='row justify-content-between align-items-center my-2' key={item.id}>
                           <div className='position-relative col-12 d-flex justify-content-between align-items-center'>
                              <div style={{ cursor: "pointer" }} onClick={() => handleSelectMusic(item)} className={`w-100 d-flex gap-2 z-0 ${selectedMusic.some(e => e.id === item.id) ? "selected_music" : ""}`}>
                                 <img width={50} className='rounded-3' src="https://img.freepik.com/free-vector/gradient-album-cover-template_23-2150597431.jpg?size=338&ext=jpg&ga=GA1.1.1141335507.1717977600&semt=ais_user" alt="" />
                                 <p >
                                    <span style={{color : `${selectedMusic.some(e => e === item.id) ? "white" : ""}`}} className='d-block'>{item.title}</span>
                                    <small style={{color : `${selectedMusic.some(e => e === item.id) ? "white" : ""}`}} className='d-block text-info-emphasis'>{item.artist}</small>
                                 </p>
                              </div>
                              <small className='position-absolute top-25' style={{ cursor: "pointer" , right : "5%" }} onClick={() => handlePlayPause(item.id)}>
                                 {currentAudio === item.id && isPlaying ? (
                                    <img width={40} src="/images/pause.svg" alt="Pause" />
                                 ) : (
                                    <img width={40} src="/images/play.svg" alt="Play" />
                                 )}
                              </small>
                              <AudioPlayer
                                 className='d-none'
                                 ref={(el) => (audioRefs.current[item.id] = el)}
                                 id={`audio-player-${item.id}`}
                                 src={item.audio}
                              />
                           </div>
                        </div>
                     ))}
                  </div>

                  
                  <div className='col-8 m-auto'>
                     <button disabled={selectedMusic.length < 1} className='text_post_btn' onClick={() => {setNextBtn(true); close();}}>{`${selectedMusic.length === 0 ? 'Next' : `Next (${selectedMusic.length})`}`}</button>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
         {nextBtn && <MusicPreview location={location} setLocation={setLocation} setOpenEmoji={setOpenEmoji} setSelectedEmoji = {setSelectedEmoji} selectedEmoji = {selectedEmoji} setSelectedMusic={setSelectedMusic} openEmoji={openEmoji} selectedMusic={selectedMusic} open={nextBtn} close={() => setNextBtn(false)} />}
      </div>
   );
};

export default MusicSelect;
