import React, { useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { ApiContext } from "../../UseContext/ApiCall";
import LeftBlockSkeleton from "../../Skeleton/RightSection/LeftBlockSkeleton";
import { AwsFileUrl, BASE_URL_STATIC } from "../../../../config";
import { useLocation } from 'react-router-dom';
import { update_profile_image } from "../../UseContext/ApiHelpers";
import toast from "react-hot-toast";
import imageCompression from 'browser-image-compression';
// import ProfileFollowerPopup from "../../Popup/Profile/ProfileUser/ProfileFollowerPopup";
// import ProfileFollowingPopup from "../../Popup/Profile/ProfileUser/ProfileFollowingPopup";
import { profileContext } from "../../UseContext/ProfileContext";
import FollowerFollowingPopup from "../../Popup/Profile/ProfileUser/FollowerFollowingPopup";



const LeftBlock = () => {

  // const [showFollowerPopup, setShowFollowerPopup] = useState(false);
  // const [showFollowingPopup, setShowFollowingPopup] = useState(false); 

  const { 
    config, loggedInUserId, setLoggedInUserImage,
    followerList, profileOption,
    emiloLeftBlockData,
    getEmiloLeftBlockData,
  } = useContext(ApiContext);

  const {
    setShowFollowerFollowingTab,
    setShowFollowerFollowingPopup,
    getFollowers,
    getFollowings,
  } = useContext(profileContext);

  let location = useLocation();

  const pathParts = location.pathname.split('/');
  const lastPart = pathParts[pathParts.length - 1];

  const profileInputRef = useRef(null);

  // upload profile image
  const uploadProfileImage = async(e) => {
    const compressedPhotoAndVideo = await imageCompression(e.target.files[0], profileOption);

    update_profile_image(compressedPhotoAndVideo, config).then((res) => {
      if(res.status===201){
        setLoggedInUserImage(res.data.url);
        toast.success(res.data.message)
      } else{
        toast.error("failed to update profile image")
      };
    })
  };

  useEffect(() => {
    getEmiloLeftBlockData();
  }, []);


  return (
    <>
     {/* <ProfileFollowerPopup showFollowerPopup={showFollowerPopup} setShowFollowerPopup={setShowFollowerPopup} /> */}

     {/* <ProfileFollowingPopup showFollowingPopup={showFollowingPopup} setShowFollowingPopup={setShowFollowingPopup} /> */}

     {/* Followers & Following Popup */}
     <FollowerFollowingPopup />

      {
        emiloLeftBlockData
        ?
        <section className="vh-100 pt-100 overflow-scroll scrollbar-hidden">
          {/* Profile, Follower, Following and Posts */}
          <>
            {
              emiloLeftBlockData
              &&
              <>
                {/* Profile */}
                <div className="text-center">
                  <div className="mt-3 mb-4">
                    <div className="border rounded-circle mx-auto position-relative" style={{ height: '125px', width: '125px' }}>
                      <img className="h-100 w-100 rounded-circle object-fit-cover" src={AwsFileUrl+emiloLeftBlockData.profile} alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`} />

                      <i className="far fa-camera position-absolute top-100 start-50 translate-middle text-white p-2 rounded-circle" onClick={() => profileInputRef.current.click()}  style={{ cursor: "pointer", backgroundColor: '#5f6268fc' }} />
                      <input type="file" accept="image/*" ref={profileInputRef} onChange={uploadProfileImage} style={{ display: "none", visibility: "none" }} />
                    </div>
                  </div>

                  <h4 className="fw-bold fs-5 mb-0">{emiloLeftBlockData.name || '----------'}</h4>
                  <span>{emiloLeftBlockData.userName || '----------'}</span>
                </div>

                {/* Follower, Following and Posts */}
                <div className="my-5 d-flex justify-content-evenly text-center">
                  {/* Follower */}
                  <div className="lb-ffp px-1 py-3 rounded-4 shadow cursor-pointer position-relative" 
                    onClick={() =>{setShowFollowerFollowingPopup(true); setShowFollowerFollowingTab('followers'); getFollowers('', 0, loggedInUserId);}}
                  >
                    <div className="lb-fb-box position-absolute top-0 start-50 translate-middle w-100" >
                      {
                        followerList
                        &&
                        followerList.slice(1, 5).map((follower, index) => (
                          follower.imageUrl
                          ?
                          <img key={index} className="lb-f-img" src={follower.imageUrl} alt={follower.userId} onError={(e) => e.target.src=`${BASE_URL_STATIC}/user-icon${index+1}.jpg`} />
                          :
                          <img key={index} className="lb-f-img" src={`${BASE_URL_STATIC}/dummy.png`} alt="dummy" />
                        ))
                      }
        
                      {
                        followerList
                        ?
                          (followerList.length - 4) >= 0
                          ?
                          <small className="lb-f-count">{followerList.length - 4}</small>
                          :
                          <small className="lb-f-count">0</small>
                        :
                        <small className="lb-f-count">0</small>
                      }
                    </div>

                    <p>{emiloLeftBlockData.followersCount || 0}</p>
                    <small className="lb-ffp-text text-secondary">Followers</small>
                  </div>

                  {/* Following */}
                  <div className="lb-ffp px-1 py-3 rounded-4 shadow cursor-pointer" 
                    onClick={() =>{setShowFollowerFollowingPopup(true); setShowFollowerFollowingTab('followings'); getFollowings('', 0, loggedInUserId);}}
                  >
                    <p>{emiloLeftBlockData.followingsCount || 0}</p>
                    <small className="lb-ffp-text text-secondary">Following</small>
                  </div>

                  {/* Posts */}
                  <div className="lb-ffp px-1 py-3 rounded-4 shadow cursor-pointer">
                    <p>{emiloLeftBlockData.postsCount || 0}</p>
                    <small className="lb-ffp-text text-secondary">Posts</small>
                  </div>
                </div>
              </>
            }
          </>

          {/* Options */}
          <ul className="w-75 mx-auto mb-5">
            <li className="mb-4">
                {
                  lastPart === ''
                  ?
                  <Link exact="true" to="/">
                    <img className="me-3" style={{ height: '25px', width: '25px' }} src={`${BASE_URL_STATIC}/icon/home-home-2.svg`} alt="home" />
                    <span>Home</span>
                  </Link>
                  :
                  <Link exact="true" to="/">
                    <img className="me-3" style={{ height: '25px', width: '25px' }} src={`${BASE_URL_STATIC}/menu-icon1.png`} alt="home" />
                    <span className="text-dark">Home</span>
                  </Link>
                }
            </li>

            <li className="mb-4">
                {
                  lastPart === 'explore'
                  ?
                  <Link to="/explore">
                    <img className="me-3" style={{ height: '25px', width: '25px' }} src={`${BASE_URL_STATIC}/icon/home-explore-2.svg`} alt="" />
                    <span>Explore</span>
                  </Link>
                  :
                  <Link to="/explore">
                    <img className="me-3" style={{ height: '25px', width: '25px' }} src={`${BASE_URL_STATIC}/menu-icon2.png`} alt="" />
                    <span className="text-dark">Explore</span>
                  </Link>
                }
            </li>

            <li className="mb-4">
                {
                  lastPart === 'reels'
                  ?
                  <Link to="/flix">
                    <img className="me-3" style={{ height: '25px', width: '25px' }} src={`${BASE_URL_STATIC}/icon/home-flix-2.svg`} alt="" />
                    <span>Flix</span>
                  </Link>
                  :
                  <Link to="/flix">
                    <img className="me-3" style={{ height: '25px', width: '25px' }} src={`${BASE_URL_STATIC}/menu-icon3.png`} alt="" />
                    <span className="text-dark">Flix</span>
                  </Link>
                }
            </li>

            <li className="mb-4">
                {
                  lastPart === 'memes'
                  ?
                  <Link to="/memes">
                    <img className="me-3" style={{ height: '25px', width: '25px' }} src={`${BASE_URL_STATIC}/icon/home-meme-2.svg`} alt="" />
                    <span>Memes</span>
                  </Link>
                  :
                  <Link to="/memes">
                    <img className="me-3" style={{ height: '25px', width: '25px' }} src={`${BASE_URL_STATIC}/menu-icon5.png`} alt="" />
                    <span className="text-dark">Memes</span>
                  </Link>
                }
            </li>

            <li className="mb-4">
                {
                  lastPart === 'bestclips'
                  ?
                  <Link to="/bestclips">
                    <img className="me-3" style={{ height: '25px', width: '25px' }} src={`${BASE_URL_STATIC}/icon/home-bestclip-2.svg`} alt="" />
                    <span>Bestclips</span>
                  </Link>
                  :
                  <Link to="/bestclips">
                    <img className="me-3" style={{ height: '25px', width: '25px' }} src={`${BASE_URL_STATIC}/menu-icon6.png`} alt="" />
                    <span className="text-dark">Bestclips</span>
                  </Link>
                }
            </li>
            
            <li className="mb-4">
              <Link to={`/business/basic-info`}>
                <img className="me-3" style={{ height: '25px', width: '25px' }} src={`/images/business/icons/create-page.svg`} alt="create page" />
                <span className="text-dark">Create Page</span>
              </Link>
            </li>

            <li className="mb-5">
              <Link to={`/profile/${loggedInUserId}`}>
                <img className="me-3" style={{ height: '25px', width: '25px' }} src={`${BASE_URL_STATIC}/menu-icon7.png`} alt="" />
                <span className="text-dark">Profile</span>
              </Link>
            </li>
          </ul>

          {/* Footer */}
          <div className="w-100 p-2 mx-auto bg-light">
            <Link to="/privacyPolicy" className="text-dark text-decoration-underline me-3">Privacy</Link>
            <Link to="/terms" className="text-dark text-decoration-underline me-3">Terms</Link>
            <Link to="/" className="text-dark text-decoration-underline me-3">Advertising</Link>
            <Link to="/about" className="text-dark text-decoration-underline me-3">About</Link>
            <Link to="/" className="text-dark text-decoration-underline me-3">Career</Link>
            <Link to="/ReportAnything" className="text-dark text-decoration-underline ">Help</Link>

            <small className="d-block">E-Milo &copy; {new Date().getFullYear()} - All Rights Reserved.</small>
          </div>
        </section>
        : 
        <LeftBlockSkeleton />
      }
    </>
  );
}

export default LeftBlock;
