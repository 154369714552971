import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
// import { useHistory } from 'react-router-dom';
import { myProfile } from "../../../UseContext/ApiHelpers";
import axios from "axios";
import { profileContext } from "../../../UseContext/ProfileContext";
import { BASE_URL_STATIC, BASEAPIURL } from "../../../../../config";
import { ApiContext } from "../../../UseContext/ApiCall";
import { FaPen } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";


const PersonalDetailPopup = ({ show, handleClose, userProfile }) => {

  console.log('userProfile : ', userProfile);
  

  const { setUserProfile } = useContext(profileContext);
  const { config } = useContext(ApiContext);
  const [flipInput, setFlipInput] = useState(false)
  const [usernameData, setUsernameData] = useState('')
  const [btn, setBtn] = useState(false)
  const [errMsg, seterrMsg] = useState("The minimum required length for a user name is five characters")
  const [errColor, setErrColor] = useState("")
  const [fnameErr, setfnameErr] = useState(null)
  // const [userUpdateData, setUserUpdateData] = useState({});

  // console.log('usernameData : ', usernameData);

  const [userInput, setuserInput] = useState({
    fname: "",
    mobile: "",
    dob: "",
    gender: "",
    maritial: "",
    spouseName: "",
    maritualDate: null,
  });

  useEffect(() => {
    if (userProfile) {
      setuserInput((prev) => ({
        ...prev, fname: userProfile.fname,
        mobile: userProfile.mobile,
        dob: userProfile.dob,
        gender: userProfile.gender,
        maritial: userProfile.maritial,
        maritualDate: userProfile.maritualDate,
        spouseName: userProfile.spouse,
      }))
    }
  }, [userProfile]);
  console.log("user", userInput)

  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
    .toISOString()
    .split('T')[0]; // Format the date to YYYY-MM-DD

  // useEffect(() => {
  //   myProfile().then((res) => { setUserProfile(res); setUsernameData(res && res.username) });
  // }, []);

  function handleChange(e) {
    if (e.target.name === "fname") {
      setfnameErr(null)
      const nameParts = e.target.value.trim().split(" ");
      if (nameParts.length < 2) {
        setfnameErr("Please enter your full name (at least two words).");
      }
    }

    if (e.target.name === "maritial" && e.target.value === "Un-Married") {
      setuserInput({ ...userInput, maritualDate: null, spouseName: "", [e.target.name]: e.target.value });
    } else {
      setuserInput({ ...userInput, [e.target.name]: e.target.value });
    }
  }

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      // Assuming education is an array of education objects
    const res =   await axios.post(
        `${BASEAPIURL}/user/updateuserprofile`,
        {
          // mobile: userInput.mobile,
          dob: new Date(userInput.dob) || userProfile.dob,
          gender: userInput.gender || userProfile.gender,
          maritial: userInput.maritial,
          spouse: userInput.spouseName,
          maritualDate: userInput.maritualDate,
          username: usernameData ? usernameData : userProfile.username,
          mobile: userProfile.mobile,
          fname: userInput.fname
        },
        config
      );

      return res

    } catch (error) {
      return null;
    }
  };


  const handleUserNameChange = async (e) => {
    const value = e.target.value;
    if (value.length >= 5) {
      if (value.indexOf(" ") === -1) {
        try {
          const check = await axios.post(`${BASEAPIURL}/user/findusername`, { username: value }, config);
          if (check.data.message) {
            seterrMsg(check.data.message);
            setErrColor("green");
            setUsernameData(value);
            setBtn(false);
          }
        } catch (error) {
          setErrColor("red");
          seterrMsg(error.response.data.message);
          setBtn(true);
        }
      } else {
        seterrMsg("Username doesn't contain spaces");
        setErrColor("red");
        setBtn(true);
      }
    } else {
      seterrMsg("");
      setErrColor("");
      setBtn(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    updateUser(e).then((r) => {
      if (r.status === 200) {
        myProfile(config).then((res) => {
          handleClose();
          setUserProfile(res);
          setFlipInput(false);
          setfnameErr(null)
          setUsernameData("");
          seterrMsg("The minimum required length for a user name is five characters"); setErrColor("")
        })
      }
    }
    ).catch(error =>{
        console.log(error)
    })

  }

  return (
    <>
      <Modal show={show} onHide={() => { handleClose(); setfnameErr(null) }} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h5 >Personal Info</h5>
          <form
            method="POST"
            onSubmit={handleSubmit}
          >
            <div className="form-group d-flex flex-column justify-content-center">
              <div
                className="row"
                style={{
                  display: "flex",
                  padding: "10px 0px 0px 25px",
                }}
              >
                <div className="">
                  <div
                    className="d-flex"
                    style={{
                      width: "fit-content",
                      marginRight: "5px",
                      background: "#f5f5f5",
                      padding: "6px",
                      borderRadius: "10px",
                    }}
                  >
                    <img style={{ width: "14px", margin: "0px 10px" }} src={`${BASE_URL_STATIC}/icon/name.svg`} alt="" />
                    <p
                      style={{
                        margin: "auto",
                        color: "#9f9797b8",
                      }}
                    >
                      Name
                    </p>
                  </div>
                </div>

                <div className="col-lg-11">
                  {/* <span style={{ border: '0px 0px 1px 0px solid black' }}>
                    {user.fname}
                    </span> */}
                  <input
                    onChange={handleChange}
                    type="text"
                    name="fname"
                    required
                    style={{
                      border: "2px solid white",
                      fontWeight: "500",
                      borderBottom: "2px solid #f3f3f3",
                      lineHeight: "50px",
                    }}
                    className="form-control"
                    placeholder="Name"
                    defaultValue={userProfile && userProfile.fname}
                  />
                </div>

                {fnameErr && <p className="fs-12 text-center text-danger">{fnameErr}</p>}

              </div>

              <div
                className="row"
                style={{
                  display: "flex",
                  padding: "10px 0px 0px 25px",
                }}
              >
                <div className="">
                  <div
                    className="d-flex"
                    style={{
                      width: "fit-content",
                      marginRight: "5px",
                      background: "#f5f5f5",
                      padding: "6px",
                      borderRadius: "10px",
                    }}
                  >
                    <img style={{ width: "14px", margin: "0px 10px" }} src={`${BASE_URL_STATIC}/icon/name.svg`} alt="" />
                    <p
                      style={{
                        margin: "auto",
                        color: "#9f9797b8",
                      }}
                    >
                      Username
                    </p>
                  </div>
                </div>

                <div className="col-lg-11 position-relative">

                  {!flipInput && <span className="position-absolute" style={{ cursor: "pointer", right: "0px", top: "-31px" }} onClick={() => { setFlipInput(true); setBtn(true) }}><FaPen color="#d3d3d3" /> </span>}
                  {flipInput && <span className="position-absolute" style={{ cursor: "pointer", right: "0px", top: "-31px" }} onClick={() => setFlipInput(false)}><IoMdClose color="#d3d3d3" /> </span>}

                  {!flipInput ?
                    <div style={{
                      border: "2px solid white",
                      borderBottom: "2px solid #f3f3f3",
                      lineHeight: "50px",
                    }}>
                      {userProfile && userProfile.username}
                    </div>
                    :
                    <>
                      <input
                        type="text"
                        style={{
                          border: "2px solid #f3f3f3",
                          lineHeight: "50px",
                          margin: "8px 0px"
                        }}
                        required
                        className="form-control"
                        placeholder="username123"
                        onChange={handleUserNameChange}
                      />
                      <p className="text-center">
                        <span className="fs-12" style={{ color: `${errColor}` }}>{errMsg}</span>
                      </p>
                    </>
                  }
                </div>
              </div>

              <div
                className="row"
                style={{
                  display: "flex",
                  padding: "10px 0px 0px 25px",
                }}
              >
                <div className="">
                  <div
                    className="d-flex"
                    style={{
                      width: "fit-content",
                      marginRight: "5px",
                      background: "#f5f5f5",
                      padding: "6px",
                      borderRadius: "10px",
                    }}
                  >
                    <img style={{ width: "14px", margin: "0px 10px" }} src={`${BASE_URL_STATIC}/icon/gender.svg`} alt="" />
                    <p
                      style={{
                        margin: "auto",
                        color: "#9f9797b8",

                      }}
                    >
                      Gender
                    </p>
                  </div>
                </div>
                <div className="col-lg-11">
                  <select
                    className="form-control"
                    name="gender"
                    defaultValue={userProfile && userProfile.gender}
                    required
                    onChange={handleChange}
                    style={{
                      border: "0px",
                      borderBottom: "2px solid #f3f3f3",
                      marginTop: "10px",
                      fontSize: "15px"
                    }}
                  >
                    {/* {userProfile.gender ? <option value={userProfile.gender}>{userProfile.gender}</option> : ( */}
                    <>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="other">Other</option>
                    </>

                    {/* )} */}
                  </select>
                </div>
              </div>

              <div
                className="row"
                style={{
                  display: "flex",
                  padding: "10px 0px 0px 25px",
                }}
              >
                <div className="w-fit">
                  <div
                    className="d-flex"
                    style={{
                      width: "fit-content",
                      marginRight: "5px",
                      background: "#f5f5f5",
                      padding: "6px",
                      borderRadius: "10px",
                    }}
                  >
                    <img style={{ width: "14px", margin: "0px 10px" }} src={`${BASE_URL_STATIC}/icon/calender.svg`} alt="" />
                    <p
                      style={{
                        margin: "auto",
                        color: "#9f9797b8",

                      }}
                    >
                      Date of Birth
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="col-lg-12">
                  <input
                    type="date"
                    name="dob"
                    min="1924-01-01"
                    max={maxDate}
                    style={{
                      border: "2px solid white",
                      borderBottom: "2px solid #f3f3f3",
                      lineHeight: "50px",
                    }}
                    className="form-control"
                    defaultValue={userProfile && userProfile.dob ? userProfile.dob.split('T')[0] : ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  padding: "10px 0px 0px 25px",
                }}
              >
                <div className="w-fit">
                  <div
                    className="d-flex"
                    style={{
                      width: "fit-content",
                      marginRight: "5px",
                      background: "#f3f3f3",
                      padding: "6px",
                      borderRadius: "10px",
                    }}
                  >
                    <img style={{ width: "14px", margin: "0px 10px" }} src={`${BASE_URL_STATIC}/icon/married.svg`} alt="" />
                    <p
                      style={{
                        margin: "auto",
                        color: "#9f9797b8",

                      }}
                    >
                      Martial Status
                    </p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="col-lg-12 mt-3">

                    <h6
                      style={{
                        fontWeight: "400",
                        color: "#b3afaf",
                        fontSize: "14px",
                      }}
                    >
                      status
                    </h6>
                    <div className="col-lg-10">
                      <select
                        name="maritial"
                        className="form-control form-box"
                        defaultValue={(userProfile && userProfile.maritial) || ""}
                        onChange={handleChange}
                        // onBlur={(e) => {
                        //     setMarried(e.target.value);
                        // }}
                        style={{
                          height: "50px",
                          border: "0px",
                          borderBottom: "2px solid #f3f3f3",
                        }}
                      >

                        {/* {userProfile.maritial ? <option value={userProfile.maritial}>{userProfile.maritial}</option> : ( */}
                        <>
                          <option value="" disabled>select</option>
                          <option value="Un-Married">Un-Married</option>
                          <option value="Married">Married</option>
                        </>

                        {/* )} */}
                      </select>
                    </div>
                  </div>
                  {
                    userInput.maritial === "Married" &&
                    <>
                      {/* spouse name */}
                      <div className="col-lg-12 mt-3">
                        <h6
                          style={{
                            fontWeight: "500",
                            color: "#b3afaf",
                            fontSize: "14px",
                          }}
                        >
                          spouse name
                        </h6>
                        <div className="col-lg-10">
                          <input
                            name="spouseName"
                            onChange={handleChange}
                            defaultValue={userProfile && userProfile.spouse && userProfile.spouse}
                            type="text"
                            style={{
                              border: "2px solid white",
                              borderBottom: "2px solid #f3f3f3",
                              lineHeight: "50px",
                            }}
                            className="form-control"
                            placeholder="Name"
                          // value={.spouseName}
                          />
                        </div>
                      </div>

                      {/* marriage date */}
                      <div className="col-lg-12 mt-3">
                        <h6
                          style={{
                            fontWeight: "400",
                            color: "#b3afaf",
                            fontSize: "14px",
                          }}
                        >
                          Marriage Date
                        </h6>
                        <div className="col-lg-10">
                          <input
                            type="date"
                            name="maritualDate"
                            max={new Date().toISOString().split('T')[0]}
                            style={{
                              border: "2px solid white",
                              borderBottom: "2px solid #f3f3f3",
                              lineHeight: "50px",
                            }}
                            className="form-control"
                            defaultValue={userProfile && userProfile.maritualDate && userProfile.maritualDate.split('T')[0]}
                            // value={userInput.dob}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </>
                  }
                </div>

                <div
                  className="col-lg-10 d-flex"
                  style={{
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >

                  <button disabled={btn} type="submit" className="edit-modal-btn" >
                    Save
                  </button>
                </div>
              </div>
            </div>

          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PersonalDetailPopup;
