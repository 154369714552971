import React, { useContext, useEffect } from 'react';
import { AwsFileUrl } from '../../../../config';
import { ApiContext } from '../../UseContext/ApiCall';
// import FollowUnfollowButton from '../../Button/FollowUnfollowButton';
import { useNavigate } from 'react-router-dom';


const Suggestions = () => {

  const navigate = useNavigate();

  const {
    suggestedUsers,
    showSuggestedLoadMore,
    getSuggestedUsers,
    handleFollowUnfollow,
  } = useContext(ApiContext);

  useEffect(() => {
    if(suggestedUsers.length === 0) {
      getSuggestedUsers(0);
    };
  }, []);

  return (
    <>
      <section>
        <h6 className='mb-3 text-center bg-white p-2 fw-bold'>Suggestions</h6>

        <div className={`overflow-y-scroll pe-2`} style={{ maxHeight: '60vh' }}>
          {
            suggestedUsers && suggestedUsers.length > 0
            && 
            <>
              {
                suggestedUsers.map((value, index) => (
                  <div className='mb-3 d-flex align-items-center gap-3' key={index}>
                    <img src={AwsFileUrl+value.profile} className='rounded-circle border cursor-pointer' style={{ height: '43px', width: '43px' }} onClick={() => navigate(`/profile/${value._id}`)} alt="profile" />
    
                    <div className='me-auto'>
                      <h6 className='mb-0 fw-semibold cursor-pointer' onClick={() => navigate(`/profile/${value._id}`)}>{value.name}</h6>
                      <span className='text-secondary cursor-pointer' onClick={() => navigate(`/profile/${value._id}`)}>{value.userName || '----------'}</span>
                    </div>

                    <button type="button" className='btn btn-primary btn-sm text-nowrap' onClick={() => handleFollowUnfollow(value._id)}>{value.buttonText}</button>
                  </div>
                ))
              }

              {
                showSuggestedLoadMore
                &&
                <div className='text-center '>
                  <button type="button" className='btn btn-primary btn-sm mb-1' onClick={() => getSuggestedUsers(suggestedUsers.length)}>Load More</button>
                </div>
              }
            </>
          }
        </div>
      </section>
    </>
  );
};

export default Suggestions;