import React, { useContext,useEffect, useState } from 'react'
import { AwsFileUrl } from '../../../config'
import { getSoftCornerProfile } from '../../Emilo/UseContext/ScApiHelpers'
import { ScApiContext } from '../useContext/softCornerContext'
import { ApiContext } from '../../Emilo/UseContext/ApiCall'
import {Link, useLocation } from 'react-router-dom'



const ProfileCenterBlock = () => {

  const [userProfile, setUserProfile] = useState(null)
  const [activeNav , setActiveNav] = useState('verify')
  const [partnerName , setPartnername] = useState("") 

  const { scConfig } = useContext(ScApiContext)
  const { loggedInUserId } = useContext(ApiContext)
  const location = useLocation();

  useEffect(() => {
    getSoftCornerProfile(loggedInUserId, scConfig).then((res) => {
      setPartnername(res.partnerName)
      setUserProfile(res.getProfile)
    })
  }, [scConfig])

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const lastPart = pathParts[pathParts.length - 1];
    setActiveNav(lastPart)
  },[location])

  const menu = [
    {
      icon: '/images/icons/address-sc.svg',
      title: 'Address',
      description: 'Please enter the shipping address, ensuring accurate details for smooth and timely delivery.',
      route: 'address',
    },
    {
      icon: '/images/icons/address-sc.svg',
      title: 'Setting',
      description: 'Please enter the shipping address, ensuring accurate details for smooth and timely delivery.',
      route: 'setting',
    },
    {
      icon: '/images/icons/payment-sc.svg',
      title: 'Payment',
      description: 'Kindly provide your payment details for a seamless transaction process.',
      route: 'payment',
    },
  ];

  return (
    <>
      <section className='vh-100 overflow-y-scroll scrollbar-hidden pt-5 px-3 px-sm-4 px-xl-5 pb-5 pb-sm-0'>
        {/* Div 1 */}
        <div className='d-flex align-items-center mt-5 mt-md-5'>
         {userProfile && <img src={AwsFileUrl + userProfile.profileImage} className='rounded-circle me-3' alt="profile" height={50} width={50} />}

          <div className=''>
            <h4 className=''>{userProfile && userProfile.fname}</h4>
            <span className=''>{userProfile && userProfile.username}</span>
          </div>
        </div>

        <hr className='hr bg-secondary my-4' />

        {/* Div 2 */}
        <div>
          <h4 className='mb-2 mb-lg-3'>Profile</h4>

          {/* Verify Identity */}
          <Link to={`/SoftCorner/profile/verify`} className={`${(activeNav === 'profile' || activeNav === 'verify') ? "selected-sc" : 'text-dark'} d-flex align-items-center justify-content-start px-3 px-sm-4 px-xl-5 py-2 py-lg-3 mb-2`} >
            <div className='me-3' >
              <img src={`/images/icons/profile-sc.svg`} alt="tag" />
            </div>

            <div>
              <h4 className='mb-0'>Change Profile Pic</h4>
              <span className='fs-14'>To authenticate your identity, kindly complete the verification process by scanning a live image.</span>
            </div>
          </Link>

          {/* Address, Setting & Payment */}
          <>
            {
              menu.map((value, index) => (
                <Link to={`/SoftCorner/profile/${value.route}`} key={index}
                  className={`${activeNav === value.route ? "selected-sc" : 'text-dark'} d-flex align-items-center justify-content-start px-3 px-sm-4 px-xl-5 py-2 py-lg-3 mb-2`}
                >
                  <div className='me-3' >
                    <img src={value.icon} alt="tag" />
                  </div>

                  <div>
                    <h4 className='mb-0'>{value.title}</h4>
                    <span className='fs-14'>{value.description}</span>
                  </div>
                </Link>
              ))
            }
          </>
        </div>

        <hr className='hr bg-secondary my-4' />

        {/* Div 3 */}
        <div className='mb-3'>
          <h4 className='mb-2'>Account</h4>

          <div className=''>
            {partnerName !== "" &&  <Link to={`/SoftCorner/Profile/Remove`} className='text-dark d-block'> Remove <b> &nbsp; &quot;{partnerName}&quot; &nbsp;</b> From Soft Corner </Link>}
            <Link to={`/SoftCorner/Profile/Privacy`} className='text-dark d-block'>Privacy</Link>
            <Link to={`/SoftCorner/Profile/About`} className='text-dark d-block'>About</Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProfileCenterBlock