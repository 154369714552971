import React, { useContext, useEffect } from 'react'
import { BASE_URL_STATIC } from '../../../../../config';
import { ApiContext } from '../../../UseContext/ApiCall';
import { settingContext } from '../../../UseContext/SettingContext';
import SettingInputUser from './SettingInputUser';
import { getValue, getAdded } from '../../../UseContext/SettingHelper';



const AccountProfile = () => {

  const { 
    // ---------- Value ----------

    value, setValue,

    handleOptionChange,

    // ---------- Followers Except ----------

    setAdded,

  } = useContext(settingContext);

  console.log('value : ', value);

  // Messages to show according to option
  const message = {
    public: 'Everyone on EMILO can view your',
    closeFriends: 'Only your close friends can view your',
    followers: 'Only your followers can view your',
    fof: 'Your followers & as well as their followers can view your',
    none: 'No one can view your',
  };

  const { config, loggedInUserName, loggedInUserImage, loggedInUser } = useContext(ApiContext);


  // To get profile info Added (Value, Followers Except and Hide From )
  useEffect(() => {
    // To get Value
    getValue('profile', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });

    // To get added (Followers Except and Hide From)
    getAdded('profile', config)
    .then(res => {

      // Update state with the fetched data
      setAdded(prevState => ({
        ...prevState,
        ...res.data.message
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);
  
  return (
    <>
      <section className='vh-100 pt-5 px-2 px-sm-3 px-lg-4 px-xl-5 mb-5 mb-lg-0 overflow-scroll scrollbar-hidden' id='accordionToggle1'>
        {/* Breadcrumb */}
        <nav className='pt-4 pt-lg-5'>
          <ol className="breadcrumb bg-transparent p-0">
            <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
            <li className="breadcrumb-item active" aria-current="page">Basic Info</li>
          </ol>
        </nav>

        {/* Profile */}
        <div className='text-center mb-5'>
          <img className='ps-profile rounded-circle' src={loggedInUserImage} alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`}  />

          <h4 className='fw-bold mt-3'>{loggedInUserName}</h4>

          <span className='text-secondary'>Raipur, Chhattisgarh</span>
        </div>

        {/* --- personal info --- */}
        <div className='accordion accordion-flush mb-3'>
          <div className='shadow-sm accordion-level-1 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#personalInfo">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
            <h5 className='fw-semibold ms-3'>Who can view your personal info?</h5>
          </div>

          <div id='personalInfo' className="accordion-collapse ms-3" data-bs-parent="#accordionToggle1" >
            {/* DOB */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4' data-bs-toggle="collapse" data-bs-target="#dob">Date of Birth</h5>

              <div id='dob' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-dob-public" name="dob" value='public' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.dob === 'public' || !value.dob}  
                    />
                    <label className="form-check-label" htmlFor="ps-dob-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-dob-fof" name="dob" value='fof' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.dob === 'fof'} 
                    /> 
                    <label className="form-check-label" htmlFor="ps-dob-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-dob-followers" name="dob" value='followers' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.dob === 'followers'}  
                    />
                    <label className="form-check-label" htmlFor="ps-dob-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-dob-cf" name="dob" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.dob === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-dob-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-dob-following" name="dob" value='none' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.dob === 'none'} 
                    />  
                    <label className="form-check-label" htmlFor="ps-dob-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.dob ? value.dob : "followers"]} Date of Birth.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.dob) || (value.dob === 'public') || (value.dob === 'followers') || (value.dob === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.dob || value.dob === 'public') ? 'dobPublic' : 
                          value.dob === 'followers' ? 'dobFollowers' : 
                          value.dob === 'fof' && 'dobFOF'
                        }
                        settingType={value.dob ? value.dob : 'public'} 
                        header={
                          (!value.dob || value.dob === 'public') ? 'Hide date of birth from specific people' :
                          value.dob === 'followers' ? 'Followers Except' :
                          value.dob === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your date of birth' 
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Marital Status */}
            <div className='mb-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#maritalStatus">Marital Status</h5>

              <div id='maritalStatus' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-maritalStatus-public" name="maritalStatus" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.maritalStatus === 'public' || !value.maritalStatus} />
                    <label className="form-check-label" htmlFor="ps-maritalStatus-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-maritalStatus-fof" name="maritalStatus" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.maritalStatus === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-maritalStatus-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-maritalStatus-followers" name="maritalStatus" value='followers' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.maritalStatus === 'followers'}  
                    />
                    <label className="form-check-label" htmlFor="ps-maritalStatus-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-maritalStatus-cf" name="maritalStatus" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.maritalStatus === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-maritalStatus-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-maritalStatus-following" name="maritalStatus" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.maritalStatus === 'none'} />
                    <label className="form-check-label" htmlFor="ps-maritalStatus-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.maritalStatus ? value.maritalStatus : 'followers']} Marital Status.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.maritalStatus) || (value.maritalStatus === 'public') || (value.maritalStatus === 'followers') || (value.maritalStatus === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.maritalStatus || value.maritalStatus === 'public') ? 'maritalStatusPublic' : 
                          value.maritalStatus === 'followers' ? 'maritalStatusFollowers' : 
                          value.maritalStatus === 'fof' && 'maritalStatusFOF'
                        }
                        settingType={value.maritalStatus ? value.maritalStatus : 'public'} 
                        header={
                          (!value.maritalStatus || value.maritalStatus === 'public') ? 'Hide marital status from specific people' :
                          value.maritalStatus === 'followers' ? 'Followers Except' :
                          value.maritalStatus === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your marital status' 
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Spouse Name */}
            <div className='mb-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#spouseName">Spouse Name</h5>

              <div id='spouseName' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-spouseName-public" name="spouseName" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.spouseName === 'public' || !value.spouseName} />
                    <label className="form-check-label" htmlFor="ps-spouseName-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-spouseName-fof" name="spouseName" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.spouseName === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-spouseName-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-spouseName-followers" name="spouseName" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.spouseName === 'followers'}  />
                    <label className="form-check-label" htmlFor="ps-spouseName-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-spouseName-cf" name="spouseName" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.spouseName === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-spouseName-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-spouseName-following" name="spouseName" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.spouseName === 'none'} />
                    <label className="form-check-label" htmlFor="ps-spouseName-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.spouseName ? value.spouseName : 'followers']} Spouse Name.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.spouseName) || (value.spouseName === 'public') || (value.spouseName === 'followers') || (value.spouseName === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.spouseName || value.spouseName === 'public') ? 'spouseNamePublic' : 
                          value.spouseName === 'followers' ? 'spouseNameFollowers' : 
                          value.spouseName === 'fof' && 'spouseNameFOF'
                        }
                        settingType={value.spouseName ? value.spouseName : 'public'} 
                        header={
                          (!value.spouseName || value.spouseName === 'public') ? 'Hide spouse name from specific people' :
                          value.spouseName === 'followers' ? 'Followers Except' :
                          value.spouseName === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your spouse name'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Marriage Date */}
            <div className='mb-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#marriageDate">Marriage Date</h5>

              <div id='marriageDate' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-marriageDate-public" name="marriageDate" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.marriageDate === 'public' || !value.marriageDate} />
                    <label className="form-check-label" htmlFor="ps-marriageDate-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-marriageDate-fof" name="marriageDate" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.marriageDate === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-marriageDate-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-marriageDate-followers" name="marriageDate" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.marriageDate === 'followers'}  />
                    <label className="form-check-label" htmlFor="ps-marriageDate-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-marriageDate-cf" name="marriageDate" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.marriageDate === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-marriageDate-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-marriageDate-following" name="marriageDate" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.marriageDate === 'none'} />
                    <label className="form-check-label" htmlFor="ps-marriageDate-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.marriageDate ? value.marriageDate : 'followers']} Marriage Date.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.marriageDate) || (value.marriageDate === 'public') || (value.marriageDate === 'followers') || (value.marriageDate === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.marriageDate || value.marriageDate === 'public') ? 'marriageDatePublic' : 
                          value.marriageDate === 'followers' ? 'marriageDateFollowers' : 
                          value.marriageDate === 'fof' && 'marriageDateFOF'
                        }
                        settingType={value.marriageDate ? value.marriageDate : 'public'} 
                        header={
                          (!value.marriageDate || value.marriageDate === 'public') ? 'Hide marriage date from specific people' :
                          value.marriageDate === 'followers' ? 'Followers Except' :
                          value.marriageDate === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your marriage date'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>
          </div>
        </div>

        {/* --- contact info --- */}
        <div className='accordion accordion-flush mb-3'>
          <div className='shadow-sm accordion-level-1 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#contactInfo">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
            <h5 className='fw-semibold ms-3'>Who can view your contact info?</h5>
          </div>

          <div id='contactInfo' className="accordion-collapse ms-3" data-bs-parent="#accordionToggle1" >
            {/* Mobile Number */}
            <div className='my-3 accordion accordion-flush'> 
              <h5 className='shadow-sm accordion-level-4' data-bs-toggle="collapse" data-bs-target="#mobileNumber">Mobile Number</h5>

              <div id='mobileNumber' className="accordion-collapse collapse show mt-3 ms-3 me-0">
                <span className='py-1'>Current mobile no. is <span className='text-primary'>{loggedInUser && loggedInUser.mobile}</span></span>

                {/* Value */}
                <div className='row w-100'>
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-mn-public" name="mobileNumber" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.mobileNumber === 'public' || !value.mobileNumber} />
                    <label className="form-check-label" htmlFor="ps-mn-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-mn-fof" name="mobileNumber" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.mobileNumber === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-mn-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-mn-followers" name="mobileNumber" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.mobileNumber === 'followers'} />
                    <label className="form-check-label" htmlFor="ps-mn-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-mn-cf" name="mobileNumber" value='closeFriends' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.mobileNumber === 'closeFriends'} />
                    <label className="form-check-label" htmlFor="ps-mn-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-mn-none" name="mobileNumber" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.mobileNumber === 'none'} />
                    <label className="form-check-label" htmlFor="ps-mn-none">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.mobileNumber ? value.mobileNumber : "none"]} Mobile No.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.mobileNumber) || (value.mobileNumber === 'public') || (value.mobileNumber === 'followers') || (value.mobileNumber === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.mobileNumber || value.mobileNumber === 'public') ? 'mobileNumberPublic' : 
                          value.mobileNumber === 'followers' ? 'mobileNumberFollowers' : 
                          value.mobileNumber === 'fof' && 'mobileNumberFOF'
                        }
                        settingType={value.mobileNumber ? value.mobileNumber : 'public'} 
                        header={
                          (!value.mobileNumber || value.mobileNumber === 'public') ? 'Hide mobile number from specific people' :
                          value.mobileNumber === 'followers' ? 'Followers Except' :
                          value.mobileNumber === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your mobile number'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Email Id */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4' data-bs-toggle="collapse" data-bs-target="#emailId">Email Id</h5>

              <div id='emailId' className="accordion-collapse collapse show mt-3 ms-3 me-0">
                <span className='py-1'>Current email id is <span className='text-primary'>{loggedInUser && loggedInUser.email}</span></span>

                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-eid-public" name="emailId" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.emailId === 'public' || !value.emailId} />
                    <label className="form-check-label" htmlFor="ps-eid-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-eid-fof" name="emailId" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.emailId === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-eid-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-eid-followers" name="emailId" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.emailId === 'followers'} />
                    <label className="form-check-label" htmlFor="ps-eid-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-eid-cf" name="emailId" value='closeFriends' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.emailId === 'closeFriends'} />
                    <label className="form-check-label" htmlFor="ps-eid-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-eid-none" name="emailId" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.emailId === 'none'} />
                    <label className="form-check-label" htmlFor="ps-eid-none">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.emailId ? value.emailId : "none"] } Email Id.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.emailId) || (value.emailId === 'public') || (value.emailId === 'followers') || (value.emailId === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.emailId || value.emailId === 'public') ? 'emailIdPublic' : 
                          value.emailId === 'followers' ? 'emailIdFollowers' : 
                          value.emailId === 'fof' && 'emailIdFOF'
                        }
                        settingType={value.emailId ? value.emailId : 'public'} 
                        header={
                          (!value.emailId || value.emailId === 'public') ? 'Hide email id from specific people' :
                          value.emailId === 'followers' ? 'Followers Except' :
                          value.emailId === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your email id'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>
          </div>
        </div>

        {/* --- other info --- */}
        <div className='mb-3 accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#otherInfo">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
            <h5 className='fw-semibold ms-3'>Who can view your other info?</h5>
          </div>

          <div id='otherInfo' className="accordion-collapse ms-3" data-bs-parent="#accordionToggle1">
            {/* Place Lived */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#placeLived">Place Lived</h5>

              <div id='placeLived' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-placeLived-public" name="placeLived" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.placeLived === 'public' || !value.placeLived} />
                    <label className="form-check-label" htmlFor="ps-placeLived-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-placeLived-fof" name="placeLived" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.placeLived === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-placeLived-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-placeLived-followers" name="placeLived" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.placeLived === 'followers'}  />
                    <label className="form-check-label" htmlFor="ps-placeLived-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-placeLived-cf" name="placeLived" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.placeLived === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-placeLived-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-placeLived-following" name="placeLived" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.placeLived === 'none'} />
                    <label className="form-check-label" htmlFor="ps-placeLived-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.placeLived ? value.placeLived : 'followers']} Place Lived.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.placeLived) || (value.placeLived === 'public') || (value.placeLived === 'followers') || (value.placeLived === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.placeLived || value.placeLived === 'public') ? 'placeLivedPublic' : 
                          value.placeLived === 'followers' ? 'placeLivedFollowers' : 
                          value.placeLived === 'fof' && 'placeLivedFOF'
                        }
                        settingType={value.placeLived ? value.placeLived : 'public'} 
                        header={
                          (!value.placeLived || value.placeLived === 'public') ? 'Hide place lived from specific people' :
                          value.placeLived === 'followers' ? 'Followers Except' :
                          value.placeLived === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your place lived'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Education */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#education">Education</h5>

              <div id='education' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-education-public" name="education" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.education === 'public' || !value.education} />
                    <label className="form-check-label" htmlFor="ps-education-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-education-fof" name="education" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.education === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-education-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-education-followers" name="education" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.education === 'followers'}  />
                    <label className="form-check-label" htmlFor="ps-education-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-education-cf" name="education" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.education === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-education-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-education-following" name="education" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.education === 'none'} />
                    <label className="form-check-label" htmlFor="ps-education-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.education ? value.education : 'followers']} Education.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.education) || (value.education === 'public') || (value.education === 'followers') || (value.education === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.education || value.education === 'public') ? 'educationPublic' : 
                          value.education === 'followers' ? 'educationFollowers' : 
                          value.education === 'fof' && 'educationFOF'
                        }
                        settingType={value.education ? value.education : 'public'} 
                        header={
                          (!value.education || value.education === 'public') ? 'Hide education from specific people' :
                          value.education === 'followers' ? 'Followers Except' :
                          value.education === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your education'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Work */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#work">Work</h5>

              <div id='work' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-work-public" name="work" value='public' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.work === 'public' || !value.work} 
                    />
                    <label className="form-check-label" htmlFor="ps-work-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-work-fof" name="work" value='fof' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.work === 'fof'} 
                    />
                    <label className="form-check-label" htmlFor="ps-work-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-work-followers" name="work" value='followers' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.work === 'followers'}  
                    />
                    <label className="form-check-label" htmlFor="ps-work-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-work-cf" name="work" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.work === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-work-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-work-following" name="work" value='none' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.work === 'none'} 
                    />
                    <label className="form-check-label" htmlFor="ps-work-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.work ? value.work : 'followers']} Work Place.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.work) || (value.work === 'public') || (value.work === 'followers') || (value.work === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.work || value.work === 'public') ? 'workPublic' : 
                          value.work === 'followers' ? 'workFollowers' : 
                          value.work === 'fof' && 'workFOF'
                        }
                        settingType={value.work ? value.work : 'public'} 
                        header={
                          (!value.work || value.work === 'public') ? 'Hide work from specific people' :
                          value.work === 'followers' ? 'Followers Except' :
                          value.work === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your work'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Skills */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#skills">Skills</h5>

              <div id='skills' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-skills-public" name="skills" value='public' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.skills === 'public' || !value.skills} 
                    />
                    <label className="form-check-label" htmlFor="ps-skills-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-skills-fof" name="skills" value='fof' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.skills === 'fof'} 
                    />
                    <label className="form-check-label" htmlFor="ps-skills-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-skills-followers" name="skills" value='followers' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.skills === 'followers'}  
                    />
                    <label className="form-check-label" htmlFor="ps-skills-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-skills-cf" name="skills" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.skills === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-skills-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-skills-following" name="skills" value='none' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.skills === 'none'} 
                    />
                    <label className="form-check-label" htmlFor="ps-skills-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.skills ? value.skills : 'followers']} Skills.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.skills) || (value.skills === 'public') || (value.skills === 'followers') || (value.skills === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.skills || value.skills === 'public') ? 'skillsPublic' : 
                          value.skills === 'followers' ? 'skillsFollowers' : 
                          value.skills === 'fof' && 'skillsFOF'
                        }
                        settingType={value.skills ? value.skills : 'public'} 
                        header={
                          (!value.skills || value.skills === 'public') ? 'Hide email id from specific people' :
                          value.skills === 'followers' ? 'Followers Except' :
                          value.skills === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your email id'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Hobbies */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#hobbies">Hobbies</h5>

              <div id='hobbies' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hobbies-public" name="hobbies" value='public' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hobbies === 'public' || !value.hobbies} 
                    />
                    <label className="form-check-label" htmlFor="ps-hobbies-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hobbies-fof" name="hobbies" value='fof' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hobbies === 'fof'} 
                    />
                    <label className="form-check-label" htmlFor="ps-hobbies-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hobbies-followers" name="hobbies" value='followers' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hobbies === 'followers'}  
                    />
                    <label className="form-check-label" htmlFor="ps-hobbies-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hobbies-cf" name="hobbies" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hobbies === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-hobbies-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hobbies-following" name="hobbies" value='none' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hobbies === 'none'} 
                    />
                    <label className="form-check-label" htmlFor="ps-hobbies-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.hobbies ? value.hobbies : 'followers']} Hobbies.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.hobbies) || (value.hobbies === 'public') || (value.hobbies === 'followers') || (value.hobbies === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.hobbies || value.hobbies === 'public') ? 'hobbiesPublic' : 
                          value.hobbies === 'followers' ? 'hobbiesFollowers' : 
                          value.hobbies === 'fof' && 'hobbiesFOF'
                        }
                        settingType={value.hobbies ? value.hobbies : 'public'} 
                        header={
                          (!value.hobbies || value.hobbies === 'public') ? 'Hide hobbies from specific people' :
                          value.hobbies === 'followers' ? 'Followers Except' :
                          value.hobbies === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your hobbies'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Most Hangout Places */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#hangoutPlaces">Most Hangout Places</h5>

              <div id='hangoutPlaces' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hangoutPlaces-public" name="hangoutPlaces" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hangoutPlaces === 'public' || !value.hangoutPlaces} />
                    <label className="form-check-label" htmlFor="ps-hangoutPlaces-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hangoutPlaces-fof" name="hangoutPlaces" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hangoutPlaces === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-hangoutPlaces-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hangoutPlaces-followers" name="hangoutPlaces" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hangoutPlaces === 'followers'}  />
                    <label className="form-check-label" htmlFor="ps-hangoutPlaces-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hangoutPlaces-cf" name="hangoutPlaces" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hangoutPlaces === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-hangoutPlaces-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hangoutPlaces-following" name="hangoutPlaces" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hangoutPlaces === 'none'} />
                    <label className="form-check-label" htmlFor="ps-hangoutPlaces-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.hangoutPlaces ? value.hangoutPlaces : 'followers']} Most Hangout Places.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.hangoutPlaces) || (value.hangoutPlaces === 'public') || (value.hangoutPlaces === 'followers') || (value.hangoutPlaces === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.hangoutPlaces || value.hangoutPlaces === 'public') ? 'hangoutPlacesPublic' : 
                          value.hangoutPlaces === 'followers' ? 'hangoutPlacesFollowers' : 
                          value.hangoutPlaces === 'fof' && 'hangoutPlacesFOF'
                        }
                        settingType={value.hangoutPlaces ? value.hangoutPlaces : 'public'} 
                        header={
                          (!value.hangoutPlaces || value.hangoutPlaces === 'public') ? 'Hide hangout places from specific people' :
                          value.hangoutPlaces === 'followers' ? 'Followers Except' :
                          value.hangoutPlaces === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your hangout places'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Zodiac Sign */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#zodiacSign">Zodiac Sign</h5>

              <div id='zodiacSign' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-zodiacSign-public" name="zodiacSign" value='public' onChange={(e) => handleOptionChange(e, 'profile')} 
                      checked={value.zodiacSign === 'public' || !value.zodiacSign} 
                    />
                    <label className="form-check-label" htmlFor="ps-zodiacSign-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-zodiacSign-fof" name="zodiacSign" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} 
                      checked={value.zodiacSign === 'fof'} 
                    />
                    <label className="form-check-label" htmlFor="ps-zodiacSign-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-zodiacSign-followers" name="zodiacSign" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} 
                      checked={value.zodiacSign === 'followers'}  
                    />
                    <label className="form-check-label" htmlFor="ps-zodiacSign-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-zodiacSign-cf" name="zodiacSign" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.zodiacSign === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-zodiacSign-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-zodiacSign-following" name="zodiacSign" value='none' onChange={(e) => handleOptionChange(e, 'profile')} 
                      checked={value.zodiacSign === 'none'} 
                    />
                    <label className="form-check-label" htmlFor="ps-zodiacSign-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.zodiacSign ? value.zodiacSign : 'followers']} Most Hangout Places.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.zodiacSign) || (value.zodiacSign === 'public') || (value.zodiacSign === 'followers') || (value.zodiacSign === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.zodiacSign || value.zodiacSign === 'public') ? 'zodiacSignPublic' : 
                          value.zodiacSign === 'followers' ? 'zodiacSignFollowers' : 
                          value.zodiacSign === 'fof' && 'zodiacSignFOF'
                        }
                        settingType={value.zodiacSign ? value.zodiacSign : 'public'} 
                        header={
                          (!value.zodiacSign || value.zodiacSign === 'public') ? 'Hide zodiac sign from specific people' :
                          value.zodiacSign === 'followers' ? 'Followers Except' :
                          value.zodiacSign === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your zodiac sign'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Other Social Media Links */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#socialMedia">Other Social Media Links</h5>

              <div id='socialMedia' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-socialMedia-public" name="socialMedia" value='public' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.socialMedia === 'public' || !value.socialMedia} 
                    />
                    <label className="form-check-label" htmlFor="ps-socialMedia-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-socialMedia-fof" name="socialMedia" value='fof' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.socialMedia === 'fof'} 
                    />
                    <label className="form-check-label" htmlFor="ps-socialMedia-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-socialMedia-followers" name="socialMedia" value='followers' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.socialMedia === 'followers'}  
                    />
                    <label className="form-check-label" htmlFor="ps-socialMedia-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-socialMedia-cf" name="socialMedia" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.socialMedia === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-socialMedia-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-socialMedia-following" name="socialMedia" value='none' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.socialMedia === 'none'} 
                    />
                    <label className="form-check-label" htmlFor="ps-socialMedia-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.socialMedia ? value.socialMedia : 'followers']} Other Social Media Links.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.socialMedia) || (value.socialMedia === 'public') || (value.socialMedia === 'followers') || (value.socialMedia === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.socialMedia || value.socialMedia === 'public') ? 'socialMediaPublic' : 
                          value.socialMedia === 'followers' ? 'socialMediaFollowers' : 
                          value.socialMedia === 'fof' && 'socialMediaFOF'
                        }
                        settingType={value.socialMedia ? value.socialMedia : 'public'} 
                        header={
                          (!value.socialMedia || value.socialMedia === 'public') ? 'Hide social media from specific people' :
                          value.socialMedia === 'followers' ? 'Followers Except' :
                          value.socialMedia === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your social media'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Family And Relationship */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#familyRelationship">Family And Relationship</h5>

              <div id='familyRelationship' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-familyRelationship-public" name="familyRelationship" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.familyRelationship === 'public' || !value.familyRelationship} />
                    <label className="form-check-label" htmlFor="ps-familyRelationship-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-familyRelationship-fof" name="familyRelationship" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.familyRelationship === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-familyRelationship-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-familyRelationship-followers" name="familyRelationship" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.familyRelationship === 'followers'}  />
                    <label className="form-check-label" htmlFor="ps-familyRelationship-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-familyRelationship-cf" name="familyRelationship" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.familyRelationship === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-familyRelationship-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-familyRelationship-following" name="familyRelationship" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.familyRelationship === 'none'} />
                    <label className="form-check-label" htmlFor="ps-familyRelationship-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.familyRelationship ? value.familyRelationship : 'followers']} Family And Relationship.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.familyRelationship) || (value.familyRelationship === 'public') || (value.familyRelationship === 'followers') || (value.familyRelationship === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.familyRelationship || value.familyRelationship === 'public') ? 'familyRelationshipPublic' : 
                          value.familyRelationship === 'followers' ? 'familyRelationshipFollowers' : 
                          value.familyRelationship === 'fof' && 'familyRelationshipFOF'
                        }
                        settingType={value.familyRelationship ? value.familyRelationship : 'public'} 
                        header={
                          (!value.familyRelationship || value.familyRelationship === 'public') ? 'Hide family and relationship from specific people' :
                          value.familyRelationship === 'followers' ? 'Followers Except' :
                          value.familyRelationship === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your family and relationship'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Favourite Movie */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#favouriteMovie">Favourite Movie</h5>

              <div id='favouriteMovie' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-favouriteMovie-public" name="favouriteMovie" value='public' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.favouriteMovie === 'public' || !value.favouriteMovie} 
                    />
                    <label className="form-check-label" htmlFor="ps-favouriteMovie-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-favouriteMovie-fof" name="favouriteMovie" value='fof' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.favouriteMovie === 'fof'} 
                    />
                    <label className="form-check-label" htmlFor="ps-favouriteMovie-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-favouriteMovie-followers" name="favouriteMovie" value='followers' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.favouriteMovie === 'followers'}  
                    />
                    <label className="form-check-label" htmlFor="ps-favouriteMovie-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-favouriteMovie-cf" name="favouriteMovie" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.favouriteMovie === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-favouriteMovie-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-favouriteMovie-following" name="favouriteMovie" value='none' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.favouriteMovie === 'none'} 
                    />
                    <label className="form-check-label" htmlFor="ps-favouriteMovie-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.favouriteMovie ? value.favouriteMovie : 'followers']} Favourite Movie.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.favouriteMovie) || (value.favouriteMovie === 'public') || (value.favouriteMovie === 'followers') || (value.favouriteMovie === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.favouriteMovie || value.favouriteMovie === 'public') ? 'favouriteMoviePublic' : 
                          value.favouriteMovie === 'followers' ? 'favouriteMovieFollowers' : 
                          value.favouriteMovie === 'fof' && 'favouriteMovieFOF'
                        }
                        settingType={value.favouriteMovie ? value.favouriteMovie : 'public'} 
                        header={
                          (!value.favouriteMovie || value.favouriteMovie === 'public') ? 'Hide favourite movie from specific people' :
                          value.favouriteMovie === 'followers' ? 'Followers Except' :
                          value.favouriteMovie === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your favourite movie'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>

            {/* Favourite Food */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#favouriteFood">Favourite Food</h5>

              <div id='favouriteFood' className="accordion-collapse collapse show ms-3 mt-3">
                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-favouriteFood-public" name="favouriteFood" value='public' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.favouriteFood === 'public' || !value.favouriteFood} 
                    />
                    <label className="form-check-label" htmlFor="ps-favouriteFood-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-favouriteFood-fof" name="favouriteFood" value='fof' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.favouriteFood === 'fof'} 
                    />
                    <label className="form-check-label" htmlFor="ps-favouriteFood-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-favouriteFood-followers" name="favouriteFood" value='followers' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.favouriteFood === 'followers'}  
                    />
                    <label className="form-check-label" htmlFor="ps-favouriteFood-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-favouriteFood-cf" name="favouriteFood" value='closeFriends' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.favouriteFood === 'closeFriends'} 
                    />
                    <label className="form-check-label" htmlFor="ps-favouriteFood-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-favouriteFood-following" name="favouriteFood" value='none' 
                      onChange={(e) => handleOptionChange(e, 'profile')} checked={value.favouriteFood === 'none'} 
                    />
                    <label className="form-check-label" htmlFor="ps-favouriteFood-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.favouriteFood ? value.favouriteFood : 'followers']} Favourite Food.)</small>

                  <hr className='hr bg-secondary mt-3 ms-2 mb-3' style={{ width: '-webkit-fill-available' }}  />
                </div>

                {/* Input */}
                <>
                  {
                    ((!value.favouriteFood) || (value.favouriteFood === 'public') || (value.favouriteFood === 'followers') || (value.favouriteFood === 'fof'))
                    &&
                    <>
                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          (!value.favouriteFood || value.favouriteFood === 'public') ? 'favouriteFoodPublic' : 
                          value.favouriteFood === 'followers' ? 'favouriteFoodFollowers' : 
                          value.favouriteFood === 'fof' && 'favouriteFoodFOF'
                        }
                        settingType={value.favouriteFood ? value.favouriteFood : 'public'} 
                        header={
                          (!value.favouriteFood || value.favouriteFood === 'public') ? 'Hide favourite food from specific people' :
                          value.favouriteFood === 'followers' ? 'Followers Except' :
                          value.favouriteFood === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your favourite food'
                      />

                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                    </>
                  }
                </>
              </div>
            </div>
          </div>
        </div>




        {/* Who can view your common info? */}
        <div className=''>
          <div>Note : - </div>
        </div>
      </section>
    </>
  );
};

export default AccountProfile;