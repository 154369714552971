import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
// import BestClipThumbnail from "../../../ThumbnailExtractor/BestClipThumbnail";
import AddTags from "../../../Button/AddTags";
import { ApiContext } from "../../../UseContext/ApiCall";
import toast from "react-hot-toast";
import EmojiPicker from 'emoji-picker-react';
import { BASE_URL_STATIC, BASEAPIURL } from "../../../../../config";
import BestclipAdvanceSettingPopup from "./BestclipAdvanceSettingPopup";
import NotificationpostPopup from "../../Notification/NotificationpostPopup";
import { createNotification } from "../../../UseContext/ApiHelpers";

const CreateBestClips2 = ({ selectedCategory, setSelectedCategory, setBestClips,
  bestClips,
  setShowCreateBestClipsPopup1,
}) => {
  const { config, setallDataPost,partnerId, setscAllposts, loggedInUserId, postType, setPosts } = useContext(ApiContext);
  // const [showCreateBestClipsEditPopup, setShowCreateBestClipsEditPopup] =
  //   useState(false);
  const bestClipArray = Array.from(bestClips);
  const [description, setDescription] = useState("");
  const [sliderClip, setSliderClip] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [tagsByCarouselItem, setTagsByCarouselItem] = useState([]);
  const [setfeelingActivity] = useState(null);
  const [openEmoji, setOpenEmoji] = useState(false)


  const clearObjectURLs = () => {
    sliderClip.forEach((url) => URL.revokeObjectURL(url));
  };

  useEffect(() => {
    const clipsArray = Array.from(bestClips); // Convert FileList to an array

    if (Array.isArray(clipsArray) && clipsArray.length > 0) {
      const urls = clipsArray.map((file) => URL.createObjectURL(file));

      setSliderClip(urls);

      return clearObjectURLs;
    }
  }, [bestClips]);

  useEffect(() => {
    if (Array.isArray(bestClips)) {
      const urls = bestClips.map((file) => {
        const objectURL = URL.createObjectURL(file);
        return objectURL;
      });
      setSliderClip(urls);

      return () => {
        urls.forEach((url) => URL.revokeObjectURL(url));
      };
    }
  }, [bestClips]);

  useEffect(() => {
    if (bestClips.length) {
      setShowCreateBestClipsPopup1(true);
    }
    setLoader(false)
    // setfeelingActivity(null)
  }, [])




  const handleCreateBestClipsPopupPost = async () => {
    if (selectedCategory !== null) {
      setLoader(true);
      let mediaUrls = [];
      let uploadResponse = '';
      try {
        for (let i = 0; i < bestClipArray.length; i++) {

          const formData = new FormData();

          formData.append("mediaUrls", bestClipArray[0]);
          formData.append("thumbnail", selectedImage[0]);
          formData.append("postTumbnailUrl", selectedImage[0]);
          formData.append("audio", '')
          formData.append("start", '')
          formData.append("end", '')

          // Make the API call to upload each file
          uploadResponse = await axios.post(`${BASEAPIURL}/post/postdataurl`, formData, config);

          // Get the media URL from the response and push it to mediaUrls array
          const uploadsdata = uploadResponse.data.mediaUrls[0]; // Access mediaUrls correctly

          mediaUrls.push(uploadsdata);
        };

        const mediaPostData = {
          description: description,
          maintype: postType,
          partnerUserId : partnerId , 
          // title: selectedAddLocation.place,
          title: "",
          // emotion: selectedEmoji.emotion,
          // emotionName: selectedEmoji.emotionName,
          // emotionIcon: selectedEmoji.icon,
          mediaUrls: mediaUrls,
          postTumbnailUrl: uploadResponse.data.postTumbnailUrl || '',
          category: selectedCategory.parentCategoryName,
          type: 'bestclips'
        };

        // Call the API to create the media post
        const datamediaPostData = await axios.post(`${BASEAPIURL}/post/createmediapost`, mediaPostData, config);

        const savedPost = datamediaPostData.data.post;

        // Handle uploaded files info and tags
        const uploadedFilesInfo = mediaUrls; // Use mediaUrls, not uploadResponse anymore

        const finalResponse = uploadedFilesInfo.map((fileInfo, index) => {
          const tags = tagsByCarouselItem[index] || [];

          return {
            ...fileInfo,
            tags: tags.map(tag => ({
              x: tag.xVal,
              y: tag.yVal,
              tagUserId: tag.tagUserId,
              fname: tag.fname,
              username: tag.username,
              profileImg: tag.profileImg,
              postedBy: loggedInUserId,
            })),
          };
        });

        await axios.post(`${BASEAPIURL}/post/updatetagpost`, {
          id: savedPost._id,
          mediaUrls: finalResponse,
        }, config);

        const finalData = {
          ...savedPost,
          mediaUrls: finalResponse,
        };
        const taguserids = finalResponse[0].tags.map(item => item.tagUserId);
        if (postType === 'postType') {
          setallDataPost(prev => [finalData, ...prev]);
        } else if (postType === 'scType') {
          setscAllposts(prev => [finalData, ...prev])
        } else {
          setPosts(prev => [finalData, ...prev])
        }

        async function createNotifications() {
          for (let ids of taguserids) {
            const data = {
              targetId: ids,
              userType: "user",
              targetEvent: "tag",
              targetType: savedPost.type,
              targetEventId: savedPost._id,
              notificationType: "email",
            };

            try {
              await createNotification(data, config);

            } catch (error) {
              console.error("Error creating notification for user", ids, error);
            }
          }
        }
        createNotifications();
        setBestClips([])
        setShowCreateBestClipsPopup1(false);
        setSelectedCategory(null);

      } catch (error) {
        setLoader(false);
        setSelectedCategory(null);
        toast.error("failed to post bestclip")
      }
    } else {
      setLoader(false);
      toast.error("please select category")
    }
  };

  const handleEmojiPick = (e) => {
    setDescription(prev => prev + e.emoji)
  };

  // ----- Advance Setting ----- 

  const [showBestclipAdvanceSettingPopup, setShowBestclipAdvanceSettingPopup] = useState(false);

  return (
    <>
      <BestclipAdvanceSettingPopup
        showBestclipAdvanceSettingPopup={showBestclipAdvanceSettingPopup}
        setShowBestclipAdvanceSettingPopup={setShowBestclipAdvanceSettingPopup}
        setShowCreateBestClipsPopup1={setShowCreateBestClipsPopup1}
      />
      <NotificationpostPopup />
      <section id="create-reels-1">
        <div className="flex justify-content-center ">
          <AddTags
            fileUrl={sliderClip}
            selectphotoAndVideo={bestClips}
            tagsByCarouselItem={tagsByCarouselItem}
            setTagsByCarouselItem={setTagsByCarouselItem}
            setfeelingActivity={setfeelingActivity}
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
          />
        </div>
        
        {/* <BestClipThumbnail selectedImage={selectedImage} setSelectedImage={setSelectedImage} file={bestClipArray[0]} status={status} setStatus={setStatus} /> */}

        <div className="mt-3 text-center">
          <p className="fw-bold fs-14 text-start">
            Title/Description
          </p>

          <form className="desp-form" >
            <label htmlFor="textArea"></label>
            <textarea className="text-dark"
              name="textArea"
              id="textArea"
              cols="8"
              rows="3"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Add a title or description for your clip"
            ></textarea>
            <button type="button" style={{ right: "15px" }} className="smiley" >
              <img src={`${BASE_URL_STATIC}/emoji-smiley.png`} onClick={() => setOpenEmoji(!openEmoji)} alt="" />
            </button>

            <span className='emoji-select-desp'>
              {openEmoji && <EmojiPicker onEmojiClick={(res) => handleEmojiPick(res)} />}
            </span>
          </form>
        </div>

        <div className="text-center">
          <button disabled={loader} type="button" className="btn btn-primary col-6 rounded-pill" onClick={handleCreateBestClipsPopupPost}>
            {
              loader
                ?
                <span className="flex justify-content-center"><FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin />{" "}posting...</span>
                :
                "post"
            }
          </button>
        </div>

        <div className="text-center text-primary mt-3 cursor-pointer"
          onClick={() => {
            setShowBestclipAdvanceSettingPopup(true);
          }}
        >Advance Setting</div>
      </section>

    </>
  );
};

export default CreateBestClips2;
