import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { AwsFileUrl, BASE_URL_STATIC } from "../../../../config";
import { ApiContext } from "../../UseContext/ApiCall";


const CloseFriendsPopup = () => {

  const {
    showCloseFriendsPopup, setShowCloseFriendsPopup,
    closeFriendsTab, setCloseFriendsTab,
    searchCloseFriendsText, setSearchCloseFriendsText,
    showCloseFriendsButtons,
    addedCloseFriends, setAddedCloseFriends,
    suggestedCloseFriends, setSuggestedCloseFriends,
    searchedCloseFriends, setSearchedCloseFriends,
    getAddedCloseFriends,
    getSuggestedCloseFriends,
    getSearchedCloseFriends,
    addRemoveCloseFriend,
  } = useContext(ApiContext);


  // handle check box change
  const handleAddRemove = (e, closeFriendId) => {
    const checked = e.target.checked;   

    // console.log('checked : ', checked);

    addRemoveCloseFriend(closeFriendsTab, closeFriendId, checked === true ? "add" : "remove");

    setAddedCloseFriends((prevState) => ({
      ...prevState,
      [closeFriendsTab]: prevState[closeFriendsTab].map((friend) =>
        friend._id === closeFriendId ? { ...friend, checked: checked === true ? 'true' : 'false' } : friend
      ),
    }));

    setSuggestedCloseFriends((prevState) => ({
      ...prevState,
      [closeFriendsTab]: prevState[closeFriendsTab].map((friend) =>
        friend._id === closeFriendId ? { ...friend, checked: checked === true ? 'true' : 'false' } : friend
      ),
    }));

    setSearchedCloseFriends((prevState) => ({
      ...prevState,
      [closeFriendsTab]: prevState[closeFriendsTab].map((friend) =>
        friend._id === closeFriendId ? { ...friend, checked: checked === true ? 'true' : 'false' } : friend
      ),
    }));
  };


  return (
    <section>
      <Modal show={showCloseFriendsPopup} onHide={() => setShowCloseFriendsPopup(false)} centered backdrop="static" keyboard={false} className="create-call" >
      {/* <Modal show={true} onHide={() => setShowCloseFriendsPopup(false)} centered backdrop="static" keyboard={false} className="create-call" > */}
        <Modal.Header closeButton style={{ paddingRight: "10px" }}>&nbsp;</Modal.Header>
        <Modal.Body>
          <h5 className="mb-3">Close Friends</h5>

          {/* Buttons */}
          <div className="d-flex align-items-center gap-1 mb-3">
            <button type="button" className={`${closeFriendsTab === 'listOne' ? 'btn-primary' : 'btn-secondary'} btn flex-grow-1`}
              onClick={() => {setCloseFriendsTab('listOne'); getAddedCloseFriends(0, 'listOne'); getSuggestedCloseFriends(0, 'listOne');}}
            >List 1</button>

            <button type="button" className={`${closeFriendsTab === 'listTwo' ? 'btn-primary' : 'btn-secondary'} btn flex-grow-1`}
              onClick={() => {setCloseFriendsTab('listTwo'); getAddedCloseFriends(0, 'listTwo'); getSuggestedCloseFriends(0, 'listTwo');}}
            >List 2</button>
            
            <button type="button" className="btn btn-secondary">Edit</button>
          </div>

          {/* Search Bar */}
          <div className="d-flex align-items-center my-3 gap-2">
            <div className="d-flex flex-grow-1 rounded-pill bg-body-secondary p-1 p-sm-2">
              <img className="mx-2" src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />
  
              <input type="search" className="rounded-pill bg-body-secondary w-100 border-0" name="search" autoComplete='false' placeholder="Search Followers..." 
                onChange={(e) => {setSearchCloseFriendsText(e.target.value); getSearchedCloseFriends(e.target.value, 0, closeFriendsTab);}}
              />
            </div>
  
            <small className="text-primary cursor-pointer">Filter</small>
          </div>

          {/* List > Added, Suggested & Searched */}
          <div className="overflow-y-scroll pe-2" style={{ maxHeight: '50vh' }}>
            {
              searchedCloseFriends[closeFriendsTab].length > 0
              ?
              // Searched
              <>
                <h5 className="mb-3 text-start">Searched</h5>

                <div>
                  {
                    searchedCloseFriends[closeFriendsTab].map((value, index) => (
                      <label className="d-flex align-items-center gap-3 cursor-pointer mb-3" htmlFor={`searched-${index}`}  key={index}>
                        <img src={AwsFileUrl+value.profile} className="border rounded-circle" style={{ height: '40px', width: '40px' }} alt="profile" />

                        <div className="me-auto">
                          <h6 className="mb-0">{value.name}</h6>
                          <span className="text-secondary">{value.userName || '----------'}</span>
                        </div>

                        <input type="checkbox" className="cursor-pointer" checked={value.checked === 'true'} onChange={(e) => handleAddRemove(e, value._id)} id={`searched-${index}`} />
                      </label>
                    ))
                  }
                </div>

                {/* Load More */}
                <>
                  {
                    showCloseFriendsButtons.searched === 'true'
                    &&
                    <div className="text-center mb-1">
                      <button type="button" className="btn btn-primary btn-sm" 
                        onClick={() => getSearchedCloseFriends(searchCloseFriendsText, searchedCloseFriends[closeFriendsTab].length, closeFriendsTab)}
                      >Load More</button>
                    </div>
                  }
                </>
              </>
              :
              // Added & Suggested  
              <>
                {/* Added */}
                <>
                  {
                    addedCloseFriends[closeFriendsTab].length > 0
                    &&
                    <>
                      <h5 className="mb-3 text-start">Added</h5>

                      <div>
                        {
                          addedCloseFriends[closeFriendsTab].map((value, index) => (
                            <label className="d-flex align-items-center gap-3 cursor-pointer mb-3" htmlFor={`added-${index}`}  key={index}>
                              <img src={AwsFileUrl+value.profile} className="border rounded-circle" style={{ height: '40px', width: '40px' }} alt="profile" />

                              <div className="me-auto">
                                <h6 className="mb-0">{value.name}</h6>
                                <span className="text-secondary">{value.userName || '----------'}</span>
                              </div>

                              <input type="checkbox" className="cursor-pointer" checked={value.checked === 'true'} onChange={(e) => handleAddRemove(e, value._id)} id={`added-${index}`} />
                            </label>
                          ))
                        }
                      </div>

                      {/* Load More */}
                      <>
                        {
                          showCloseFriendsButtons.added === 'true'
                          &&
                          <div className="text-center mb-1">
                            <button type="button" className="btn btn-primary btn-sm" 
                              onClick={() => getAddedCloseFriends(addedCloseFriends[closeFriendsTab].length, closeFriendsTab)}
                            >Load More</button>
                          </div>
                        }
                      </>

                      <hr className='hr' />
                    </>
                  }
                </>

                {/* Suggested */}
                <>
                  {
                    suggestedCloseFriends[closeFriendsTab].length > 0
                    &&
                    <>
                      <h5 className="mb-3 text-start">Suggested</h5>

                      <div>
                        {
                          suggestedCloseFriends[closeFriendsTab].map((value, index) => (
                            <label className="d-flex align-items-center gap-3 cursor-pointer mb-3" htmlFor={`suggested-${index}`}  key={index}>
                              <img src={AwsFileUrl+value.profile} className="border rounded-circle" style={{ height: '40px', width: '40px' }} alt="profile" />

                              <div className="me-auto">
                                <h6 className="mb-0">{value.name}</h6>
                                <span className="text-secondary">{value.userName || '----------'}</span>
                              </div>

                              <input type="checkbox" className="cursor-pointer" checked={value.checked === 'true'} onChange={(e) => handleAddRemove(e, value._id)}  id={`suggested-${index}`} />
                            </label>
                          ))
                        }
                      </div>

                      {/* Load More */}
                      <>
                        {
                          showCloseFriendsButtons.suggested === 'true'
                          &&
                          <div className="text-center mb-1">
                            <button type="button" className="btn btn-primary btn-sm" 
                              onClick={() => getSuggestedCloseFriends(suggestedCloseFriends[closeFriendsTab].length, closeFriendsTab)}
                            >Load More</button>
                          </div>
                        }
                      </>
                    </>
                  }
                </>
              </>
            }
          </div>
          
          {/* Submit */}
          <button type="button" className='btn btn-primary w-100 mt-3 rounded-pill' 
            onClick={() => {getAddedCloseFriends(0, closeFriendsTab); getSuggestedCloseFriends(0, closeFriendsTab);}}
          >Submit</button>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default CloseFriendsPopup;