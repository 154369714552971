import { useParams } from 'react-router-dom';
import React, { useState, useContext, useEffect } from "react";
import { ApiContext } from "../../UseContext/ApiCall";
import ProfilePostPopup from "../../Popup/Profile/ProfileShow/ProfilePostPopup";
import { AwsFileUrl } from "../../../../config";
import { getPostData } from '../../UseContext/ApiHelpers'
import Loader from '../../../Loader';


const ProfilePosts = () => {

  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [postData, setpostData] = useState([]);
  const [mainpoastData, setmainpoastData] = useState('')
  const [loader, setLoader] = useState(false)


  const { 
    config, 
    // profilePost 
  } = useContext(ApiContext);

  // console.log('profilePost : ', profilePost);

  const { paramsUserId } = useParams();

  useEffect(() => {
    getProfileData();
  }, [paramsUserId]);


  const getProfileData = async () => {
    setLoader(true)

    try {
      const responsedata = await getPostData(paramsUserId, 'post', config)
      
      setpostData(responsedata.posts)
      setLoader(false)
    }
    catch (error) {
      setLoader(false)
      console.error(error);
    }
  }


  if (loader) {
    return (
      <div className="position-relative">
        <Loader />
      </div>
    )
  }

  return (
    <>
      {/* Post image popup */}
      {/* <ProfilePostPopup showPostsPopup={showPostsPopup} postId={postId} setShowPostsPopup={setShowPostsPopup} postsPopupData={postsPopupData} setPostsPopupData={setPostsPopupData} /> */}
      <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={mainpoastData} postId={postId} />


      <section className="col-12 col-lg-7 p-0 mx-auto ps-3 row d-flex gap-3">

        {
          postData.map((item) => (
            <div key={item._id} className='post-container col-2 mx-1 mb-1 mx-lg-2 mb-lg-3' onClick={() => { setShowPostsPopup(!showPostsPopup); setmainpoastData(item); setPostId(item._id) }}>

              <div >
                <div style={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', height: '200px', width: '100%', borderRadius: '0.5rem', overflow: 'hidden'
                }}>
                  {item.mediaUrls[0] && item.mediaUrls[0].filetype === 'video' ?
                    <img
                      src={AwsFileUrl + (item.mediaUrls[0].thumbnail || item.postTumbnailUrl)}
                      alt={item.title}
                      className='img-fluid rounded'
                      loading="lazy"
                      style={{ height: '100%', maxWidth: '100%', objectFit: "cover" }}
                    />
                    :
                    <img
                      src={AwsFileUrl + (item.mediaUrls[0] && item.mediaUrls[0].url)}
                      alt={item.title}
                      className='img-fluid rounded'
                      loading="lazy"
                      style={{ height: '100%', maxWidth: '100%', objectFit: "cover" }}
                    />
                  }
                </div>


              </div>

            </div>
          ))
        }
      </section>
    </>
  );
}

export default ProfilePosts;
