import React, { useContext } from 'react';
import { profileContext } from '../../../UseContext/ProfileContext';
import Modal from "react-bootstrap/Modal";
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL } from '../../../../../config';
import axios from 'axios';
import { ApiContext } from '../../../UseContext/ApiCall';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';


const FollowerFollowingPopup = () => {

  const{ 
    config,
    loggedInUser,
    loggedInUserId,
    acceptFollowRequest, 
    removeFollower,
  } = useContext(ApiContext);

  const {
    showFollowerFollowingTab, setShowFollowerFollowingTab,
    showFollowerFollowingPopup, setShowFollowerFollowingPopup,

    showFollowersLoadMore, showFollowingsLoadMore, showPendingsLoadMore, showRequestsLoadMore, showMutualsLoadMore,

    followersSearchText, setFollowersSearchText,
    followingsSearchText, setFollowingsSearchText,
    pendingsSearchText, setPendingsSearchText,
    requestsSearchText, setRequestsSearchText,
    mutualsSearchText, setMutualsSearchText,

    followers, setFollowers,
    followings, setFollowings,
    pendings, setPendings,
    requests, setRequests,
    mutuals, setMutuals,

    searchedFollowers, setSearchedFollowers,
    searchedFollowings, setSearchedFollowings,
    searchedPendings, setSearchedPendings,
    searchedRequests, setSearchedRequests,
    searchedMutuals, setSearchedMutuals,

    getFollowers, getFollowings, getPendings, getRequests, getMutuals,
  } = useContext(profileContext);

  const { paramsUserId } = useParams();

  const navigate = useNavigate();

  // handle search change
  const handleSearchChange = (e) => {  
    
    switch (showFollowerFollowingTab) {
      case 'followers': {
        setFollowersSearchText(e.target.value);

        setSearchedFollowers([]);

        if(e.target.value !== '') {
          setFollowers([]);
        }; 

        getFollowers(
          e.target.value, 
          e.target.value === '' ? followers.length : 0, 
          paramsUserId ? paramsUserId : loggedInUserId
        );

        break;
      }
      case 'followings': {
        setFollowingsSearchText(e.target.value);

        setSearchedFollowings([]);

        if(e.target.value !== '') {
          setFollowings([]);
        }; 

        getFollowings(
          e.target.value, 
          e.target.value === '' ? followings.length : 0, 
          paramsUserId ? paramsUserId : loggedInUserId
        );

        break;
      }
      case 'pendings': {
        setPendingsSearchText(e.target.value);

        setSearchedPendings([]);

        if(e.target.value !== '') {
          setPendings([]);
        }; 

        getPendings(
          e.target.value, 
          e.target.value === '' ? pendings.length : 0, 
        );

        break;
      }
      case 'requests': {
        setRequestsSearchText(e.target.value);

        setSearchedRequests([]);

        if(e.target.value !== '') {
          setRequests([]);
        }; 

        getRequests(
          e.target.value, 
          e.target.value === '' ? requests.length : 0, 
        );

        break;
      }
      case 'mutuals': {
        setMutualsSearchText(e.target.value);

        setSearchedMutuals([]);

        if(e.target.value !== '') {
          setMutuals([]);
        }; 

        getMutuals(
          e.target.value, 
          e.target.value === '' ? mutuals.length : 0, 
          paramsUserId
        );

        break;
      }
      default: {
        console.warn("Invalid tab selected:", showFollowerFollowingTab);
      }
    }
  };

  // handle load more
  const handleLoadMore = () => {

    switch (showFollowerFollowingTab) {
      case 'followers': {
        
        getFollowers(
          followersSearchText,  
          followersSearchText === '' ? followers.length : searchedFollowers.length, 
          paramsUserId ? paramsUserId : loggedInUserId
        );

        break;
      }
      case 'followings': {

        getFollowings(
          followingsSearchText, 
          followingsSearchText === '' ? followings.length : searchedFollowings.length, 
          paramsUserId ? paramsUserId : loggedInUserId
        );

        break;
      }
      case 'pendings': {

        getPendings(
          pendingsSearchText, 
          pendingsSearchText === '' ? pendings.length : searchedPendings.length, 
        );

        break;
      }
      case 'requests': {

        getRequests(
          requestsSearchText, 
          requestsSearchText === '' ? requests.length : searchedRequests.length, 
        );

        break;
      }
      case 'mutuals': {

        getMutuals(
          mutualsSearchText, 
          mutualsSearchText === '' ? mutuals.length : searchedMutuals.length, 
          paramsUserId
        );

        break;
      }
      default: {
        console.warn("Invalid tab selected:", showFollowerFollowingTab);
      }
    }
  };

  // handle follow/unfollow
  const handleFollowUnfollow = (otherUserId) => {
    axios.post(`${BASEAPIURL}/user/handleFollowUnfollow`, { otherUserId }, config)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      // update followers btn text
      setFollowers(prevFollowers =>
        prevFollowers.map(follower =>
          follower._id === otherUserId ? { ...follower, buttonText: res.data.buttonText } : follower
        )
      );
      
      // update followings btn text
      setFollowings(prevFollowings =>
        prevFollowings.map(following =>
          following._id === otherUserId ? { ...following, buttonText: res.data.buttonText } : following
        )
      );

      // update pendings btn text
      setPendings(prevPendings =>
        prevPendings.map(pending =>
          pending._id === otherUserId ? { ...pending, buttonText: res.data.buttonText } : pending
        )
      );

      // update requests btn text
      setRequests(prevRequests =>
        prevRequests.map(request =>
          request._id === otherUserId ? { ...request, buttonText: res.data.buttonText } : request
        )
      );

      // update mutuals btn text
      setMutuals(prevMutuals =>
        prevMutuals.map(mutual =>
          mutual._id === otherUserId ? { ...mutual, buttonText: res.data.buttonText } : mutual
        )
      );
    
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // handle follower remove/accept
  const handleFollowerRemoveAccept = (otherUserId, value) => {

    switch (value) {
      case 'remove': {
        removeFollower(otherUserId);

        setFollowers(prevFollowers =>
          prevFollowers.map(follower =>
            follower._id === otherUserId ? { ...follower, showRemove: 'false' } : follower
          )
        );

        setSearchedFollowers(prevFollowers =>
          prevFollowers.map(follower =>
            follower._id === otherUserId ? { ...follower, showRemove: 'false' } : follower
          )
        );

        break;
      }
      case 'accept': {
        acceptFollowRequest(otherUserId);

        setFollowers(prevFollowers =>
          prevFollowers.map(follower =>
            follower._id === otherUserId ? { ...follower, showRemove: 'true' } : follower
          )
        );

        setSearchedFollowers(prevFollowers =>
          prevFollowers.map(follower =>
            follower._id === otherUserId ? { ...follower, showRemove: 'true' } : follower
          )
        );

        break;
      }
      default: {

        break;
      }
    }
  };

  // handle requests accept/remove
  const handleRequestAcceptRemove = (otherUserId, value) => {

    switch (value) {
      case 'accept': {
        acceptFollowRequest(otherUserId);

        setRequests(prevRequests =>
          prevRequests.map(request =>
            request._id === otherUserId ? { ...request, showAccept: 'false' } : request
          )
        );

        setSearchedRequests(prevRequests =>
          prevRequests.map(request =>
            request._id === otherUserId ? { ...request, showAccept: 'false' } : request
          )
        );

        break;
      }
      case 'remove': {
        removeFollower(otherUserId);

        setRequests(prevRequests =>
          prevRequests.map(request =>
            request._id === otherUserId ? { ...request, showAccept: 'true' } : request
          )
        );

        setSearchedRequests(prevRequests =>
          prevRequests.map(request =>
            request._id === otherUserId ? { ...request, showAccept: 'true' } : request
          )
        );

        break;
      }
      default: {

        break;
      }
    }
  };
  
  // handle on hide popup
  const handleOnHidePopup = () => {
    setShowFollowerFollowingPopup(false); 

    setFollowersSearchText(''); 
    setFollowingsSearchText(''); 
    setPendingsSearchText(''); 
    setRequestsSearchText(''); 
    setMutualsSearchText(''); 

    setFollowers([]); 
    setFollowings([]); 
    setPendings([]); 
    setRequests([]); 
    setMutuals([]); 

    setSearchedFollowers([]);
    setSearchedFollowings([]);
    setSearchedPendings([]);
    setSearchedRequests([]);
    setSearchedMutuals([]);
  };

  return (
    <>
      <Modal show={showFollowerFollowingPopup} onHide={handleOnHidePopup} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton style={{ paddingRight: '10px' }}>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            {/* Tabs > Followers, Followings, Pendings, Requests & Mutuals */}
            <div className='d-flex align-items-center gap-1'>
              {/* Followers */}
              <button type="button" className={`${showFollowerFollowingTab === 'followers' ? 'btn-primary' : 'btn-secondary'} btn btn-sm flex-grow-1`}
                onClick={() => {setShowFollowerFollowingTab('followers'); getFollowers(followersSearchText, 0, paramsUserId ? paramsUserId : loggedInUserId);}}
              >Followers</button>
              
              {/* Followings */}
              <button type="button" className={`${showFollowerFollowingTab === 'followings' ? 'btn-primary' : 'btn-secondary'} btn btn-sm flex-grow-1`}
                onClick={() => {setShowFollowerFollowingTab('followings'); getFollowings(followingsSearchText, 0, paramsUserId ? paramsUserId : loggedInUserId);}}
              >Followings</button>

              {/* Pendings */}
              <>
                {
                  (paramsUserId === undefined || loggedInUserId === paramsUserId)  // check undefined to show in home > left block
                  &&
                  <button type="button" className={`${showFollowerFollowingTab === 'pendings' ? 'btn-primary' : 'btn-secondary'} btn btn-sm flex-grow-1`}
                    onClick={() => {setShowFollowerFollowingTab('pendings'); getPendings(pendingsSearchText, 0);}}
                  >Pendings</button>
                }
              </>

              {/* Requests */}
              <>
                {
                  (paramsUserId === undefined || loggedInUserId === paramsUserId)  // check undefined to show in home > left block
                  &&
                  loggedInUser && loggedInUser.accountPrivacy === 'private'
                  &&
                  <button type="button" className={`${showFollowerFollowingTab === 'requests' ? 'btn-primary' : 'btn-secondary'} btn btn-sm flex-grow-1`}
                    onClick={() => {setShowFollowerFollowingTab('requests'); getRequests(requestsSearchText, 0);}}
                  >Requests</button>
                }
              </>

              {/* Mutuals */}
              <>
                {
                  paramsUserId && loggedInUserId !== paramsUserId
                  &&
                  <button type="button" className={`${showFollowerFollowingTab === 'mutuals' ? 'btn-primary' : 'btn-secondary'} btn btn-sm flex-grow-1`}
                    onClick={() => {setShowFollowerFollowingTab('mutuals'); getMutuals(mutualsSearchText, 0, paramsUserId);}}
                  >Mutuals</button>
                }
              </>
            </div>
            

            {/* --- Lists > Followers --- */}
            <>
              {
                showFollowerFollowingTab === 'followers'
                &&
                <>
                  {/* Search Bar */}
                  <div className="d-flex align-items-center my-3">
                    <div className="d-flex flex-grow-1 rounded-pill bg-body-secondary p-1 p-sm-2">
                      <img className="mx-2" src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />
          
                      <input type="search" className="rounded-pill bg-body-secondary w-100 border-0" id="search" name="search" autoComplete='false' placeholder="Search Followers..." 
                        value={followersSearchText} onChange={handleSearchChange}
                      />
                    </div>
          
                    <small className="ms-3 ms-lg-4 text-primary cursor-pointer">Filter</small>
                  </div>

                  {/* List */}
                  <>
                    {
                      searchedFollowers && searchedFollowers.length > 0
                      ?
                      // Searched Followers List
                      <div className={`${searchedFollowers.length > 5 && 'overflow-y-scroll'} pe-1`} style={{ maxHeight: '50vh' }}>
                        {
                          searchedFollowers.map((value, index) => (
                            <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                              <img src={AwsFileUrl+value.profile} className='rounded-circle cursor-pointer' style={{ height: '40px', width: '40px' }} onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}} alt="profile" />

                              <div className='me-auto cursor-pointer' onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}>
                                <h5 className='mb-0'>{value.name}</h5>
                                <span className='text-secondary'>{value.userName || 'username123@'}</span>
                              </div>

                              {
                                value._id !== loggedInUserId
                                &&
                                <button type="button" className='btn btn-primary btn-sm' onClick={() => handleFollowUnfollow(value._id)}>{value.buttonText}</button>
                              }

                              {/* Accept */}
                              {
                                (paramsUserId === undefined || paramsUserId === loggedInUserId)
                                && 
                                (
                                  value.showRemove === 'true'
                                  ?
                                  <button type="button" className='btn btn-secondary btn-sm' onClick={() => handleFollowerRemoveAccept(value._id, 'remove')}>Remove</button>
                                  :
                                  <button type="button" className='btn btn-success btn-sm' onClick={() => handleFollowerRemoveAccept(value._id, 'accept')}>Accept</button>
                                )
                              }
                            </div>
                          ))
                        }

                        {/* Load More */}
                        <>
                          {
                            showFollowersLoadMore
                            ?
                            <div className='text-center'>
                              <button type="button" className='btn btn-primary btn-sm mb-1' onClick={handleLoadMore}>Load More</button>
                            </div>
                            :
                            <div className='text-center'>
                              <span className='text-secondary'>No more followers...</span>
                            </div>
                          }
                        </>
                      </div>
                      :
                      // Followers List
                      <div className={`${followers.length > 5 && 'overflow-y-scroll'} pe-1`} style={{ maxHeight: '50vh' }}>
                        {
                          followers && followers.length > 0
                          &&
                          followers.map((value, index) => (
                            <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                              <img src={AwsFileUrl+value.profile} className='rounded-circle cursor-pointer' style={{ height: '40px', width: '40px' }} onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}} alt="profile" />

                              <div className='me-auto cursor-pointer' onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}>
                                <h5 className='mb-0'>{value.name}</h5>
                                <span className='text-secondary'>{value.userName || 'username123@'}</span>
                              </div>

                              {
                                value._id !== loggedInUserId
                                &&
                                <button type="button" className='btn btn-primary btn-sm' onClick={() => handleFollowUnfollow(value._id)}>{value.buttonText}</button>
                              }

                              {/* Accept */}
                              {
                                (paramsUserId === undefined || paramsUserId === loggedInUserId)
                                && 
                                (
                                  value.showRemove === 'true'
                                  ?
                                  <button type="button" className='btn btn-secondary btn-sm' onClick={() => handleFollowerRemoveAccept(value._id, 'remove')}>Remove</button>
                                  :
                                  <button type="button" className='btn btn-success btn-sm' onClick={() => handleFollowerRemoveAccept(value._id, 'accept')}>Accept</button>
                                )
                              }
                            </div>
                          ))
                        }

                        {/* Load More */}
                        <>
                          {
                            showFollowersLoadMore
                            ?
                            <div className='text-center'>
                              <button type="button" className='btn btn-primary btn-sm mb-1' onClick={handleLoadMore}>Load More</button>
                            </div>
                            :
                            <div className='text-center'>
                              <span className='text-secondary'>No more followers...</span>
                            </div>
                          }
                        </>
                      </div>
                    }
                  </>
                </>
              }
            </>

            {/* --- Lists > Followings --- */}
            <>
              {
                showFollowerFollowingTab === 'followings'
                &&
                <>
                  {/* Search Bar */}
                  <div className="d-flex align-items-center my-3">
                    <div className="d-flex flex-grow-1 rounded-pill bg-body-secondary p-1 p-sm-2">
                      <img className="mx-2" src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />
          
                      <input type="search" className="rounded-pill bg-body-secondary w-100 border-0" id="search" name="search" placeholder="Search Followings..." 
                        value={followingsSearchText} onChange={handleSearchChange}
                      />
                    </div>
          
                    <small className="ms-3 ms-lg-4 text-primary cursor-pointer">Filter</small>
                  </div>

                  {/* List */}
                  <>
                    {
                      searchedFollowings && searchedFollowings.length > 0
                      ?
                      // Searched Followings List
                      <div className='overflow-y-scroll pe-1' style={{ maxHeight: '50vh' }}>
                        {
                          searchedFollowings.map((value, index) => (
                            <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                              <img src={AwsFileUrl+value.profile} className='rounded-circle cursor-pointer' style={{ height: '40px', width: '40px' }} onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}} alt="profile" />

                              <div className='me-auto cursor-pointer' onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}>
                                <h5 className='mb-0'>{value.name}</h5>
                                <span className='text-secondary'>{value.userName || 'username123@'}</span>
                              </div>

                              {
                                value._id !== loggedInUserId
                                &&
                                <button type="button" className='btn btn-primary btn-sm' onClick={() => handleFollowUnfollow(value._id)}>{value.buttonText}</button>
                              }
                            </div>
                          ))
                        }

                        {/* Load More */}
                        <>
                          {
                            showFollowingsLoadMore
                            ?
                            <div className='text-center'>
                              <button type="button" className='btn btn-primary btn-sm mb-1' onClick={handleLoadMore}>Load More</button>
                            </div>
                            :
                            <div className='text-center'>
                              <span className='text-secondary'>No more followings...</span>
                            </div>
                          }
                        </>
                      </div>
                      :
                      // Followings List
                      <div className='overflow-y-scroll pe-1' style={{ maxHeight: '50vh' }}>
                        {
                          followings && followings.length > 0
                          &&
                          followings.map((value, index) => (
                            <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                              <img src={AwsFileUrl+value.profile} className='rounded-circle cursor-pointer' style={{ height: '40px', width: '40px' }} onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}} alt="profile" />

                              <div className='me-auto cursor-pointer' onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}>
                                <h5 className='mb-0'>{value.name}</h5>
                                <span className='text-secondary'>{value.userName || 'username123@'}</span>
                              </div>

                              {
                                value._id !== loggedInUserId
                                &&
                                <button type="button" className='btn btn-primary btn-sm' onClick={() => handleFollowUnfollow(value._id)}>{value.buttonText}</button>
                              }
                            </div>
                          ))
                        }

                        {/* Load More */}
                        <>
                          {
                            showFollowingsLoadMore
                            ?
                            <div className='text-center'>
                              <button type="button" className='btn btn-primary btn-sm mb-1' onClick={handleLoadMore}>Load More</button>
                            </div>
                            :
                            <div className='text-center'>
                              <span className='text-secondary'>No more followings...</span>
                            </div>
                          }
                        </>
                      </div>
                    }
                  </>
                </>
              }
            </>

            {/* --- Lists > Pendings --- */}
            <>
              {
                showFollowerFollowingTab === 'pendings'
                &&
                <>
                  {/* Search Bar */}
                  <div className="d-flex align-items-center my-3">
                    <div className="d-flex flex-grow-1 rounded-pill bg-body-secondary p-1 p-sm-2">
                      <img className="mx-2" src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />
          
                      <input type="search" className="rounded-pill bg-body-secondary w-100 border-0" id="search" name="search" placeholder="Search Pendings..." 
                        value={pendingsSearchText} onChange={handleSearchChange}
                      />
                    </div>
          
                    <small className="ms-3 ms-lg-4 text-primary cursor-pointer">Filter</small>
                  </div>

                  {/* List */}
                  <>
                    {
                      searchedPendings && searchedPendings.length > 0
                      ?
                      // Searched Pendings List
                      <div className='overflow-y-scroll pe-1' style={{ maxHeight: '50vh' }}>
                        {
                          searchedPendings.map((value, index) => (
                            <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                              <img src={AwsFileUrl+value.profile} className='rounded-circle cursor-pointer' style={{ height: '40px', width: '40px' }} alt="profile"
                                onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}  
                              />

                              <div className='me-auto cursor-pointer' onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}>
                                <h5 className='mb-0'>{value.name}</h5>
                                <span className='text-secondary'>{value.userName || 'username123@'}</span>
                              </div>

                              {
                                value._id !== loggedInUserId
                                &&
                                <button type="button" className='btn btn-primary btn-sm' onClick={() => handleFollowUnfollow(value._id)}>{value.buttonText}</button>
                              }
                            </div>
                          ))
                        }

                        {/* Load More */}
                        <>
                          {
                            showPendingsLoadMore
                            ?
                            <div className='text-center'>
                              <button type="button" className='btn btn-primary btn-sm mb-1' onClick={handleLoadMore}>Load More</button>
                            </div>
                            :
                            <div className='text-center'>
                              <span className='text-secondary'>No more pendings...</span>
                            </div>
                          }
                        </>
                      </div>
                      :
                      // Pendings List
                      <div className='overflow-y-scroll pe-1' style={{ maxHeight: '50vh' }}>
                        {
                          pendings && pendings.length > 0
                          &&
                          pendings.map((value, index) => (
                            <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                              <img src={AwsFileUrl+value.profile} className='rounded-circle cursor-pointer' style={{ height: '40px', width: '40px' }} alt="profile" 
                                onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}} 
                              />

                              <div className='me-auto cursor-pointer' onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}>
                                <h5 className='mb-0'>{value.name}</h5>
                                <span className='text-secondary'>{value.userName || 'username123@'}</span>
                              </div>

                              {
                                value._id !== loggedInUserId
                                &&
                                <button type="button" className='btn btn-primary btn-sm' onClick={() => handleFollowUnfollow(value._id)}>{value.buttonText}</button>
                              }
                            </div>
                          ))
                        }

                        {/* Load More */}
                        <>
                          {
                            showPendingsLoadMore
                            ?
                            <div className='text-center'>
                              <button type="button" className='btn btn-primary btn-sm mb-1' onClick={handleLoadMore}>Load More</button>
                            </div>
                            :
                            <div className='text-center'>
                              <span className='text-secondary'>No more pendings...</span>
                            </div>
                          }
                        </>
                      </div>
                    }
                  </>
                </>
              }
            </>

            {/* --- Lists > Requests --- */}
            <>
              {
                showFollowerFollowingTab === 'requests'
                &&
                <>
                  {/* Search Bar */}
                  <div className="d-flex align-items-center my-3">
                    <div className="d-flex flex-grow-1 rounded-pill bg-body-secondary p-1 p-sm-2">
                      <img className="mx-2" src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />
          
                      <input type="search" className="rounded-pill bg-body-secondary w-100 border-0" id="search" name="search" placeholder="Search Requests..." 
                        value={requestsSearchText} onChange={handleSearchChange}
                      />
                    </div>
          
                    <small className="ms-3 ms-lg-4 text-primary cursor-pointer">Filter</small>
                  </div>

                  {/* List */}
                  <>
                    {
                      searchedRequests && searchedRequests.length > 0
                      ?
                      // Searched Requests List
                      <div className='overflow-y-scroll pe-1' style={{ maxHeight: '50vh' }}>
                        {
                          searchedRequests.map((value, index) => (
                            <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                              <img src={AwsFileUrl+value.profile} className='rounded-circle cursor-pointer' style={{ height: '40px', width: '40px' }} alt="profile"
                                onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}  
                              />

                              <div className='me-auto cursor-pointer' onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}>
                                <h5 className='mb-0'>{value.name}</h5>
                                <span className='text-secondary'>{value.userName || 'username123@'}</span>
                              </div>

                              {/* Follow Back */}
                              {
                                value._id !== loggedInUserId
                                &&
                                <button type="button" className='btn btn-primary btn-sm' onClick={() => handleFollowUnfollow(value._id)}>{value.buttonText}</button>
                              }

                              {/* Accept */}
                              {
                                value.showAccept === 'true'
                                ?
                                <button type="button" className='btn btn-success btn-sm' onClick={() => handleRequestAcceptRemove(value._id, 'accept')}>Accept</button>
                                :
                                <button type="button" className='btn btn-secondary btn-sm' onClick={() => handleRequestAcceptRemove(value._id, 'remove')}>Remove</button>
                              }
                            </div>
                          ))
                        }

                        {/* Load More */}
                        <>
                          {
                            showRequestsLoadMore
                            ?
                            <div className='text-center'>
                              <button type="button" className='btn btn-primary btn-sm mb-1' onClick={handleLoadMore}>Load More</button>
                            </div>
                            :
                            <div className='text-center'>
                              <span className='text-secondary'>No more request...</span>
                            </div>
                          }
                        </>
                      </div>
                      :
                      // Requests List
                      <div className='overflow-y-scroll pe-1' style={{ maxHeight: '50vh' }}>
                        {
                          requests && requests.length > 0
                          &&
                          requests.map((value, index) => (
                            <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                              <img src={AwsFileUrl+value.profile} className='rounded-circle cursor-pointer' style={{ height: '40px', width: '40px' }} alt="profile" 
                                onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}} 
                              />

                              <div className='me-auto cursor-pointer' onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}>
                                <h5 className='mb-0'>{value.name}</h5>
                                <span className='text-secondary'>{value.userName || 'username123@'}</span>
                              </div>

                              {/* Follow Back */}
                              {
                                value._id !== loggedInUserId
                                &&
                                <button type="button" className='btn btn-primary btn-sm' onClick={() => handleFollowUnfollow(value._id)}>{value.buttonText}</button>
                              }

                              {/* Accept */}
                              {
                                value.showAccept === 'true'
                                ?
                                <button type="button" className='btn btn-success btn-sm' onClick={() => handleRequestAcceptRemove(value._id, 'accept')}>Accept</button>
                                :
                                <button type="button" className='btn btn-secondary btn-sm' onClick={() => handleRequestAcceptRemove(value._id, 'remove')}>Remove</button>
                              }
                            </div>
                          ))
                        }

                        {/* Load More */}
                        <>
                          {
                            showRequestsLoadMore
                            ?
                            <div className='text-center'>
                              <button type="button" className='btn btn-primary btn-sm mb-1' onClick={handleLoadMore}>Load More</button>
                            </div>
                            :
                            <div className='text-center'>
                              <span className='text-secondary'>No more request...</span>
                            </div>
                          }
                        </>
                      </div>
                    }
                  </>
                </>
              }
            </>

            {/* --- Lists > Mutuals --- */}
            <>
              {
                showFollowerFollowingTab === 'mutuals'
                &&
                <>
                  {/* Search Bar */}
                  <div className="d-flex align-items-center my-3">
                    <div className="d-flex flex-grow-1 rounded-pill bg-body-secondary p-1 p-sm-2">
                      <img className="mx-2" src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />
          
                      <input type="search" className="rounded-pill bg-body-secondary w-100 border-0" id="search" name="search" placeholder="Search Mutuals..." 
                        value={mutualsSearchText} onChange={handleSearchChange}
                      />
                    </div>
          
                    <small className="ms-3 ms-lg-4 text-primary cursor-pointer">Filter</small>
                  </div>

                  {/* List */}
                  <>
                    {
                      searchedMutuals && searchedMutuals.length > 0
                      ?
                      // Searched Requests List
                      <div className='overflow-y-scroll pe-1' style={{ maxHeight: '50vh' }}>
                        {
                          searchedMutuals.map((value, index) => (
                            <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                              <img src={AwsFileUrl+value.profile} className='rounded-circle cursor-pointer' style={{ height: '40px', width: '40px' }} alt="profile"
                                onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}  
                              />

                              <div className='me-auto cursor-pointer' onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}>
                                <h5 className='mb-0'>{value.name}</h5>
                                <span className='text-secondary'>{value.userName || 'username123@'}</span>
                              </div>

                              {/* Follow Back */}
                              {
                                value._id !== loggedInUserId
                                &&
                                <button type="button" className='btn btn-primary btn-sm' onClick={() => handleFollowUnfollow(value._id)}>{value.buttonText}</button>
                              }
                            </div>
                          ))
                        }

                        {/* Load More */}
                        <>
                          {
                            showMutualsLoadMore
                            ?
                            <div className='text-center'>
                              <button type="button" className='btn btn-primary btn-sm mb-1' onClick={handleLoadMore}>Load More</button>
                            </div>
                            :
                            <div className='text-center'>
                              <span className='text-secondary'>No more mutuals...</span>
                            </div>
                          }
                        </>
                      </div>
                      :
                      // Requests List
                      <div className='overflow-y-scroll pe-1' style={{ maxHeight: '50vh' }}>
                        {
                          mutuals && mutuals.length > 0
                          &&
                          mutuals.map((value, index) => (
                            <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                              <img src={AwsFileUrl+value.profile} className='rounded-circle cursor-pointer' style={{ height: '40px', width: '40px' }} alt="profile" 
                                onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}} 
                              />

                              <div className='me-auto cursor-pointer' onClick={() => {navigate(`/profile/${value._id}`); setShowFollowerFollowingPopup(false)}}>
                                <h5 className='mb-0'>{value.name}</h5>
                                <span className='text-secondary'>{value.userName || 'username123@'}</span>
                              </div>

                              {/* Follow Back */}
                              {
                                value._id !== loggedInUserId
                                &&
                                <button type="button" className='btn btn-primary btn-sm' onClick={() => handleFollowUnfollow(value._id)}>{value.buttonText}</button>
                              }
                            </div>
                          ))
                        }

                        {/* Load More */}
                        <>
                          {
                            showMutualsLoadMore
                            ?
                            <div className='text-center'>
                              <button type="button" className='btn btn-primary btn-sm mb-1' onClick={handleLoadMore}>Load More</button>
                            </div>
                            :
                            <div className='text-center'>
                              <span className='text-secondary'>No more mutuals...</span>
                            </div>
                          }
                        </>
                      </div>
                    }
                  </>
                </>
              }
            </>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FollowerFollowingPopup;