import React, { useContext } from 'react';
import LeftBlock from '../../Home/LeftBlock/LeftBlock';
import { ApiContext } from '../../UseContext/ApiCall';
import CreateNewPostPopup from './Popup/CreateNewPostPopup';
import CnpFeelingActivityPopup from './Popup/FeelingActivity/CnpFeelingActivityPopup';
import { useEffect } from 'react';
import { AwsFileUrl } from '../../../../config';
import CnpLocationPopup from './Popup/Location/CnpLocationPopup';
// import CnpTagPopup from './Popup/Tag/CnpTagPopup';


const CreateNewPost = ({ className }) => {

  const {
    setShowCreateNewPostPopup,
    userPosts,
    getPosts,
  } = useContext(ApiContext);

  // console.log('userPosts : ', userPosts);

  useEffect(() => {
    getPosts();
  }, []);


  return (
    <>
      {/* Popup */}
      <>
        {/* Create New Post Popup */}
        <CreateNewPostPopup />

        {/* Fellings/Activities Popup */}
        <CnpFeelingActivityPopup />

        {/* Tag Popup */}
        {/* <CnpTagPopup /> */}

        {/* Check in/Location Popup */}
        <CnpLocationPopup />
      </>

      <section className={`section-main ${className || ''}`}>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            {/* Left Block */}
            <div className="col-lg-3 d-none d-lg-block shadow z-1">
              <LeftBlock />
            </div>

            {/* Right Block */}
            <div className="col-lg-9 pt-5 overflow-y-scroll scrollbar-hidden px-3" style={{ background: "lightblue", height: '100vh' }}>
              <h3 className='pt-5 text-center fw-bold'>Create New Post</h3>

              <button type="button" className='btn btn-primary btn-sm rounded-pill' onClick={() => setShowCreateNewPostPopup(true)}>Create New Post</button>

              <div className='border border-primary mt-3 p-1' style={{ height: '75vh' }}>
                {
                  userPosts && userPosts.length > 0
                  && 
                  userPosts.map((value, index) => (
                    <div className='border border-danger mb-1 p-1 bg-white' key={index}>
                      <div className='d-flex align-items-center gap-3'>
                        <img src={AwsFileUrl+value.profile} className='rounded-circle' style={{ height: '40px', width: '40px' }} alt="profile" />

                        <div>
                          {/* Name */}
                          <span className='fs-5 mb-0 me-2'>{value.name}</span>

                          {/* Feeling */}
                          <>
                            {
                              (value.feeling && value.feeling._id !== '')
                              &&
                              (
                                <span className='me-2'>
                                  is feeling {value.feeling.feelingName} {" "}
                                  <img src={value.feeling.feelingIcon} style={{ height: '17px', width: '17px' }} alt="icon" />
                                </span>
                              )
                            }
                          </>

                          {/* Activity */}
                          <>
                            {
                              (value.activityCategory && value.activityCategory._id !== '' && value.activity && value.activity._id !== '')
                              &&
                              (
                                <span className='me-2'>
                                  is {value.activityCategory.activityCategoryName} {" "}
                                  <img src={value.activityCategory.activityCategoryIcon} style={{ height: '17px', width: '17px' }} alt="icon" /> {" "}
                                  {value.activity.activityName} {" "}
                                  <img src={value.activity.activityIcon} style={{ height: '17px', width: '17px' }} alt="icon" />
                                </span>
                              )
                            }
                          </>



                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateNewPost;