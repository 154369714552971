import React, { useContext } from 'react';
import { ApiContext } from '../../../../UseContext/ApiCall';
import { Modal } from 'react-bootstrap';
import { BASE_URL_STATIC } from '../../../../../../config';


const CnpFeelingActivityPopup = () => {

  const {
    setShowCreateNewPostPopup,

    createPostData, setCreatePostData,

    showCnpFeelingActivityPopup, setShowCnpFeelingActivityPopup,
    cnpFeelingActivityTab, setCnpFeelingActivityTab,
    cnpFeelingActivitySearchText, setCnpFeelingActivitySearchText,
    showCnpFeelingLoadMore, setShowCnpFeelingLoadMore,

    cnpFeelings, setCnpFeelings,
    cnpSearchedFeelings, setCnpSearchedFeelings,
    cnpSelectedFeeling, setCnpSelectedFeeling,

    cnpActivityCategories, setCnpActivityCategories,
    cnpSearchedActivityCategories, setCnpSearchedActivityCategories,
    cnpSelectedActivityCategory, setCnpSelectedActivityCategory,

    cnpActivities, setCnpActivities,
    cnpSearchedActivities, setCnpSearchedActivities,
    cnpSelectedActivity, setCnpSelectedActivity,

    getFeelings, getActivityCategories, getActivities,
  } = useContext(ApiContext);

  // handle click feeling tab 
  const handleClickFeelingTab = () => {
    setCnpFeelingActivityTab('feeling');

    getFeelings('', 0);
  };

  // handle click activity tab 
  const handleClickActivityTab = () => {
    if(cnpSelectedActivityCategory._id) {
      setCnpFeelingActivityTab('activity');

      getActivities(cnpSelectedActivityCategory._id, '', 0);
    } else {
      setCnpFeelingActivityTab('activityCategory');

      getActivityCategories('', 0);
    };
  };

  // handle search
  const handleSearch = (e) => {
    setCnpFeelingActivitySearchText(e.target.value);

    switch (cnpFeelingActivityTab) {
      case 'feeling': {

        getFeelings(e.target.value, 0);

        if(e.target.value === '') {
          setCnpSearchedFeelings([]);
        };

        break;
      }
      case 'activityCategory': {

        getActivityCategories(e.target.value, 0);

        if(e.target.value === '') {
          setCnpSearchedActivityCategories([]);
        };

        break;
      }
      case 'activity': {

        getActivities(cnpSelectedActivityCategory._id, e.target.value, 0);

        if(e.target.value === '') {
          setCnpSearchedActivities([]);
        };

        break;
      }
      default: {

        break;
      }
    }
  };

  // handle select feeling
  const handleSelectFeeling = (value) => {    

    if(cnpSelectedFeeling && cnpSelectedFeeling._id === value._id) {
      setCnpSelectedFeeling({});

      setCreatePostData((prevData) => ({
        ...prevData,
        postType: '',
        activityCategoryIcon: '',
        activityCategoryName: '',
        feelingActivityIcon: '',
        feelingActivityName: '',
      }));
    } else {
      setCnpSelectedFeeling(value);
      
      setCreatePostData((prevData) => ({
        ...prevData,
        postType: 'feeling',
        activityCategoryIcon: '',
        activityCategoryName: '',
        feelingActivityIcon: value.feelingIcon || '',
        feelingActivityName: value.feelingName || '',
      }));
      
      setCnpFeelings((preFeelings) => preFeelings.filter(feeling => feeling._id !== value._id));

      setShowCnpFeelingActivityPopup(false);
      setShowCreateNewPostPopup(true);

      setCnpSelectedActivityCategory({});
      setCnpSelectedActivity({});
    };
  };

  // handle remove feeling
  const handleRemoveFeeling = () => {
    setCnpFeelings((prevFeelings) => {
      const filteredFeelings = prevFeelings.filter((feeling) => feeling._id !== cnpSelectedFeeling._id);

      return [cnpSelectedFeeling, ...filteredFeelings];
    });

    setCnpSelectedFeeling({});

    setCreatePostData((prevData) => ({
      ...prevData,
      postType: '',
      activityCategoryIcon: '',
      activityCategoryName: '',
      feelingActivityIcon: '',
      feelingActivityName: '',
    }));
  };

  // handle select activity category
  const handleSelectActivityCategory = (value) => {
    setCnpFeelingActivityTab('activity'); 
    setCnpSelectedActivityCategory(value);
    setCnpSelectedFeeling({});
    getActivities(value._id, '', 0);

    setCreatePostData((prevData) => ({
      ...prevData,
      feelingActivityIcon: '',
      feelingActivityName: '',
    }));
  };

  // handle remove activity category
  const handleRemoveActivityCategory = () => {
    setCnpFeelingActivityTab('activityCategory'); 
    setCnpSelectedActivityCategory({});
    setCnpSelectedActivity({});
    setShowCnpFeelingLoadMore(true);

    setCreatePostData((preState) => ({
      ...preState,
      activityCategoryIcon: '',
      activityCategoryName: '',
      feelingActivityIcon: '',
      feelingActivityName: '',
    }));
  };

  // handle select activity 
  const handleSelectActivity = (value) => {
    setCnpSelectedActivity(value);
    setShowCnpFeelingActivityPopup(false);
    setShowCreateNewPostPopup(true);

    setCreatePostData((prevData) => ({
      ...prevData,
      postType: 'activity',
      activityCategoryIcon: cnpSelectedActivityCategory.activityCategoryIcon || '',
      activityCategoryName: cnpSelectedActivityCategory.activityCategoryName || '',
      feelingActivityIcon: value.activityIcon || '',
      feelingActivityName: value.activityName || '',
    }));

    setCnpSelectedFeeling({});
  };

  // handle remove activity
  const handleRemoveActivity = () => {
    setCnpActivities((preActivities) => {
      const filteredFeelings = preActivities.filter((activity) => activity._id !== cnpSelectedActivity._id);

      return [cnpSelectedActivity, ...filteredFeelings];
    });

    setCnpSelectedActivity({});

    setCreatePostData((prevData) => ({
      ...prevData,
      postType: '',
      activityCategoryIcon: '',
      activityCategoryName: '',
      feelingActivityIcon: '',
      feelingActivityName: '',
    }));
  };

  // handle close feeling/activity popup
  const handleCloseFeelingActivityPopup = () => {
    setShowCnpFeelingActivityPopup(false);
    setShowCreateNewPostPopup(true);
  };

  

  return (
    <>
      <Modal show={showCnpFeelingActivityPopup} onHide={handleCloseFeelingActivityPopup} centered backdrop="static" keyboard={false} className="main-modal" >
        <Modal.Header className='mb-3 pt-0' closeButton>
          <h5 className='flex-grow-1 text-center pt-3'>Feelings/Activities</h5>
        </Modal.Header>

        <Modal.Body>
          {/* Tab */}
          <div className='d-flex align-items-center gap-2'>
            <button type="button" onClick={handleClickFeelingTab}
              className={`${cnpFeelingActivityTab === 'feeling' ? 'btn-primary' : 'btn-secondary'} btn btn-sm flex-grow-1`}
            >Fellings</button>

            <button type="button" onClick={handleClickActivityTab}
              className={`${(cnpFeelingActivityTab === 'activityCategory' || cnpFeelingActivityTab === 'activity') ? 'btn-primary' : 'btn-secondary'} btn btn-sm flex-grow-1`}
            >Activities</button>
          </div>


          {/* Search Bar */}
          <div className="d-flex flex-grow-1 rounded-pill bg-body-secondary p-1 p-sm-2 my-3">
            <img className="mx-2" src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />

            <input type="search" className="rounded-pill bg-body-secondary w-100 border-0" id="search" name="search" autoComplete='false' 
              placeholder={`Search ${cnpFeelingActivityTab === 'feeling' ? 'Feelings' : 'Activities'}...`} 
              value={cnpFeelingActivitySearchText} onChange={handleSearch}
            />
          </div>


          {/* --- Feelings --- */}
          <>
            {
              cnpFeelingActivityTab === 'feeling' 
              && 
              <>
                {
                  cnpSearchedFeelings && cnpSearchedFeelings.length > 0
                  ?
                  // Searched Feelings
                  <div className='d-flex flex-wrap overflow-y-scroll' style={{ maxHeight: '50vh' }}>
                    {
                      cnpSearchedFeelings && cnpSearchedFeelings.length > 0
                      &&
                      cnpSearchedFeelings.map((value, index) => (
                        <div onClick={() => handleSelectFeeling(value)} key={index} 
                          className={`${cnpSelectedFeeling._id === value._id && 'bg-secondary-subtle'} w-50 p-2 cursor-pointer rounded-3`} 
                        >
                          <img src={value.feelingIcon} className='me-2' style={{ height: '23px', width: '23px' }} alt="icon" />

                          <span>{value.feelingName}</span>
                        </div>
                      ))
                    }

                    {/* Load More */}
                    <>
                      {
                        showCnpFeelingLoadMore
                        &&
                        <div className='text-center mt-3 w-100 mb-1'>
                          <button type="button" className='btn btn-primary btn-sm' onClick={() => getFeelings(cnpFeelingActivitySearchText, cnpSearchedFeelings.length)}>Load More</button>
                        </div>
                      }
                    </>
                  </div>
                  :
                  // Feelings
                  <div className='d-flex flex-wrap overflow-y-scroll' style={{ maxHeight: '50vh' }}>
                    {/* Selected Feeling */}
                    <>
                      {
                        cnpSelectedFeeling._id
                        &&
                        <div className={`bg-secondary-subtle w-50 p-2 cursor-pointer rounded-3`} onClick={handleRemoveFeeling} >
                          <img src={cnpSelectedFeeling.feelingIcon} className='me-2' style={{ height: '23px', width: '23px' }} alt="icon" />

                          <span>{cnpSelectedFeeling.feelingName}</span>
                        </div>
                      }
                    </>

                    {
                      cnpFeelings && cnpFeelings.length > 0
                      &&
                      cnpFeelings.map((value, index) => (
                        <>
                          {
                            value._id !== cnpSelectedFeeling._id
                            &&
                            <div onClick={() => handleSelectFeeling(value)} key={index} 
                              className={`${cnpSelectedFeeling._id === value._id && 'bg-secondary-subtle'} w-50 p-2 cursor-pointer rounded-3`}
                            >
                              <img src={value.feelingIcon} className='me-2' style={{ height: '23px', width: '23px' }} alt="icon" />

                              <span>{value.feelingName}</span>
                            </div>
                          }
                        </>
                      ))
                    }

                    {/* Load More */}
                    <>
                      {
                        showCnpFeelingLoadMore
                        &&
                        <div className='text-center mt-3 w-100 mb-1'>
                          <button type="button" className='btn btn-primary btn-sm' onClick={() => getFeelings('', cnpFeelings.length)}>Load More</button>
                        </div>
                      }
                    </>
                  </div>
                }
              </>
            }
          </>


          {/* --- Activity Category --- */}
          <>
            {
              cnpFeelingActivityTab === 'activityCategory'
              && 
              <>
                {
                  cnpSearchedActivityCategories && cnpSearchedActivityCategories.length > 0
                  ?
                  // Searched Activity Categories
                  <div className='d-flex flex-wrap overflow-y-scroll' style={{ maxHeight: '50vh' }}>
                    {
                      cnpSearchedActivityCategories && cnpSearchedActivityCategories.length > 0
                      &&
                      cnpSearchedActivityCategories.map((value, index) => (
                        <div className='w-50 p-2 cursor-pointer rounded-3' onClick={() => handleSelectActivityCategory(value)} key={index} >
                          <img src={value.activityCategoryIcon} className='me-2' style={{ height: '23px', width: '23px' }} alt="icon" />

                          <span>{value.activityCategoryName}</span>
                        </div>
                      ))
                    }

                    {/* Load More */}
                    <>
                      {
                        showCnpFeelingLoadMore
                        &&
                        <div className='text-center mt-3 w-100 mb-1'>
                          <button type="button" className='btn btn-primary btn-sm' onClick={() => getActivityCategories(cnpFeelingActivitySearchText, cnpSearchedActivityCategories.length)}>Load More</button>
                        </div>
                      }
                    </>
                  </div>
                  :
                  // Activity Categories
                  <div className='d-flex flex-wrap overflow-y-scroll' style={{ maxHeight: '50vh' }}>
                    {
                      cnpActivityCategories && cnpActivityCategories.length > 0
                      &&
                      cnpActivityCategories.map((value, index) => (
                        <div className='w-50 p-2 cursor-pointer rounded-3' onClick={() => handleSelectActivityCategory(value)} key={index} >
                          <img src={value.activityCategoryIcon} className='me-2' style={{ height: '23px', width: '23px' }} alt="icon" />

                          <span>{value.activityCategoryName}</span>
                        </div>
                      ))
                    }

                    {/* Load More */}
                    <>
                      {
                        showCnpFeelingLoadMore
                        &&
                        <div className='text-center mt-3 w-100 mb-1'>
                          <button type="button" className='btn btn-primary btn-sm' onClick={() => getActivityCategories('', cnpActivityCategories.length)}>Load More</button>
                        </div>
                      }
                    </>
                  </div>
                }
              </>
            }
          </>


          {/* --- Activity --- */}
          <>
            {
              cnpFeelingActivityTab === 'activity'
              && 
              <>
                {/* Selected Activity Category */}
                <div className='d-flex align-items-center gap-2 bg-primary-subtle px-3 py-2 rounded-3 mb-3' style={{ width: 'fit-content' }}>
                  <img src={cnpSelectedActivityCategory.activityCategoryIcon} style={{ height: '20px', width: '20px' }} alt="icon" />

                  <span>{cnpSelectedActivityCategory.activityCategoryName}</span>

                  <span className='text-danger cursor-pointer' onClick={handleRemoveActivityCategory}>X</span>
                </div>

                <>
                  {
                    cnpSearchedActivities && cnpSearchedActivities.length > 0
                    ?
                    // Searched Activities
                    <div className='d-flex flex-wrap overflow-y-scroll' style={{ maxHeight: '50vh' }}>
                      {
                        cnpSearchedActivities && cnpSearchedActivities.length > 0
                        &&
                        cnpSearchedActivities.map((value, index) => (
                          <div className='w-50 p-2 cursor-pointer rounded-3' key={index} >
                            <img src={value.activityIcon} className='me-2' style={{ height: '23px', width: '23px' }} alt="icon" />

                            <span>{value.activityName}</span>
                          </div>
                        ))
                      }

                      {/* Load More */}
                      <>
                        {
                          showCnpFeelingLoadMore
                          &&
                          <div className='text-center mt-3 w-100 mb-1'>
                            <button type="button" className='btn btn-primary btn-sm' onClick={() => getActivities(cnpSelectedActivityCategory._id, cnpFeelingActivitySearchText, cnpSearchedActivities.length)}>Load More</button>
                          </div>
                        }
                      </>
                    </div>
                    :
                    // Activities
                    <div className='d-flex flex-wrap overflow-y-scroll' style={{ maxHeight: '50vh' }}>
                      {/* Selected Activity */}
                      <>
                        {
                          cnpSelectedActivity._id
                          &&
                          <div className={`bg-secondary-subtle w-50 p-2 cursor-pointer rounded-3`} onClick={handleRemoveActivity} >
                            <img src={cnpSelectedActivity.activityIcon} className='me-2' style={{ height: '23px', width: '23px' }} alt="icon" />

                            <span>{cnpSelectedActivity.activityName}</span>
                          </div>
                        }
                      </>

                      {
                        cnpActivities && cnpActivities.length > 0
                        &&
                        cnpActivities.map((value, index) => (
                          <>
                            {
                              value._id !== cnpSelectedActivity._id
                              &&
                              <div className='w-50 p-2 cursor-pointer rounded-3' onClick={() => handleSelectActivity(value)} key={index} >
                                <img src={value.activityIcon} className='me-2' style={{ height: '23px', width: '23px' }} alt="icon" />

                                <span>{value.activityName}</span>
                              </div>
                            }
                          </>
                        ))
                      }

                      {/* Load More */}
                      <>
                        {
                          showCnpFeelingLoadMore
                          &&
                          <div className='text-center mt-3 w-100 mb-1'>
                            <button type="button" className='btn btn-primary btn-sm' onClick={() => getActivities(cnpSelectedActivityCategory._id, '', cnpActivities.length)}>Load More</button>
                          </div>
                        }
                      </>
                    </div>
                  }
                </>
              </>
            }
          </>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default CnpFeelingActivityPopup