import React from 'react';
import LeftBlock from '../../Home/LeftBlock/LeftBlock';


const Check = ({ className }) => {

  return (
    <>
      <section className={`section-main ${className || ''}`}>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            {/* Left Block */}
            <div className="col-lg-3 d-none d-lg-block shadow z-1">
              <LeftBlock />
            </div>

            {/* Right Block */}
            <div className="col-lg-9 pt-5 overflow-y-scroll scrollbar-hidden px-3" style={{ background: "lightblue", height: '100vh' }}>
              <h3 className='pt-4 text-center fw-bold'>Check</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Check;