import React, { useRef, useState, useEffect, useContext, useCallback } from 'react';
import { GoogleMap, useLoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import { faClose, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Loader from '../../Loader';
import { IoCloseCircle } from 'react-icons/io5';
import { ApiContext } from '../UseContext/ApiCall';


const GoogleMapApi = ({ input, selectedLocation, setSelectedLocation, setInput, setErr }) => {
    
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60",
        libraries: ["places"]
    });

    const inputRef = useRef();
    const [map, setMap] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const [autocompletedPlaces, setAutocompletedPlaces] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [toggleSearch, setToggleSearch] = useState(true);
    const { setSelectedAddLocation } = useContext(ApiContext)

    // useEffect(() => {
    //     setLoading(!isLoaded);
    // }, [isLoaded]);

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            setSelectedLocation({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            });
        }
    };

    const handlePlaceClick = useCallback((place) => {
        // Update input field with the selected place description
        if (inputRef.current) {
            inputRef.current.value = place.description;
        }
    
        // Extract and format location details
        const location = place.terms && place.terms.length
            ? `${place.terms[place.terms.length - 3].value || ''},${place.terms[place.terms.length - 2].value || ''},${place.terms[place.terms.length - 1].value || ''}`
            : '';
    
        // Update input state with location details
        setInput((prevInput) => ({
            ...prevInput,
            location,
            place: place.structured_formatting.main_text,
        }));
    
        // Clear the autocomplete suggestions
        setAutocompletedPlaces([]);
    
        // Set the selected location or geocode if necessary
        if (place.geometry && place.geometry.location) {
            const latLng = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                city: location.split(",")[0] || '',
                place: place.structured_formatting.main_text,
            };
            setSelectedLocation(latLng);
            setSelectedAddLocation({
                ...latLng,
                location,
            });
        } else {
            const description = place.structured_formatting.main_text;
            const geocoder = new window.google.maps.Geocoder();
    
            // Geocode the place description if no geometry is present
            geocoder.geocode({ address: description }, (results, status) => {
                if (status === "OK" && results[0]) {
                    const resultLocation = results[0].geometry.location;
                    const latLng = {
                        lat: resultLocation.lat(),
                        lng: resultLocation.lng(),
                        city: results[0].address_components[0].long_name || '',
                        place: description,
                    };
                    setSelectedLocation(latLng);
                    setSelectedAddLocation({
                        ...latLng,
                        location: location || description,
                    });
                } else {
                    console.error("Geocoding failed: ", status);
                }
            });
        }
    }, [setSelectedLocation, setSelectedAddLocation, setInput]);
    

    useEffect(() => {
        if (!navigator.geolocation) {
            setError("Geolocation is not supported by your browser");
            return;
        }

        const success = async (position) => {
            const { latitude, longitude } = position.coords;
            fetchAddress(latitude, longitude);
        };

        const error = (err) => {
            setError(`Unable to retrieve your location: ${err.message}`);
        };

        navigator.geolocation.getCurrentPosition(success, error);
    }, [handlePlaceClick]);

    const fetchAddress = async (lat, lng) => {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60`
            );
            if (response.status !== 200) {
                throw new Error('Failed to fetch location data');
            }
            const data = response.data;
            
            if (data.results && data.results.length > 0) {
                const formattedAddress = data.results[0].formatted_address;
                const formatted = formatAddress(formattedAddress);
                const check = data.results[0] && data.results[0].address_components && data.results[0].address_components[0].long_name
                setSelectedLocation({ lat, lng,  location: check, place: formatted });
                setInput({ ...input, location: check, place: formatted });
                setSelectedAddLocation({ ...input,lat, lng, location: check, place: formatted });
            } else {
                throw new Error('Location not found');
            }
        } catch (error) {
            setError(`Failed to fetch location: ${error.message}`);
        }
    };

    const formatAddress = (address) => {
        const parts = address.split(',');
        const formatted = parts.slice(0, 3).join(',') + ',' + parts.slice(-3).join(',');
        return formatted;
    };

    const onInputChange = (event) => {
        setErr && setErr(false);
        const input = event.target.value;
        const service = new window.google.maps.places.AutocompleteService();
        service.getPlacePredictions({ input: input }, (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setAutocompletedPlaces(predictions);
            } else {
                setAutocompletedPlaces([]);
            }
        });
    };

    if (loadError) {
        return <p>Error occurred while loading the map</p>;
    }

     const clearAutocomplete = () => {
        setAutocompletedPlaces([]);
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    return (
        <div style={{ marginTop: "20px" }}>
            {loading ? (
                <div style={{ minHeight: !error && "30vh" }}>
                    <Loader />
                </div>
            ) : (
                (toggleSearch && !error) ? (
                    <div className='container'>
                        <div className='d-flex justify-content-start gap-1 align-items-start'>
                            <img className='mt-2 me-2 current_location ping ' src="/images/current-location.svg" alt="" />
                            <div className='d-flex align-items-start justify-content-between p-0' style={{ maxWidth: "92%" }}>
                                <div className='place_ht'>
                                    <p className='fs-6 fw-bold text-black'>Current location</p>
                                    {/* <input className='border-0' type="search" value={input && input.place} /> */}
                                    <p className=' text-truncate fs-12'>{input && input.place}</p>
                                </div>
                                <div className='mt-3  me-2 fs-5' style={{ cursor: "pointer", color: "rgb(207 99 109)" }} onClick={() => setToggleSearch(false)}><IoCloseCircle /></div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='map-input'>
                        <Autocomplete onLoad={setAutocomplete} onPlaceChanged={onPlaceChanged}>
                            <input
                                onChange={onInputChange}
                                ref={inputRef}
                            />
                        </Autocomplete>
                        {autocompletedPlaces.length > 0 && (
                            <ul className='map-input-list'>
                                {autocompletedPlaces.map((prediction, index) => (
                                    <li onClick={() => handlePlaceClick(prediction)} key={index}>
                                        {prediction.description}
                                        <div className='hr-line'></div>
                                    </li>
                                ))}
                            </ul>
                        )}
                        {autocompletedPlaces && <span onClick={clearAutocomplete} className='map-input-clear'><FontAwesomeIcon icon={faClose} /></span>}

                    </div>
                )
            )}

            {isLoaded && (
                <div className='map-body'>
                    {(error && !selectedLocation) ?

                        <div className='dummy_map'>
                            <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15281523.952038161!2d72.10863842602205!3d20.757245412149782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1718950832729!5m2!1sen!2sin" width="462" height="353" style={{ borderRadius: "20px" }} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>


                        </div>
                        :
                        <GoogleMap
                            mapContainerStyle={{ height: "353px", width: "462px" }}
                            center={selectedLocation}
                            zoom={17}
                            onLoad={(map) => {
                                setMap(map);
                                setLoading(false);
                            }}
                            options={{ zoomControl: false, streetViewControl: false, mapTypeControl: false, fullscreenControl: false }}
                        >
                            {selectedLocation && (
                                <Marker position={selectedLocation} />
                            )}
                        </GoogleMap>}
                    {error === null && <span className='map-center-btn' onClick={() => map.panTo(selectedLocation)}>
                        <span>
                            <FontAwesomeIcon icon={faLocationArrow} />
                        </span>
                    </span>}

                </div>
            )}
            {error && <p className='text-center fs-12' style={{ color: "#d10d0dbd", fontSize: "11px" }}>Please all the location access to get the current location and reload the page </p>}
        </div>
    );
};

export default GoogleMapApi;
