import React, { useContext, useEffect } from 'react'
import { BASE_URL_STATIC } from '../../../config'
import { settingContext } from '../../Emilo/UseContext/SettingContext'
import { getValue } from '../../Emilo/UseContext/SettingHelper';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';
import SettingInputUser from '../../Emilo/Profile/ProfileUser/ProfileUserSetting/SettingInputUser';


const ProfileSetting = () => {

  const {
    value, setValue,

    handleOptionChange,
  } = useContext(settingContext);

  const { config } = useContext(ApiContext);
  

  // To get privacy Added (Value, Followers Except and Hide From )
  useEffect(() => {
    // To get Value
    getValue('scSetting', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);

  return (
    <>
      <section className='px-2 px-sm-3 px-lg-4 px-xl-5 pb-5 pb-lg-0 overflow-y-scroll pt-5' style={{ height: '100vh' }} id='privacyToggle1'>
        {/* Profile Setting */}
        <div className='mt-5 mb-3'>
          <div className='accordion-level-3 shadow-sm accordion-button ps-3 py-2 pe-3 pe-xl-4 rounded-3 bg-white' data-bs-toggle="collapse" data-bs-target="#profile-setting">
            <img src={`${BASE_URL_STATIC}/icon/like-comment.svg`} alt="like-comment" />
            <h5 className='fw-semibold ms-3'>Profile Setting</h5>
          </div>

          <div id='profile-setting' className="accordion-collapse collapse show accordion accordion-flush my-0">
            {/* Partner’s Profile Pic */}
            <div className='ms-3 mt-3 me-auto'>
              <div className='d-flex justify-content-between align-items-center my-2'>
                <h5 className='accordion-level-4 shadow-sm mb-0'>Partner’s Profile Pic</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                    value={value.postLike === 'true' ? false : true}
                    onChange={(e) => handleOptionChange(e, 'privacy')} 
                    checked={value.postLike === 'true' ? true : false} 
                  />
                </div>
              </div>

              <small className='text-secondary d-block ms-3 mt-2'>(Note : if matchmaking not done or Partner Removed show Live Pic else user can change it to Avatar only)</small>
            </div>

            <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

            {/* Partner’s Profile Name */}
            <div className='ms-3 mt-3 me-auto'>
              <div className='d-flex justify-content-between align-items-center my-2'>
                <h5 className='accordion-level-4 shadow-sm mb-0'>Partner’s Profile Name</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                    value={value.postLike === 'true' ? false : true}
                    onChange={(e) => handleOptionChange(e, 'privacy')} 
                    checked={value.postLike === 'true' ? true : false} 
                  />
                </div>
              </div>

              <small className='text-secondary d-block ms-3 mt-2'>(Note : if matchmaking not done or Partner Removed show Real Name else user can change it to single word nickname)</small>
            </div>

            {/* Hide my Soft Corner profile from specific people. */}
            <div className='ms-3 mt-3 me-auto'>
              {
                ((!value.scView) || (value.scView === 'public') || (value.scView === 'followers') || (value.scView === 'fof'))
                &&
                <>
                  <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                  <SettingInputUser 
                    settingNav='privacy' 
                    settingKey={
                      (!value.scView || value.scView === 'public') ? 'scViewPublic' : 
                      value.scView === 'followers' ? 'scViewFollowers' : 
                      value.scView === 'fof' && 'scViewFOF'
                    }
                    settingType={value.scView ? value.scView : 'public'} 
                    header={
                      (!value.scView || value.scView === 'public') ? 'Hide softcorner from specific people' :
                      value.scView === 'followers' ? 'Followers Except' :
                      value.scView === 'fof' && 'Followers and their followers except'
                    }
                    message='You can select name of people whom you don’t want to show your softcorner' 
                  />
                </>
              }
            </div>

            <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />
          </div>
        </div>

        {/* Security Setting */}
        <div className='mb-3'>
          <div className='accordion-level-3 shadow-sm accordion-button ps-3 py-2 pe-3 pe-xl-4 rounded-3 bg-white' data-bs-toggle="collapse" data-bs-target="#security-setting">
            <img src={`${BASE_URL_STATIC}/icon/like-comment.svg`} alt="like-comment" />
            <h5 className='fw-semibold ms-3'>Security Setting</h5>
          </div>

          <div id='security-setting' className="accordion-collapse collapse accordion accordion-flush my-0">
            {/* Change Pin */}
            <div className='ms-3 mt-3 me-auto'>
              <h5 className='accordion-level-4 shadow-sm mb-0'>Change Pin</h5>

              <small className='text-secondary d-block ms-3 mt-2'>(Note : Click here to change your password. You should remember your current password.)</small>
            </div>

            <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

            {/* Partner’s Profile Name */}
            <div className='ms-3 mt-3 me-auto'>
              <h5 className='accordion-level-4 shadow-sm mb-0'>Face Id/Biometric</h5>                

              <small className='text-secondary d-block ms-3 mt-2'>(Note : For more secured login, we suggest using Two Factor Authentication you will be sent an OTP to your registered mobile no or email address after entering your Login ID and Password.)</small>
            </div>

            <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />
          </div>
        </div>

        {/* Notification Setting */}
        <div className='mb-3'>
          <div className='accordion-level-3 shadow-sm accordion-button ps-3 py-2 pe-3 pe-xl-4 rounded-3 bg-white' data-bs-toggle="collapse" data-bs-target="#notification-setting">
            <img src={`${BASE_URL_STATIC}/icon/like-comment.svg`} alt="like-comment" />
            <h5 className='fw-semibold ms-3'>Notification Setting</h5>
          </div>

          <div id='notification-setting' className="accordion-collapse collapse accordion accordion-flush my-0">
            {/* Allow Push Notifications */}
            <div className='ms-3 mt-3 me-auto'>
              <div className='d-flex justify-content-between align-items-center my-2'>
                <h5 className='accordion-level-4 shadow-sm mb-0'>Allow Push Notifications</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                    value={value.postLike === 'true' ? false : true}
                    onChange={(e) => handleOptionChange(e, 'privacy')} 
                    checked={value.postLike === 'true' ? true : false} 
                  />
                </div>
              </div>

              <small className='text-secondary d-block ms-3 mt-2'>(Note : Email regarding activity on your posts & contents)</small>
            </div>

            <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

            {/* Allow Email Notifications */}
            <div className='ms-3 mt-3 me-auto'>
              <div className='d-flex justify-content-between align-items-center my-2'>
                <h5 className='accordion-level-4 shadow-sm mb-0'>Allow Email Notifications</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                    value={value.postLike === 'true' ? false : true}
                    onChange={(e) => handleOptionChange(e, 'privacy')} 
                    checked={value.postLike === 'true' ? true : false} 
                  />
                </div>
              </div>

              <small className='text-secondary d-block ms-3 mt-2'>(Note : Email regarding activity on your posts & contents)</small>
            </div>

            <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />
          </div>
        </div>
      </section>
    </>
  )
}

export default ProfileSetting