import React, { useContext } from 'react';
import { ApiContext } from '../../../UseContext/ApiCall';
import { Modal } from 'react-bootstrap';
import { AwsFileUrl, BASE_URL_STATIC } from '../../../../../config';


const CreateNewPostPopup = () => {
  
  const {
    loggedInUser,

    // ----- Create New Post -----
    showCreateNewPostPopup, setShowCreateNewPostPopup,
    createPostData, setCreatePostData,
    createNewPost,

    // ----- Create New Post > Feeling/Activity -----
    setShowCnpFeelingActivityPopup,
    setCnpFeelingActivityTab,
    cnpSelectedActivityCategory,
    getFeelings, getActivities,

    // ----- Create New Post > Tag -----
    // showCnpTagPopup, setShowCnpTagPopup,

    // ----- Create New Post > Check in/Location -----
    showCnpLocationPopup, setShowCnpLocationPopup,
    cnpLocation, setCnpLocation,

  } = useContext(ApiContext);


  // handle click feeling/activity
  const handleClickFeelingActivity = () => {
    setShowCnpFeelingActivityPopup(true);
    setShowCreateNewPostPopup(false);
    
    if(cnpSelectedActivityCategory._id) {
      setCnpFeelingActivityTab('activity');

      getActivities(cnpSelectedActivityCategory._id, '', 0);
    } else {
      setCnpFeelingActivityTab('feeling');

      getFeelings('', 0);
    };
  };

  // handle click tag
  // const handleClickTag = () => {
  //   setShowCreateNewPostPopup(false);
  //   setShowCnpTagPopup(true);
  // };

  // handle click location
  const handleClickLocation = () => {
    setShowCreateNewPostPopup(false);
    setShowCnpLocationPopup(true);
  };


  return (
    <>
      <Modal show={showCreateNewPostPopup} onHide={() => setShowCreateNewPostPopup(false)} centered backdrop="static" keyboard={false} className="main-modal" >
      {/* <Modal show={true} onHide={() => setShowCreateNewPostPopup(false)} centered backdrop="static" keyboard={false} className="main-modal" > */}
        <Modal.Header className='mb-3 pt-0' closeButton>
          <h5 className='flex-grow-1 text-center pt-3'>Create New Post</h5>
        </Modal.Header>

        <Modal.Body>
          <>
            {
              loggedInUser 
              &&
              <div className='d-flex align-items-center gap-3'>
                {/* Profile */}
                <img src={loggedInUser.profileImg && loggedInUser.profileImg.imageId && AwsFileUrl+loggedInUser.profileImg.imageId} className='border rounded-circle' style={{ height: '45px', width: '45px' }} alt='profile' />

                <div>
                  {/* Name, Feeling, Activity & Check in/Location */}
                  <div>
                    {/* Name */}
                    <span className='fs-5'>{loggedInUser.fname} {loggedInUser.lname}</span> {' '}

                    {/* Feeling */}
                    <>
                      {
                        (createPostData.activityCategoryIcon === '' && createPostData.activityCategoryName === '')
                        && 
                        (createPostData.feelingActivityIcon !== '' && createPostData.feelingActivityName !== '')
                        &&
                        <>
                          <span className='text-secondary'>is</span> {' feeling '}
                          {createPostData.feelingActivityName} {' '}
                          <img src={createPostData.feelingActivityIcon} style={{ height: '17px', width: '17px' }} alt="icon" />
                          {' '}
                        </>
                      }
                    </>

                    {/* Activity */}
                    <>
                      {
                        (createPostData.activityCategoryIcon !== '' && createPostData.activityCategoryName !== '') 
                        && 
                        (createPostData.feelingActivityIcon !== '' && createPostData.feelingActivityName !== '')
                        &&
                        <>
                          <span className='text-secondary'>is</span> {' '}
                          {createPostData.activityCategoryName} {' '}
                          <img src={createPostData.activityCategoryIcon} style={{ height: '17px', width: '17px' }} alt="icon" /> {' '}
                          {createPostData.feelingActivityName} {' '}
                          <img src={createPostData.feelingActivityIcon} style={{ height: '17px', width: '17px' }} alt="icon" /> {' '}
                        </>
                      }
                    </>

                    {/* Check in/Location */}
                    <>
                      {
                        (cnpLocation.place !== '' && cnpLocation.lat !== '' && cnpLocation.lng !== '')
                        &&
                        <>
                          <span className='text-secondary'>at</span> {' '}
                          {cnpLocation.place.split(" ").slice(0, 5).join(" ")}
                          {cnpLocation.place.split(" ").length > 5 && "..."}
                        </>
                      }
                    </>
                  </div>

                  {/* Add Location */}
                  <>
                    {
                      (cnpLocation.place === '' && cnpLocation.lat === '' && cnpLocation.lng === '')
                      &&
                      <div className='cursor-pointer' onClick={handleClickLocation}>
                        <img src={`/images/icons/location-black.svg`} className='me-1' style={{ height: '15px', width: '15px' }} alt="location" />
                        <span>Add Location</span>
                      </div>
                    }
                  </>
                </div>
              </div>
            }
          </>

          {/* Textarea */}
          <textarea className='form-control rounded-4 my-4' style={{ resize: 'none' }} rows={10} name='text' placeholder={`Whats new ${loggedInUser && loggedInUser.fname}..?`}
            value={createPostData.text} onChange={(e) => setCreatePostData({ ...createPostData, [e.target.name]: e.target.value })}
          ></textarea>

          {/* Menu */}
          <div className='d-flex flex-wrap gap-4'>
            <div className="d-flex flex-column align-items-center cursor-pointer">
              <img src={`${BASE_URL_STATIC}/create-icon2.png`} style={{ height: '23px', width: '23px' }} alt="Camera" />
              <small className="text-secondary">Camera</small>
            </div>

            <div className="d-flex flex-column align-items-center cursor-pointer">
              <img src={`${BASE_URL_STATIC}/create-icon3.png`} style={{ height: '23px', width: '23px' }} alt="post" />
              <small className="text-secondary">Photo/Video</small>
            </div>

            <div className="d-flex flex-column align-items-center cursor-pointer">
              <img src={`${BASE_URL_STATIC}/create-icon4.png`} style={{ height: '23px', width: '23px' }} alt="Memes" />
              <small className="text-secondary">Memes</small>
            </div>

            <div className="d-flex flex-column align-items-center cursor-pointer">
              <img src={`${BASE_URL_STATIC}/create-icon5.png`} style={{ height: '23px', width: '23px' }} alt="Flix" />
              <small className="text-secondary">Flix</small>
            </div>

            <div className="d-flex flex-column align-items-center cursor-pointer">
              <img src={`${BASE_URL_STATIC}/create-icon6.png`} style={{ height: '23px', width: '23px' }} alt="Best Clips" />
              <small className="text-secondary">Best Clips</small>
            </div>

            <div className="d-flex flex-column align-items-center cursor-pointer">
              <img src={`${BASE_URL_STATIC}/create-icon10.png`} style={{ height: '23px', width: '23px' }} alt="Gif" />
              <small className="text-secondary">Gif</small>
            </div>

            <div className="d-flex flex-column align-items-center cursor-pointer">
              <img src={`${BASE_URL_STATIC}/create-icon13.png`} style={{ height: '23px', width: '23px' }} alt="Collage" />
              <small className="text-secondary">Collage</small>
            </div>

            <div className="d-flex flex-column align-items-center cursor-pointer">
              <img src={`${BASE_URL_STATIC}/create-icon11.png`} style={{ height: '23px', width: '23px' }} alt="Music" />
              <small className="text-secondary">Music</small>
            </div>

            <div className="d-flex flex-column align-items-center cursor-pointer" onClick={handleClickFeelingActivity}>
              <img src={`${BASE_URL_STATIC}/create-icon8.png`} style={{ height: '23px', width: '23px' }} alt="feeling/activity" />
              <small className="text-secondary">Feeling/Activity</small>
            </div>

            {/* <div className="d-flex flex-column align-items-center cursor-pointer" onClick={handleClickTag}>
              <img src={`/images/icons/tag-blue.svg`} style={{ height: '23px', width: '23px' }} alt="tag" />
              <small className="text-secondary">Tag</small>
            </div> */}

            <div className="d-flex flex-column align-items-center cursor-pointer" onClick={handleClickLocation}>
              <img src={`${BASE_URL_STATIC}/create-icon9.png`} style={{ height: '23px', width: '23px' }} alt="Check In" />
              <small className="text-secondary">Check In</small>
            </div>

            <div className="d-flex flex-column align-items-center cursor-pointer">
              <img src={`${BASE_URL_STATIC}/create-icon12.png`} style={{ height: '23px', width: '23px' }} alt="Background" />
              <small className="text-secondary">Background</small>
            </div>

            <div className="d-flex flex-column align-items-center cursor-pointer">
              <img src={`${BASE_URL_STATIC}/create-icon7.png`} style={{ height: '23px', width: '23px' }} alt="eShow" />
              <small className="text-secondary">eShow</small>
            </div>
          </div>

          {/* Post */}
          <button type="button" className={`btn btn-primary rounded-pill w-100 mt-4`} onClick={createNewPost}
            disabled={
              createPostData.feelingActivityIcon === '' && createPostData.feelingActivityName === '' 
              &&
              cnpLocation.place === '' && cnpLocation.lat === '' && cnpLocation.lng === '' 
            }
          >Post</button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateNewPostPopup;