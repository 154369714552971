import React, { useContext, useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import { ScApiContext } from '../../useContext/softCornerContext';
import { GoogleMap, useLoadScript, Autocomplete, Marker } from '@react-google-maps/api';


const AddUpdateAddress = () => {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60',
    libraries: ['places'],
  });

  const { 
    showAddAddressPopup, setShowAddAddressPopup, 
    address, setAddress,
    addUpdateAddress,
  } = useContext(ScApiContext);

  const addAutocompleteRef = useRef(null);
  
  // Handle autocomplete load
  const handleAddAutocompleteLoad = (autocomplete) => {
    addAutocompleteRef.current = autocomplete;
  };

  // Handle place selection from search
  const handleAddPlaceChanged = () => {
    const place = addAutocompleteRef.current.getPlace();
  
    if (place && place.geometry) {
      setAddress((prev) => ({
        ...prev,
        place: place.formatted_address,
        lat: place.geometry.location.lat().toString(), // Convert to string
        lng: place.geometry.location.lng().toString(), // Convert to string
      }));
    } else {
      alert('Please select a valid location from the suggestions.');
    }
  };

  // Function to get location name using Reverse Geocoding API
  const getLocationName = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60`
      );
      const data = await response.json();
      if (data.status === "OK" && data.results.length > 0) {
        return data.results[0].formatted_address;
      } else {
        return "Unknown Location";
      }
    } catch (error) {
      console.error("Error fetching location name:", error);
      return "Unknown Location";
    }
  };
  
  // Handle map click to set a new pin
  const handleMapClick = async (event) => {
    const lat = event.latLng.lat().toString();
    const lng = event.latLng.lng().toString();
    const placeName = await getLocationName(lat, lng);

    setAddress((prev) => ({
      ...prev,
      place: placeName,
      lat,
      lng,
    }));
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      <Modal show={showAddAddressPopup} onHide={() => setShowAddAddressPopup(false)} size='md' centered backdrop="static" keyboard={false} className="create-call">
      {/* <Modal show={true} onHide={() => setShowAddAddressPopup(false)} size='md' centered backdrop="static" keyboard={false} className="create-call"> */}
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            <h4 className='text-center mb-4'>{address._id ? 'Update' : 'Add'} Address</h4>
            <section className='overflow-y-scroll scrollbar-hidden px-1 pb-3' style={{ maxHeight: '70vh' }}>
              
              {/* Address Category */}
              <div>
                <label htmlFor="category" className='bg-primary text-white px-3 py-2 rounded-pill'>Address Category</label>

                <select className="form-select rounded-pill mb-3" id='category' name='category' 
                  value={address.category} 
                  onChange={(e) => setAddress((prev) => ({ ...prev, category: e.target.value }))}
                >
                  <option value="" disabled>Open this select menu</option>
                  <option value="Home">Home</option>
                  <option value="Office">Office</option>
                  <option value="Partner">Partner</option>
                </select>
              </div>

              {/* Place Search */}
              <Autocomplete onLoad={handleAddAutocompleteLoad} onPlaceChanged={handleAddPlaceChanged}>
                <input 
                  type="search" 
                  className='form-control mb-3 rounded-pill' 
                  placeholder="Search places here..." 
                  value={address.place} 
                  onChange={(e) => 
                    setAddress((prev) => ({ 
                      ...prev, 
                      place: e.target.value, 
                      lat: '', 
                      lng: '', 
                    }))
                  }
                />
              </Autocomplete>

              {/* Google Map */}
              <GoogleMap
                mapContainerStyle={{ height: "250px", width: "100%", borderRadius: '10px' }}
                center={address.lat && address.lng ? { lat: parseFloat(address.lat), lng: parseFloat(address.lng) } : { lat: 28.7041, lng: 77.1025 }}
                zoom={address.lat && address.lng ? 17 : 15}
                mapTypeId="satellite"
                onClick={handleMapClick} // Click on map to set pin
              >   
                {/* Drop Pin on Selected Location */}
                {address.lat && address.lng && 
                  <Marker 
                    position={{ lat: parseFloat(address.lat), lng: parseFloat(address.lng) }} 
                    draggable={true} 
                    onDragEnd={async (e) => {
                      const lat = e.latLng.lat().toString();
                      const lng = e.latLng.lng().toString();
                      const placeName = await getLocationName(lat, lng);
                      
                      setAddress((prev) => ({
                        ...prev,
                        place: placeName,
                        lat,
                        lng,
                      }));
                    }} 
                  />
                }
              </GoogleMap>


              {/* Button */}
              <button type='button' className='btn btn-primary rounded-pill px-5 mt-3 w-100' onClick={addUpdateAddress}
                disabled={!address.category || !address.place || !address.lat || !address.lng}
              >{address._id ? 'Update' : 'Add'} Address</button>
            </section>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddUpdateAddress;