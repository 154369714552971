import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import Loader from '../../Loader';


const EmojiPopup = ({ setOpenEmoji, selectedEmoji, setSelectedEmoji }) => {
  
  const [feelings, setFeelings] = useState([]);
  const [activities, setActivities] = useState([]);
  const [activityCate, setActivityCate] = useState([]);
  const [loader, setLoader] = useState(false);
  const [toggleFeelings, setToggleFeelings] = useState(true);
  const [switchActivity, setSwitchActivity] = useState(false);
  const [categoryName, setCategoryName] = useState(null);



  const handleFeelings = async () => {
    setLoader(true);
    const res = await axios.post('https://emilonode.sjain.io/post/getFeelings');
    setLoader(false);
    console.log("data1234",  res.data)
    return res.data;
   
  };

  const handleActiviyCate = async () => {
    setLoader(true);
    const res = await axios.post('https://emilonode.sjain.io/post/getActivityCategory');
    setLoader(false);
    return res.data;
  };

  const handleActivities = async (categoryName) => {
    setLoader(true);
    setCategoryName(categoryName);
    setSwitchActivity(true);
    const res = await axios.post('https://emilonode.sjain.io/post/getActivity', { categoryName });
    setActivities([categoryName, res.data[0].data]);
    setLoader(false);
    return res.data;
  };

  useEffect(() => {
    setSwitchActivity(false);
    setToggleFeelings(true);
    setCategoryName(null);
    handleFeelings().then((res) => {
      setFeelings([{ isFeeling: "is feeling" }, { feelingName: res }]);
    });
    handleActiviyCate().then((res) => {
      setActivityCate(res);
    });
  }, []);

  const handleItemClick = (item) => {
    if (toggleFeelings) {
      setSelectedEmoji((prevEmotion) => {
        if (prevEmotion && prevEmotion.emotionName === item.name && prevEmotion.emotion === "is feeling") {
          setOpenEmoji(false);
          return null; // Deselect the emoji
        }
        setOpenEmoji(false);
        return { emotion: "is feeling", emotionName: item.name, icon: item.img };
      });
    } else {
      setSelectedEmoji((prevEmotion) => {
        if (prevEmotion && prevEmotion.emotionName === item.name && prevEmotion.emotion === categoryName) {
          setOpenEmoji(false);
          return null; // Deselect the emoji
        }
        setOpenEmoji(false);
        return { emotion: categoryName, emotionName: item.name, icon: item.img };
      });

    }
  };

  console.log("selectedEmoji" , selectedEmoji);
  

  const filterFeelings = (e) => {
    const inputValue = e.target.value.toLowerCase();

    

    if (inputValue === '') {
      handleFeelings().then((res) => {
        setFeelings([{ isFeeling: "is feeling" }, { feelingName: res }]);
      });
      handleActiviyCate().then((res) => {
        setActivityCate(res);
      });
      if (categoryName !== null) {
        handleActivities(categoryName).then((res) => {
          setActivities([categoryName, res[0].data]);
        });
      }
    } else {
      if (toggleFeelings) {
        setFeelings((prev) => [
          prev[0], // Preserve the "is feeling" entry
          {
            feelingName: prev[1].feelingName.filter((feel) =>
              feel.name.toLowerCase().includes(inputValue)
            )
          }
        ]);
      } else if (!switchActivity) {
        setActivityCate((prev) =>
          prev.filter((category) =>
            category.categoryName.toLowerCase().includes(inputValue)
          )
        );
      } else {
        setActivities((prev) =>
          [prev[0], prev[1].filter((activity) =>
            activity.name.toLowerCase().includes(inputValue)
          )]
        );
      }
    }
  };

  return (
    <div className='position-relative text-center row flex-column align-items-center'>
      <nav className='position-relative feelings_nav mt-2'>
        <div style={{ zIndex: '5' }} className='mb-3 nav nav-tabs' id='nav-tab' role='tablist'>
          <button onClick={() => { setToggleFeelings(true); setCategoryName(null); setSwitchActivity(false); }} className='col-6 nav-link active' id='nav-home-tab' data-bs-toggle='tab' type='button' role='tab' aria-controls='nav-home' aria-selected='true'>Feelings</button>
          <button onClick={() => setToggleFeelings(false)} className='col-6 nav-link' id='nav-profile-tab' data-bs-toggle='tab' type='button' role='tab' aria-controls='nav-profile' aria-selected='false'>Activities</button>
        </div>

        {categoryName !== null && <button className='cate_btn' onClick={() => { setSwitchActivity(false); setCategoryName(null); }}>&#11164; {categoryName}</button>}
      </nav>

      <div className='d-flex align-items-center p-1 mb-3 rounded-pill bg-secondary-subtle shadow-sm'>
        <span className='px-2'><IoSearch /></span>
        <input type='search' className='w-100 rounded-pill ps-1 border-0 bg-transparent' onChange={filterFeelings} name='' id='' placeholder='search...' />
      </div>

      {loader ?
        <div className='position-relative' style={{ height: "60vh" }}>
          <div className="loader-hw">
            <Loader />
          </div>
        </div>
        : <div style={{ maxWidth: '500px' }} className='position-relative row row-cols-12 justify-content-between' id='nav-home' role='tabpanel' aria-labelledby='nav-home-tab' tabIndex='0'>
          {toggleFeelings ?
            <div style={{ height: "50vh" }} className='overflow-y-scroll scrollbar-hidden'>
              <div className='row justify-content-center'>
                {feelings[1] && feelings[1].feelingName.map((e, i) => (
                  <div onClick={() => handleItemClick(e)} key={i} className='col-5 m-1 px-1 py-2 rounded feel_list' style={{ background: `${selectedEmoji && selectedEmoji.emotionName === e.name ? '#5d94e04d' : ''}` }}>
                    <div className='d-flex gap-3 align-items-center'>
                      <img className='feeling-img mx-2' src={e.img} alt='' />
                      <p className='text-start'>{e.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            :
            !switchActivity ? (
              activityCate.map((e, i) => (
                <div onClick={() => handleActivities(e.categoryName)} key={i} className='col-5 m-1 px-1 py-2 rounded feel_list'>
                  <div className='d-flex gap-3 align-items-center'>
                    <img className='feeling-img mx-2' src={e.categoryImage} alt='' />
                    <p className='text-start'>{e.categoryName}</p>
                  </div>
                </div>
              ))
            ) : (
              activities && activities[1].map((e, i) => (
                <div key={i} onClick={() => handleItemClick(e)} className='col-12 m-1 px-1 py-2 rounded feel_list' style={{ background: `${selectedEmoji && selectedEmoji.emotionName === e.name ? '#5d94e04d' : ''}` }}>
                  <div className='d-flex gap-3 align-items-center'>
                    <img className='feeling-img mx-2' src={e.img} alt='' />
                    <p className='text-start'>{e.name}</p>
                  </div>
                </div>
              ))
            )
          }
        </div>}
    </div>
  );
};

export default EmojiPopup;