import React, { useContext } from 'react';
import { ApiContext } from '../../UseContext/ApiCall';
import Modal from "react-bootstrap/Modal";


const AddToCloseFriendsPopup = () => {

  const {
    showAddToCloseFriendsPopup, setShowAddToCloseFriendsPopup,
    addToCloseFriendsUserId,
    addToCloseFriendsValue, setAddToCloseFriendsValue,
    addRemoveCloseFriend,
  } = useContext(ApiContext);

  console.log('addToCloseFriendsUserId : ', addToCloseFriendsUserId);
  

  // handle add/remove close friends
  const handleAddRemove = (e) => {
    const { name, checked } = e.target;

    addRemoveCloseFriend(name, addToCloseFriendsUserId, checked === true ? "add" : "remove");

    setAddToCloseFriendsValue(preState => ({
      ...preState,
      [name] : checked === true ? "true" : "false"
    }))
  };

  return (
    <>
      <Modal show={showAddToCloseFriendsPopup} onHide={() => setShowAddToCloseFriendsPopup(false)} size="sm" centered backdrop="static" keyboard={false} className="main-modal" >
      {/* <Modal show={true} onHide={() => setShowAddToCloseFriendsPopup(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" > */}
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h5 className='mb-4 text-center'>Close Friends</h5>

          <div class="form-check float-none mb-2 w-75 ms-auto">
            <input type="checkbox" class="form-check-input" checked={addToCloseFriendsValue.listOne === 'true'} onChange={handleAddRemove} name='listOne' id="listOne" />
            <label class="form-check-label" htmlFor="listOne">List 1</label>
          </div>

          <div class="form-check float-none mb-2 w-75 ms-auto">
            <input type="checkbox" class="form-check-input" checked={addToCloseFriendsValue.listTwo === 'true'} onChange={handleAddRemove} name='listTwo' id="listTwo" />
            <label class="form-check-label" htmlFor="listTwo">List 2</label>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddToCloseFriendsPopup;