import React, { useContext, useRef } from 'react'
import { BASE_URL_STATIC } from '../../../config'
import { ScApiContext } from '../useContext/softCornerContext';


const ProfileVerify = () => {

  const {
    updateScProfileImage,
  } = useContext(ScApiContext);

  const fileInputRef = useRef(null); // Ref to access the file input element

  // Function to trigger file input click
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  // Handle the file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      console.log("Selected file:", file);

      updateScProfileImage(file);
    };
  };

  return (
    <>
      {/* Verify Identity */}
      <section className='vh-100 text-center pt-5 pb-5 pb-lg-0 overflow-y-scroll scrollbar-hidden'>
        <img src={`${BASE_URL_STATIC}/SoftCorner/verify-live-image.png`} className='mt-3 mt-md-4 mt-lg-5 cursor-pointer' onClick={handleImageClick} alt="verify-live" height={300} width={300} />

        <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} accept='image/*' />

        <span className='w-75 my-4 my-sm-5'>For identity verification, we kindly request you to complete the necessary steps by scanning a live image. This process helps us ensure the authenticity of your identity, and it involves capturing a real-time image to confirm your verification. Your cooperation in completing this step is greatly appreciated.</span>

        <button type='button' className='btn btn-primary rounded-pill px-4 px-sm-5 mb-4 mb-lg-0'>Upload My Live Image</button>
      </section>
    </>
  )
}

export default ProfileVerify