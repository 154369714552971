import React, { useContext, useRef, useState } from 'react';
import { ApiContext } from '../../../../UseContext/ApiCall';
import { Modal } from 'react-bootstrap';
import { GoogleMap, useLoadScript, Autocomplete, Marker } from '@react-google-maps/api';


const CnpLocationPopup = () => {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60',
    libraries: ['places'],
  });

  const [location, setLocation] = useState({
    place: '',
    lat: '',
    lng: '',
  });

  
  const {
    setShowCreateNewPostPopup,

    createPostData, setCreatePostData,

    showCnpLocationPopup, setShowCnpLocationPopup,
    cnpLocation, setCnpLocation,
  } = useContext(ApiContext);

  const addAutocompleteRef = useRef(null);
  
  // Handle autocomplete load
  const handleAddAutocompleteLoad = (autocomplete) => {
    addAutocompleteRef.current = autocomplete;
  };

  // Handle place selection from search
  const handleAddPlaceChanged = () => {
    const place = addAutocompleteRef.current.getPlace();
  
    if (place && place.geometry) {
      setLocation((prev) => ({
        ...prev,
        place: place.formatted_address,
        lat: place.geometry.location.lat().toString(),
        lng: place.geometry.location.lng().toString(),
      }));
    } else {
      alert('Please select a valid location from the suggestions.');
    }
  };

  // Function to get location name using Reverse Geocoding API
  const getLocationName = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60`
      );
      const data = await response.json();
      if (data.status === "OK" && data.results.length > 0) {
        return data.results[0].formatted_address;
      } else {
        return "Unknown Location";
      }
    } catch (error) {
      console.error("Error fetching location name:", error);
      return "Unknown Location";
    }
  };

  // Handle map click to set a new pin
  const handleMapClick = async (event) => {
    const lat = event.latLng.lat().toString();
    const lng = event.latLng.lng().toString();
    const placeName = await getLocationName(lat, lng);

    setLocation((prev) => ({
      ...prev,
      place: placeName,
      lat,
      lng,
    }));
  };

  // handle add location
  const handleAddLocation = () => {
    setShowCnpLocationPopup(false);
    setShowCreateNewPostPopup(true);

    setCnpLocation(location);

    setCreatePostData((preState) => ({
      ...preState,
      postType: 'location'
    }))
  };

  // handle close location popup
  const handleCloseLocationPopup = () => {
    setShowCnpLocationPopup(false);
    setShowCreateNewPostPopup(true);
  };


  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      <Modal show={showCnpLocationPopup} onHide={handleCloseLocationPopup} centered backdrop="static" keyboard={false} className="main-modal" >
      {/* <Modal show={true} onHide={handleCloseLocationPopup} centered backdrop="static" keyboard={false} className="main-modal" > */}
        <Modal.Header className='mb-3 pt-0' closeButton>
          <h5 className='flex-grow-1 text-center pt-3'>Check in</h5>
        </Modal.Header>

        <Modal.Body className='pt-1'>
          {/* Place Search */}
          <Autocomplete onLoad={handleAddAutocompleteLoad} onPlaceChanged={handleAddPlaceChanged}>
            <input 
              type="search" 
              className='form-control mb-3 rounded-pill' 
              placeholder="Search places here..." 
              value={location.place} 
              onChange={(e) => {
                setLocation((prev) => ({ 
                  place: e.target.value, 
                  lat: '', 
                  lng: '', 
                }));

                if(e.target.value === '') {
                  setCnpLocation({
                    place: '', 
                    lat: '', 
                    lng: '', 
                  })
                }
              }}
            />
          </Autocomplete>

          {/* Google Map */}
          <GoogleMap
            mapContainerStyle={{ height: "250px", width: "100%", borderRadius: '10px' }}
            center={location.lat && location.lng ? { lat: parseFloat(location.lat), lng: parseFloat(location.lng) } : { lat: 28.7041, lng: 77.1025 }}
            zoom={location.lat && location.lng ? 17 : 15}
            mapTypeId="satellite"
            onClick={handleMapClick} // Click on map to set pin
          >   
            {/* Drop Pin on Selected Location */}
            { 
              (location.lat !== '' && location.lng !== '') 
              && 
              <Marker 
                position={{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }} 
                draggable={true} 
                onDragEnd={async (e) => {
                  const lat = e.latLng.lat().toString();
                  const lng = e.latLng.lng().toString();
                  const place = await getLocationName(lat, lng);
                  
                  setLocation({ place, lat, lng });
                }} 
              />
            }
          </GoogleMap>

          {/* Button */}
          <button type='button' className='btn btn-primary rounded-pill px-5 mt-3 w-100' onClick={handleAddLocation}
            disabled={!location.place || !location.lat || !location.lng}
          >Add</button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CnpLocationPopup