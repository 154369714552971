import React, { useState, useEffect, useContext, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { ApiContext } from '../../../UseContext/ApiCall';
import Slider from 'react-slick';
import Skeleton from 'react-loading-skeleton';
import ViewPosts from '../../../Button/ViewPosts';
import LikeButton from '../../../Button/LikeButton/LikeButton.js';
// import FollowUnfollowButton from '../../../Button/FollowUnfollowButton';
import { AwsFileUrl, BASEURL, BASE_URL_STATIC } from '../../../../../config.js';
import PostTime from './PostTime.js';
import { getPostComment } from '../../../UseContext/ApiHelpers.jsx';
import FavouritePostButton from '../../../Button/FavouriteButton/FavouritePostButton.jsx';
import ProfilePostPopup from '../../../Popup/Profile/ProfileShow/ProfilePostPopup.js';
import ViewPostButton from '../../../Button/ViewButton/ViewPostButton.jsx';
import Offcanvas from 'react-bootstrap/Offcanvas';
// import { Spinner } from 'react-bootstrap';
import AddComments from '../../../Button/AddComments.jsx';
import ProfilePostCommentThreeDotPopup from '../../../Popup/Profile/ProfileShow/CommentThreeDot/ProfilePostCommentThreeDotPopup.js';
import PostSharePopup from '../../../Popup/Share/PostSharePopup.js';
import ProfilePostThreeDotPopup from '../../../Popup/Profile/ProfileShow/ThreeDot/ProfilePostThreeDotPopup.js';

import ViewCheckinText from '../../../Button/ViewCheckinText.jsx';
import { getAllPostData2, getTrendingFeedsApi } from '../../../UseContext/ApiHelpers.jsx';
import PostTagModel from './PostTagModel.js';
import { ReadMoreData } from '../../../Data/EducationData.jsx';
import { useLocation } from 'react-router-dom';
import Userpopup from '../../../Popup/Userpopup.js';
import HidePostmessage from "../../../Popup/Profile/ProfileShow/ThreeDot/HidePostmessage.js"
import FollowRequest from '../../../Button/FollowRequest.jsx';
import { FaHeartCircleCheck } from "react-icons/fa6";


const CenterBlockPosts = () => {

  const location = useLocation();

  const [delOpen, setDelOpen] = useState(false)
  const [status, setStatus] = useState(0);
  const [loder, setLoader] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [shareData, setShareData] = useState(null)
  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [showMobileComments, setShowMobileComments] = useState(false);
  const [commentType] = useState(null);
  const [showPostSharePopup, setShowPostSharePopup] = useState(false);
  const [tagOpen, setTagOpen] = useState(false);
  const [tagsData, setTagsData] = useState([]);

  //---------------comment states-----------------------
  const [clickedreply, setClickedReply] = useState(false)
  const [viewReply, setViewReply] = useState(false)
  const [comment, setComment] = useState();
  const [mediaId] = useState(null)
  const [showProfilePostCommentThreeDotPopup, setShowProfilePostCommentThreeDotPopup] = useState(false)

  // const [iframeCode, setIframeCode] = useState('');

  //---------------My Changes-----------------------

  const { 
    config, ReadMore, comments, setComments, trendingfeed, loggedInUserId, loader, 
    parentSpinner, setParentSpinner, allDataPost, setallDataPost, 
    handleFollowUnfollow, 

    setShowProfilePostThreeDotPopup,
    postsPopupData, setPostsPopupData,
  } = useContext(ApiContext);

  // let hideList = localStorage.getItem('hide') && Array.from(new Set(localStorage.getItem('hide').split(',')))

  const sliderRef = useRef();

  const feedslider = {
    autoplay: false,
    arrows: false,
    dots: true,
    slidesToShow: 1,
    pauseOnHover: true,
    adaptiveHeight: true
  };

  // handle three dot click
  const handleThreeDotsClick = (post) => {

    // generateIframeCode(post);

    // if (loggedInUserId !== post.postedBy) {
    //   setPostId(post);
      setShowProfilePostThreeDotPopup(true);
      setPostsPopupData(post);
    // };
  };

  const handleThreeDotsUser = (post) => {
    setPostsPopupData(post);
    setShowPopup(true);
  };

  // To handle post share
  const handlePostShare = (postId, data) => {
    if (postId) {
      setPostId(postId);
    }
    setShareData(data)
    setShowPostSharePopup(true);
  };

  // To handle comment popup
  const handleCommentPopup = (post) => {

    if (post._id) {
      setShowPostsPopup(true);
      setPostsPopupData(post);
      setPostId(post._id)
    }
  };

  // To handle mobile comment popup
  const handleMobileCommentPopup = () => {
    setShowMobileComments(false);
    setComments('');
  };

  // const generateIframeCode = (postsPopupData) => {

  //   let textWithoutNestedIframes = '';

  //   if (postsPopupData.type) {
  //     textWithoutNestedIframes = removeNestedIframes(postsPopupData.description);
  //     const iframeCode = `<iframe srcdoc="${textWithoutNestedIframes}" width="400" height="200" frameborder="0"></iframe>`;
  //     setIframeCode(iframeCode);
  //   } else {
  //     const iframeCode = `<iframe width="1248" height="755" src="https://images.pexels.com/photos/7381200/pexels-photo-7381200.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen ></iframe>`
  //     setIframeCode(iframeCode);
  //   }
  // };

  // const removeNestedIframes = (text) => {
  //   const regex = /<iframe.*?<\/iframe>/g;
  //   return text && text.replace(regex, '');
  // };

  const handleTagMessage = (data) => {

    if (Array.isArray(data) && data.length > 0) {
      if (data.length < 2) {
        return ` with ${data[0].fname}`
      }
      else if (data.length < 3) {
        return ` with ${data[0].fname} and  ${data[1].fname}`
      }
      else if (data.length >= 3) {
        return ` with ${data[0].fname} and  ${data.length - 1} others`
      }
    }
    else {
      return ''
    }
  };

  const followButton = (userId, accountPrivacy) => {
    let updatedPosts;
    if (accountPrivacy === "private") {

      updatedPosts = allDataPost.map(post =>
        post.postedBy === userId ? { ...post, follow: 3 } : post
      );
      return setallDataPost(updatedPosts)

    } else {
      updatedPosts = allDataPost.map(post =>
        post.postedBy === userId && post.follow !== 3 ? { ...post, follow: 1 } : post
      );
      return setallDataPost(updatedPosts)

    }
  };


  useEffect(() => {
    setClickedReply(false)
    setViewReply(false)
    getPostComment(mediaId, config)
  }, [mediaId, setShowMobileComments]);
  
  // To handle click on three dots of post of center block of home page
  useEffect(() => {
    const fetchData = async () => {
      try {
        let res;
        if (location.pathname === '/trendings') {
          res = await getTrendingFeedsApi(1, config);
        } else {
          res = await getAllPostData2("postType", 1, config);
        }
        // console.log("res.posts", res)
        setallDataPost(res.posts);
        setLoader(false);

      } catch (err) {
        console.error(err);

      }
    };

    fetchData();
  }, [location]);

  return (
    <>
      {/* ----- Popup ----- */}
      <>
        {/* Profile Post Three Dot Popup */}
        <ProfilePostThreeDotPopup setShowPostsPopup={setShowPostsPopup} 
          // iframeCode={iframeCode}
        />

        {/* Three Dot Popup */}
        <FollowRequest delOpen={delOpen} setDelOpen={setDelOpen} setStatus={setStatus} status={status} />

        {/* Post image popup */}
        <ProfilePostPopup 
          postId={postId} 
          showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} 
          postsPopupData={postsPopupData} setPostsPopupData={setPostsPopupData} 
        />

        {/* Profile User > Three Dot > Share Popup */}
        <PostSharePopup shareData={shareData} showPostSharePopup={showPostSharePopup} setShowPostSharePopup={setShowPostSharePopup} />

        <PostTagModel mediaTags={tagsData} tagOpen={tagOpen} setTagOpen={setTagOpen} />
        {/* <PostTagModel mediaTags={tagsData} tagOpen={tagOpen} setTagOpen={setTagOpen} /> */}

        <Userpopup showPopup={showPopup} setShowPopup={setShowPopup} postsPopupData={postsPopupData} setPostsPopupData={setPostsPopupData}/>
        {/* <Userpopup showPopup={showPopup} setShowPopup={setShowPopup} postsPopupData={postsPopupData} /> */}

        {/* Mobile > Comments */}
        <Offcanvas show={showMobileComments} onHide={handleMobileCommentPopup} placement="bottom">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Comments</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>

            <ProfilePostCommentThreeDotPopup showProfilePostCommentThreeDotPopup={showProfilePostCommentThreeDotPopup} setShowProfilePostCommentThreeDotPopup={setShowProfilePostCommentThreeDotPopup} comment={comment} getPostComment={getPostComment} setComment={setComment} />

            <AddComments commentType={commentType} setComment={setComment} clickedreply={clickedreply} setClickedReply={setClickedReply} postId={mediaId} viewReply={viewReply} comments={comments} setComments={setComments} parentSpinner={parentSpinner} setParentSpinner={setParentSpinner} setShowProfilePostCommentThreeDotPopup={setShowProfilePostCommentThreeDotPopup} />

          </Offcanvas.Body>
        </Offcanvas>
      </>


      <section className='cb-post w-100 mx-auto'>
        {
          allDataPost && allDataPost.length === 0 && loder 
          &&
          <span className='cb-post-spinner'>
            <div className='text_loader' style={{ top: "60%" }}></div>
          </span>
        }

        {
          allDataPost
          &&
          allDataPost.map((value, index) => (
            <>
              {value.hide ? <HidePostmessage ids={value._id} /> :

                <div className="feedBox"  key={index}>

                  {/* User */}
                  <div className="userArea">
                    <div className="userDetail" >
                      <div className="status me-2 me-lg-3">
                        {
                          value.imageId
                          ?
                          <img className='rounded-circle' src={`${AwsFileUrl + value.imageId}`} alt="profile" style={{ width: "40px", height: "40px" }} />
                          :
                          <img className='rounded-circle' src={`${BASE_URL_STATIC}/user-icon2.jpg`} alt="profile" />
                        }

                        {
                          (value.accountPrivacy === "listOne" || value.accountPrivacy === "listtwo") 
                          ? 
                          <div>
                            <FaHeartCircleCheck style={{ color: '#db192b', fontSize: '24px' }} />
                          </div>
                          : 
                          null
                        }

                      </div>


                      <div className="nameBox">
                        <div>
                          <NavLink to={`${BASEURL}/profile/${value.postedBy}`} className='cb-post-head-name'>
                            {!loader ? value.fname + " " : <Skeleton width={180} height={15} />}</NavLink>
                          {value.emotion && <span className='cp'>
                            <span className='cb-post-head-aen'>
                              {value.emotion} &nbsp;
                              <img src={value.emotionIcon} alt="icon" width={20} /> &nbsp;
                              {value.emotionName}</span>
                            {(value.type === 'text' || value.type === 'location' || value.type === 'emotion') && <span className='ms-2 text-secondary fs-14' onClick={(e) => { setTagOpen(true); setTagsData(value.mediaUrls[0] && value.mediaUrls[0].tags); }}>{handleTagMessage(value.mediaUrls[0] && value.mediaUrls[0].tags)}</span>}
                          </span>}
                        </div>

                        <p>
                          {
                            (!loader)
                            ?
                            <PostTime date={value.createdAt} />
                            :
                            <Skeleton width={50} height={10} />
                          } • &nbsp; {value.title}
                        </p>
                      </div>
                    </div>


                    <div className="userFollow">
                      <div style={{ marginRight: '10px' }} onClick={() => followButton(value.postedBy, value.accountPrivacy)} >
                        {
                          // loggedInUserId !== value.postedBy && value.follow !== 1 && value.follow === 3 
                          // ?
                          // <button type="button" className='followUnfollowBtn2' onClick={() => { setDelOpen(true); setOtherUserId(value.postedBy) }}>Requested</button>
                          // :
                          // <FollowUnfollowButton otherUserId={value.postedBy} />

                          loggedInUserId !== value.postedBy
                          &&
                          <button type="button" className='btn btn-primary btn-sm' onClick={() => handleFollowUnfollow(value.postedBy)}>{value.buttonText}</button>
                        }
                      </div>

                      {
                        loggedInUserId === value.postedBy 
                        ?
                        <span onClick={() => handleThreeDotsUser(value)} style={{ cursor: 'pointer' }} >
                          <i className="far fa-ellipsis-v" />
                        </span> 
                        :
                        <span onClick={() => handleThreeDotsClick(value)} style={{ cursor: 'pointer' }} >
                          <i className="far fa-ellipsis-v" />
                        </span>
                      }
                    </div>
                  </div>


                  {/* posted image */}
                  <div className="contentArea">
                    {
                      value._id && value.type !== 'text' && value.mediaUrls
                      &&
                      <div className="imgBox">
                        {
                          (!loader)
                            ?
                            <Slider ref={sliderRef} {...feedslider}>
                              {value._id && value.mediaUrls.map((image, index) => (
                                <ViewPosts showPostsPopup={showPostsPopup} post={value} index={index} fileType={image.filetype} imageSrc={image.url && image.url} vidSrc={image.url && image.url} key={image._id} mediaTags={image.tags} trendingfeed={trendingfeed} />
                              ))}
                            </Slider>
                            :
                            <Skeleton width={450} height={450} />
                        }
                      </div>
                    }
                    <>
                      <ViewCheckinText post={value} />
                    </>
                  </div>

                  {value.type === 'text' ?
                    <div className="text_post mb-4">
                      <ReadMoreData>
                        <div dangerouslySetInnerHTML={{ __html: value.description }}></div>
                      </ReadMoreData>
                    </div>

                    :
                    <div className="textArea">
                      <ReadMore>
                      {value.description}
                 
                      </ReadMore>
                     
                    
                    </div>}

                  <div className="likeArea">
                    <div className="leftBox d-flex" style={{ alignItems: 'center' }}>
                      {!loader ? (
                        <>
                          <span>
                            {value._id && <ViewPostButton viewCount={value.viewcount} postId={value._id} />}
                          </span>

                          <span>
                            {value._id && <LikeButton postedBy={value.postedBy} likecounts={value.likecount} postId={value._id} postType={value.type} />}
                          </span>

                          <span className='commentBtn-span'>
                            <img className='commentBtn commentBtnWeb' src={`${BASE_URL_STATIC}/icon/comment-1.svg`} alt="comment" onClick={() => handleCommentPopup(value)} style={{ cursor: 'pointer' }} />

                            <img className='commentBtn commentBtnMobile' src={`${BASE_URL_STATIC}/icon/comment-1.svg`} alt="comment" onClick={() => handleCommentPopup(value)} style={{ cursor: 'pointer' }} />
                            {value._id && value.commentcount}
                          </span>

                          <span onClick={() => handlePostShare(value._id, value)}>
                            <img className='shareBtn' src={`${BASE_URL_STATIC}/icon/share-1.svg`} alt="share" style={{ cursor: 'pointer' }} />
                            {value._id && value.shareCount}
                          </span>

                          {/* <SearchComponent open={openSharePopup} close={() => setOpenSharePopup(false)} /> */}
                        </>
                      ) : (
                        <>
                          <Skeleton width={30} height={30} />
                          <div className="divider"></div>
                          <Skeleton width={30} height={30} />
                          <div className="divider"></div>
                          <Skeleton width={30} height={30} />
                          <div className="divider"></div>
                          <Skeleton width={30} height={30} />
                        </>
                      )}
                    </div>

                    <div className="rightBox">
                      {!loader ? (
                        <>
                          <span style={{ cursor: 'pointer' }}>
                            {value._id && <FavouritePostButton postId={value._id} isfavorite={value.isfavorite} />}
                          </span>

                          {/* <span onClick={() => handleDownload(post.postId)}> */}
                          <span >
                            <img className='downloadBtn' src={`${BASE_URL_STATIC}/icon/download-1.svg`} alt="download" />
                          </span>
                        </>
                      ) : (
                        <>
                          <Skeleton width={30} height={30} />
                        </>
                      )}
                    </div>
                  </div>

                  {/* <hr className='cb-post-hr' /> */}

                  <span>{value.time}</span>
                </div>}
            </>
          ))
        }
      </section>
    </>
  );
};

export default CenterBlockPosts;