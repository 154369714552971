import React, { createContext, useContext, useState } from "react";
// import { getWorkDetails } from "./ApiHelpers";
import axios from "axios";
import { BASEAPIURL } from "../../../config";
import { ApiContext } from "./ApiCall";
import toast from "react-hot-toast";


export const profileContext = createContext();

export const ProfileProvider = ({ children }) => {

  const [userProfile, setUserProfile] = useState("")
  const [saveLoader, setSaveLoader] = useState(false)
  const [address, setAddress] = useState([])
  const [hangoutDetails, setHangoutDetails] = useState([])
  const [skillData, setSkillData] = useState([])
  const [social, setSocial] = useState([])
  const [workData, setWorkData] = useState([])
  const [zodiacVal, setzodiacVal] = useState('')
  const [educationDetails, setEducationDetails] = useState([])
  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const [bioInput, setBioInput] = useState('')
  const [movieDetails, setMovieDetails] = useState([])
  const [foodDetails, setfoodDetails] = useState([])
  const [relationsData, setRelationsData] = useState([])
  const [workInput, setworkInput] = useState({ companyName: "", startDate: new Date().getFullYear(), endDate: new Date().getFullYear(), currentlyWorking: false, about: '', jobTitle: '', campanyLocation: '', workType: 'Job' })


  const [profilePostData, setProfilePostData] = useState("")
  const [profileReelData, setProfileReelData] = useState("")
  const [profileMemeData, setProfileMemeData] = useState("")
  const [profileBestclipData, setProfileBestclipData] = useState("")


  const {
    config,
  } = useContext(ApiContext);





  // ----------- Profile > Edit -----------

  const [profileEditViewValues, setProfileEditViewValues] = useState({});

  // get profile edit view permission (other user ke profile edit section me mai kya kya view kr sakta hu)
  const getProfileEditView = (otherUserId) => {
    const payload = {
      otherUserId
    };

    axios.post(`${BASEAPIURL}/user/getProfileEditView`, payload, config)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setProfileEditViewValues(res.data.values);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  




  // ----------- Profile > User > Followers/Followings Popup -----------

  const [showFollowerFollowingTab, setShowFollowerFollowingTab] = useState('followers');
  const [showFollowerFollowingPopup, setShowFollowerFollowingPopup] = useState(false);

  const [showFollowersLoadMore, setShowFollowersLoadMore] = useState(true);
  const [showFollowingsLoadMore, setShowFollowingsLoadMore] = useState(true);
  const [showPendingsLoadMore, setShowPendingsLoadMore] = useState(true);
  const [showRequestsLoadMore, setShowRequestsLoadMore] = useState(true);
  const [showMutualsLoadMore, setShowMutualsLoadMore] = useState(true);

  const [followersSearchText, setFollowersSearchText] = useState('');
  const [followingsSearchText, setFollowingsSearchText] = useState('');
  const [pendingsSearchText, setPendingsSearchText] = useState('');
  const [requestsSearchText, setRequestsSearchText] = useState('');
  const [mutualsSearchText, setMutualsSearchText] = useState('');

  const [followers, setFollowers] = useState([]);
  const [followings, setFollowings] = useState([]);
  const [pendings, setPendings] = useState([]);
  const [requests, setRequests] = useState([]);
  const [mutuals, setMutuals] = useState([]);

  const [searchedFollowers, setSearchedFollowers] = useState([]);
  const [searchedFollowings, setSearchedFollowings] = useState([]);
  const [searchedPendings, setSearchedPendings] = useState([]);
  const [searchedRequests, setSearchedRequests] = useState([]);
  const [searchedMutuals, setSearchedMutuals] = useState([]);


  // get follwers
  const getFollowers = (query, skip, profileUserId) => {
    axios.post(`${BASEAPIURL}/user/getFollowers?query=${query}&skip=${skip}`, { profileUserId }, config)
    .then(res => {
      // console.log('res : ', res.data);

      if(query === '') {
        if(skip === 0) {
          setFollowers(res.data.followers);
        } else {
          setFollowers(prevFollowers => [...prevFollowers, ...res.data.followers]);
        };
      } else {
        if(skip === 0) {
          setSearchedFollowers(res.data.followers);
        } else {
          setSearchedFollowers(prevFollowers => [...prevFollowers, ...res.data.followers]);
        };
      };

      // to hide load more button when data end
      if(res.data.followers.length === 0) {
        setShowFollowersLoadMore(false);
      } else {
        setShowFollowersLoadMore(true);
      };
    })
    .catch(err => {
      console.log('err : ', err);
    });
  };

  // get followings
  const getFollowings = (query, skip, profileUserId) => {
    axios.post(`${BASEAPIURL}/user/getFollowings?query=${query}&skip=${skip}`, { profileUserId }, config)
    .then(res => {
      // console.log('res : ', res.data);

      if(query === '') {
        if(skip === 0) {
          setFollowings(res.data.followings);
        } else {
          setFollowings(prevFollowings => [...prevFollowings, ...res.data.followings]);
        };
      } else {
        if(skip === 0) {
          setSearchedFollowings(res.data.followings);
        } else {
          setSearchedFollowings(prevFollowings => [...prevFollowings, ...res.data.followings]);
        };
      };

      // to hide load more button when data end
      if(res.data.followings.length === 0) {
        setShowFollowingsLoadMore(false);
      } else {
        setShowFollowingsLoadMore(true);
      };
    })
    .catch(err => {
      console.log('err : ', err);
    });
  };

  // get pendings
  const getPendings = (query, skip) => {
    axios.post(`${BASEAPIURL}/user/getPendings?query=${query}&skip=${skip}`, {}, config)
    .then(res => {
      // console.log('res : ', res.data);

      if(query === '') {
        if(skip === 0) {
          setPendings(res.data.pendings);
        } else {
          setPendings(prePendings => [...prePendings, ...res.data.pendings]);
        };
      } else {
        if(skip === 0) {
          setSearchedPendings(res.data.pendings);
        } else {
          setSearchedPendings(prePendings => [...prePendings, ...res.data.pendings]);
        };
      };

      // to hide load more button when data end
      if(res.data.pendings.length === 0) {
        setShowPendingsLoadMore(false);
      } else {
        setShowPendingsLoadMore(true);
      };
    })
    .catch(err => {
      console.log('err : ', err);
    });
  };

  // get requests
  const getRequests = (query, skip) => {
    axios.post(`${BASEAPIURL}/user/getRequests?query=${query}&skip=${skip}`, {}, config)
    .then(res => {
      // console.log('res : ', res.data);

      if(query === '') {
        if(skip === 0) {
          setRequests(res.data.requests);
        } else {
          setRequests(preRequests => [...preRequests, ...res.data.requests]);
        };
      } else {
        if(skip === 0) {
          setSearchedRequests(res.data.requests);
        } else {
          setSearchedRequests(preRequests => [...preRequests, ...res.data.requests]);
        };
      };

      // to hide load more button when data end
      if(res.data.requests.length === 0) {
        setShowRequestsLoadMore(false);
      } else {
        setShowRequestsLoadMore(true);
      };
    })
    .catch(err => {
      console.log('err : ', err);
    });
  };

  // get mutuals
  const getMutuals = (query, skip, profileUserId) => {
    axios.post(`${BASEAPIURL}/user/getMutuals?query=${query}&skip=${skip}`, { otherUserId: profileUserId }, config)
    .then(res => {
      console.log('res : ', res.data);

      if(query === '') {
        if(skip === 0) {
          setMutuals(res.data.mutuals);
        } else {
          setMutuals(prevMutuals => [...prevMutuals, ...res.data.mutuals]);
        };
      } else {
        if(skip === 0) {
          setSearchedMutuals(res.data.mutuals);
        } else {
          setSearchedMutuals(prevMutuals => [...prevMutuals, ...res.data.mutuals]);
        };
      };

      // to hide load more button when data end
      if(res.data.mutuals.length === 0) {
        setShowMutualsLoadMore(false);
      } else {
        setShowMutualsLoadMore(true);
      };
    })
    .catch(err => {
      console.log('err : ', err);
    });
  };







  // useEffect(() => {
  //   getWorkDetails();
  // }, [])


  const value = {

    // ----- Profile > User -----
    showFollowerFollowingTab, setShowFollowerFollowingTab,
    showFollowerFollowingPopup, setShowFollowerFollowingPopup,

    showFollowersLoadMore, setShowFollowersLoadMore,
    showFollowingsLoadMore, setShowFollowingsLoadMore,
    showPendingsLoadMore, setShowPendingsLoadMore,
    showRequestsLoadMore, setShowRequestsLoadMore,
    showMutualsLoadMore, setShowMutualsLoadMore,

    followersSearchText, setFollowersSearchText,
    followingsSearchText, setFollowingsSearchText,
    pendingsSearchText, setPendingsSearchText,
    requestsSearchText, setRequestsSearchText,
    mutualsSearchText, setMutualsSearchText,

    followers, setFollowers,
    followings, setFollowings,
    pendings, setPendings,
    requests, setRequests,
    mutuals, setMutuals,

    searchedFollowers, setSearchedFollowers,
    searchedFollowings, setSearchedFollowings,
    searchedPendings, setSearchedPendings,
    searchedRequests, setSearchedRequests,
    searchedMutuals, setSearchedMutuals,

    getFollowers,
    getFollowings,
    getPendings,
    getRequests,
    getMutuals,



    // ----- Profile > Edit -----
    profileEditViewValues, setProfileEditViewValues,
    getProfileEditView,



    social, setSocial,
    saveLoader, setSaveLoader,
    address, userProfile, setUserProfile,
    setAddress,
    educationDetails, setEducationDetails,
    zodiacVal, setzodiacVal,
    bioInput, selectedHobbies, setSelectedHobbies,
    setBioInput,
    workData, setWorkData,
    hangoutDetails, setHangoutDetails,
    skillData, setSkillData, workInput, setworkInput,
    movieDetails, setMovieDetails,
    foodDetails, setfoodDetails,
    relationsData, setRelationsData,
    profilePostData, setProfilePostData,
    profileReelData, setProfileReelData,
    profileBestclipData, setProfileBestclipData,
    profileMemeData, setProfileMemeData,
  }


  return <profileContext.Provider value={value}>{children}</profileContext.Provider>
}