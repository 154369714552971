import React, { useContext } from "react";
import ProfileUser from "./ProfileUser/ProfileUser";
import ProfileNavigate from "./ProfileNavigate/ProfileNavigate";
import { Outlet, useParams } from "react-router-dom";
import { ApiContext } from "../UseContext/ApiCall";
import AboutUser from "./ProfileUser/ProfileUserShare/AboutUser";


const Profile = () => {

  const { switchAbout } = useContext(ApiContext);

  document.title = "Emilo-profile";

  const {paramsUserId} = useParams();
  
  return (
    <>
      <section className="border border-white ">
        <ProfileUser paramsUserId={paramsUserId} />

        {
          !switchAbout 
          ? 
          <>  
            <ProfileNavigate profileUserId={paramsUserId} />
            <Outlet />
          </> 
          : 
          <AboutUser paramsUserId={paramsUserId} />
        }
        
        {/* <BackToTop /> */}
      </section>
    </>
  );
}

export default Profile;
