import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../../../config';
import Slider from 'react-slick';
import { ApiContext } from '../../../UseContext/ApiCall';
import ProfilePostThreeDotPopup from './ThreeDot/ProfilePostThreeDotPopup';
import ProfilePostCommentThreeDotPopup from './CommentThreeDot/ProfilePostCommentThreeDotPopup';
// import ReactPlayer from 'react-player';
import VideoTag from "../../../../Common/assets/videoTag";
import ImageTag from "../../../../Common/assets/imageTag";
import FavouritePostButton from '../../../Button/FavouriteButton/FavouritePostButton';
import AddComments from '../../../Button/AddComments';
import LikeButton from '../../../Button/LikeButton/LikeButton';
import ViewPostButton from '../../../Button/ViewButton/ViewPostButton';
import Userpopup from '../../Userpopup';

const ProfilePostPopup = ({ postId, showPostsPopup, setShowPostsPopup, postsPopupData, setPostsPopupData, handlePostShare }) => {
  const [showProfilePostThreeDotPopup, setShowProfilePostThreeDotPopup] = useState(false);
  const [showProfilePostCommentThreeDotPopup, setShowProfilePostCommentThreeDotPopup] = useState(false);

  const { getPostComment, comments, setComments, parentSpinner, setParentSpinner,  loggedInUserId } = useContext(ApiContext);


  //------------------- comment states-----------------
  //const [postId, setPostId] = useState('') // memeID , bestClipId , reelId

  const [clickedreply, setClickedReply] = useState(false)
  const [viewReply, setViewReply] = useState(false)
  const [comment, setComment] = useState();
  const [showPopup, setShowPopup] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    setClickedReply(false)
    setViewReply(false)
    // getPostComment(postId, config)
  }, [postsPopupData]);

  // To add post to favourite
  const settings = {
    dots: true,
    className: "profilePostsPopup-post-slider",
    infinite: false,
    // centerPadding: "60px",
    slidesToShow: 1,
    swipeToSlide: true,
    afterChange: (index) => setActiveIndex(index),
  };

  const handleThreeDotsClick = (post) => {
   if (loggedInUserId !== post.postedBy) {
     
     setShowProfilePostThreeDotPopup(true);
   }else{
    setShowPopup(true)

   };
 };




  return (
    <>

      {/* Profile Photos And Videos Three Dot Popup */}
      <ProfilePostThreeDotPopup showProfilePostThreeDotPopup={showProfilePostThreeDotPopup} setShowProfilePostThreeDotPopup={setShowProfilePostThreeDotPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={postsPopupData} setPostsPopupData={setPostsPopupData} />
      {postsPopupData && <Userpopup showPopup={showPopup} setShowPopup={setShowPopup} postsPopupData={postsPopupData} /> }

      {/* Profile Photos And Videos Comment Three Dot Popup */}
      <ProfilePostCommentThreeDotPopup showProfilePostCommentThreeDotPopup={showProfilePostCommentThreeDotPopup} setShowProfilePostCommentThreeDotPopup={setShowProfilePostCommentThreeDotPopup} comment={comment} />
      <ProfilePostCommentThreeDotPopup showProfilePostCommentThreeDotPopup={showProfilePostCommentThreeDotPopup} setShowProfilePostCommentThreeDotPopup={setShowProfilePostCommentThreeDotPopup} comment={comment} getPostComment={getPostComment} />


      {/* Profile Photos And Videos Popup */}
      <Modal show={showPostsPopup} onHide={() => setShowPostsPopup(false)} size="xl" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          {
            postsPopupData
            &&
            <section className="profilePostsPopup">
              {/* Photos And Videos */}
              <section className="profilePostsPopup-post">
                <Slider {...settings} ref={sliderRef}>
                  {
                    postsPopupData.mediaUrls
                    &&
                    postsPopupData.mediaUrls.map((details, index) => (

                      details.filetype === 'image'
                        ?
                        <div key={index} className="profilePostsPopup-post-pav">
                          {/* {console.log("postsPopupData", postsPopupData)} */}
                          <ImageTag
                            classHere="profilePostsPopup-post-image"
                            url={details.url}
                            altHere={details.imageId}
                            onError={(e) => e.target.src = `${BASE_URL_STATIC}/cp-3.png`}
                            styleHere={{ width: '100%', height: '100%' }}
                          />
                        </div>
                        :
                        <div key={index} className="profilePostsPopup-post-pav">
                          <VideoTag
                            controls={true}
                            url={details.url}
                            classHere="profilePostsPopup-post-video"
                            autoPlay={index === activeIndex}
                            mute={false}
                          />
                        </div>
                    ))
                  }
                </Slider>
              </section>

              {/* Details */}
              <section className="profilePostsPopup-details">
                {/* User */}
                <div className='profilePostsPopup-details-user'>
                  <ImageTag
                    classHere='profilePostsPopup-details-profile'
                    url={postsPopupData.imageId}
                    altHere={postsPopupData.postId}
                    styleHere={{ width: '50px', height: '50px', objectFit: 'cover' }}
                  />

                  <div className='profilePostsPopup-details-header'>
                    <div className='profilePostsPopup-details-head'>
                      <span className='profilePostsPopup-details-name'>{postsPopupData.fname}</span>
                      <span className='text-secondary fs-14'>{postsPopupData.username}</span>
                    </div>

                    {
                      postsPopupData.title
                      &&
                      <span className='profilePostsPopup-details-location'>{postsPopupData.title}</span>
                    }
                  </div>

                  <img className='profilePostsPopup-details-threeDot' src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot"  onClick={() =>handleThreeDotsClick( postsPopupData) }/>
                </div>

                <hr className="profilePostsPopup-details-hr" />

                {/* Comments */}
                <AddComments showProfilePostThreeDotPopup={showProfilePostThreeDotPopup} showProfilePostCommentThreeDotPopup={showProfilePostCommentThreeDotPopup} setShowProfilePostCommentThreeDotPopup={setShowProfilePostCommentThreeDotPopup} setComment={setComment} commentType={"post"} clickedreply={clickedreply} setClickedReply={setClickedReply} postId={postId} viewReply={viewReply} postsPopupData={postsPopupData} comments={comments} setComments={setComments} parentSpinner={parentSpinner} setParentSpinner={setParentSpinner} setPostsPopupData={setPostsPopupData} />


                <hr className="profilePostsPopup-details-hr" />

                {/* Area */}
                <div className='profilePostsPopup-details-area'>
                  <div className='flex'>
                    <div className='profilePostsPopup-details-area-div'>
                      {/* {
                        (postsPopupData.viewCount === 0)
                          ?
                          <img className='profilePostsPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/view-1.svg`} alt="view" />
                          :
                          <img className='profilePostsPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/view-2.svg`} alt="view" />
                      }
                      <span className='profilePostsPopup-details-area-count'>{postsPopupData.viewcount}</span> */}
                    {postsPopupData && postsPopupData._id && (
                      <ViewPostButton viewCount={postsPopupData.viewcount} postId={postsPopupData._id} />
                    )}
                    </div>

                    <div className='profilePostsPopup-details-area-div'>
                    {postsPopupData && postsPopupData._id && (
                   <LikeButton postId={postsPopupData._id} likecounts={postsPopupData.likecount} postType={postsPopupData.type} />
  
                    )}
                    </div>

                    <div className='profilePostsPopup-details-area-div'>
                      <img className='profilePostsPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/comment-1.svg`} alt="comment" />
                      <span className='profilePostsPopup-details-area-count'>{postsPopupData.commentcount}</span>
                    </div>

                    <div className='profilePostsPopup-details-area-div'>
                      <img onClick={handlePostShare} className='profilePostsPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/share-1.svg`} alt="share" />
                      <span className='profilePostsPopup-details-area-count'>{postsPopupData.sharecount}</span>
                    </div>
                  </div>

                  <div className='flex'>
                    <div className='profilePostsPopup-details-area-favourite'>
                      <FavouritePostButton postId={postsPopupData.postId} />
                    </div>

                    <img className='profilePostsPopup-details-area-download' src={`${BASE_URL_STATIC}/icon/download-1.svg`} alt="download" />
                  </div>

                </div>

                {/* Description */}
                {postsPopupData && postsPopupData.details && postsPopupData.details.length > 0 && <div className='profilePostsPopup-details-description'>{postsPopupData.description}</div>}
              </section>
            </section>
          }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ProfilePostPopup;
