import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import ProfileImage from '../../UseContext/ProfileImage';
// import AddLocation from './AddLocation';
import { ApiContext } from '../../UseContext/ApiCall';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import PostSettings from '../../Button/PostSettings';
import EmojiPopup from '../EmojiPopup';
import parse from 'node-html-parser';
import * as htmlToImage from 'html-to-image';
import NotificationpostPopup from '../Notification/NotificationpostPopup';
import { uploadpostUrl, uploadPost } from '../../UseContext/ApiHelpers';
import { IoCloseCircle } from 'react-icons/io5';
import TagPeople from '../../Button/TagPeople';
import TextPostPopup from './TextPostPopup';
import { createNotification } from "../../UseContext/ApiHelpers";


const TextPreviewPopoup = ({ selectedAddLocation, setAllbg, handleCancleLocation, setCheckIn, setCheckinType, open, allbg, contentRef, setSelectedEmoji, selectedEmoji, activeIndex, setActiveIndex, bgImages, setShowCreatePostPopup, setSelectedBg, selectedBg, PageContent, close }) => {

   const [postLoader, setPostLoader] = useState(false);
   const [openEmoji, setOpenEmoji] = useState(false)
   const [openInput, setopenInput] = useState(false)
   const [show, setShow] = useState(false);
   const [selectedUser, setSelectedUser] = useState([]);
   const [tagMessage, setTagmessage] = useState([])
   const [openEdit, setOpenEdit] = useState(false)

   const { loggedInUserId, setscAllposts, setPosts, postType, loggedInUserName, config, setallDataPost, partnerId, setPageContent } = useContext(ApiContext);

   const notificationdata = selectedUser.map(ids => ids.tagUserId);


   // const currentpage = useRef(null)
   const handleEdit = () => {
      setShowCreatePostPopup(true);
      close();
   };

   const refs = useRef()
   useEffect(() => {
      if (contentRef.current && PageContent.length <= 200) {
         setActiveIndex(1)
      } else {
         setActiveIndex(0)
      }

   }, [PageContent])


   const dataURLtoBlob = (dataURL) => {
      const parts = dataURL.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
         uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
   };

   const handleConvertToPng = async () => {
      setPostLoader(true)
      if (selectedBg.name !== '' && PageContent.length <= 200 && refs.current) {
         htmlToImage.toPng(refs.current, { cacheBust: true })
            .then(async (dataUrl) => {

               const blob = dataURLtoBlob(dataUrl);
               const formData = new FormData();
               formData.append("mediaUrls", blob, 'downloaded-image.png');
               formData.append("postTumbnailUrl", blob, 'downloaded-image.png');
               formData.append("audio", 'https://webaudioapi.com/samples/audio-tag/chrono.mp3')
               formData.append("start", 'ssss')
               formData.append("end", 'sss')
               await uploadpostUrl(formData, config).then(async (res) => {
                  const mediaPostData = {
                     title: selectedAddLocation && selectedAddLocation.location,
                     partnerUserId:partnerId ,
                     emotion: selectedEmoji && selectedEmoji.emotion,
                     emotionName: selectedEmoji && selectedEmoji.emotionName,
                     maintype:  postType,
                     emotionIcon: selectedEmoji && selectedEmoji.icon,
                     postTumbnailUrl: res.postTumbnailUrl || '',
                     type: "post",
                     mediaUrls: [{
                        url: res && res.mediaUrls && res.mediaUrls[0].url,
                        filetype: 'image',
                        audio: '',
                        start: '',
                        end: '',
                        tags: selectedUser.map(user => ({
                           tagUserId: user.userId,
                           fname: user.fname,
                           username: user.username,
                           profileImg: user.profileImg,
                           postedBy: loggedInUserId,
                           x: '',
                           y: ''
                        }))
                     }]
                  };


                  const data = await uploadPost(mediaPostData, config)

                  async function createNotifications() {
                     for (let ids of notificationdata) {
                        const data1 = {
                           targetId: ids,
                           userType: "user",
                           targetEvent: "tag",
                           targetType: "post",
                           targetEventId: data.post._id,
                           notificationType: "email",
                        };

                        try {
                           await createNotification(data1, config);

                        } catch (error) {
                           console.error("Error creating notification for user", ids, error);
                        }
                     }
                  }
                  createNotifications();
                  if (postType === 'postType') {
                     setallDataPost(prev => [data.post, ...prev]);
                  } else if (postType === 'scType') {
                     setscAllposts(prev => [data.post, ...prev])
                  } else {
                     setPosts(prev => [data.post, ...prev])
                  }
                  close()
                  setPostLoader(false)
                  setPageContent('')
                  setSelectedBg({})
               })

            })
            .catch((error) => {
               setPostLoader(false)
               console.error('Error converting HTML to PNG:', error);
            });
      }
      else {
         const mediaPostData = {
            description: PageContent,
            title: (selectedAddLocation && selectedAddLocation.location) || "",
            type: PageContent.length > 0 ? "text" : "emotion",
            emotion: selectedEmoji && selectedEmoji.emotion,
            partnerUserId:partnerId,
            emotionName: selectedEmoji && selectedEmoji.emotionName,
            emotionIcon: selectedEmoji && selectedEmoji.icon,
            maintype: postType,
            mediaUrls: [{
               tags: selectedUser.map(user => ({
                  tagUserId: user.userId,
                  fname: user.fname,
                  username: user.username,
                  profileImg: user.profileImg,
                  postedBy: loggedInUserId,
                  x: '',
                  y: ''
               }))
            }]
         };
         const data = await uploadPost(mediaPostData, config)



         async function createNotifications() {
            for (let ids of notificationdata) {
               const data1 = {
                  targetId: ids,
                  userType: "user",
                  targetEvent: "tag",
                  targetType: "post",
                  targetEventId: data.post._id,
                  notificationType: "email",
               };
               console.log("data._id", data._id)

               try {
                  await createNotification(data1, config);

               } catch (error) {
                  console.error("Error creating notification for user", ids, error);
               }
            }
         }
         createNotifications();
         if (postType === 'postType') {
            setallDataPost(prev => [data.post, ...prev]);
         } else {
            setPosts(prev => [data.post, ...prev])
         }
         close()
         setPostLoader(false)
         setPageContent('')
         setSelectedBg({})
      }
   };

   var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 5,
               slidesToScroll: 3,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 2,
               initialSlide: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 2
            }
         }
      ]
   };

   const handleText = () => {
      const root = parse(PageContent);
      setPageContent(root.textContent);
   }


   const handleBgErr = () => {

      if (PageContent.length < 200) {
         setOpenEdit(true)
      }
      else if (PageContent.length > 200) {
         setOpenEdit(false)
      }
   }

   return (

      <>
         <NotificationpostPopup show={show} setShow={setShow} />
         <Modal show={open} onHide={() => { close(); setPageContent('') }} keyboard={false} className="create-post" backdrop="static" centered>
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body>
               {
                  openEmoji ?
                     <>
                        <div className="row">
                           <p className="col-1" style={{ cursor: "pointer" }}
                              onClick={() => { setOpenEmoji(false) }}
                           >
                              <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                           </p>
                           <p className="col-11 fs-6 text-black fw-bold text-center mb-2">
                              Create Post
                           </p>
                        </div>
                        <EmojiPopup setOpenEmoji={setOpenEmoji} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} />
                     </>
                     :
                     <>
                        <div className="row">
                           <p className="col-1" style={{ cursor: "pointer" }}
                              onClick={() => { close(); setShowCreatePostPopup(true) }}
                           >
                              <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                           </p>
                           <p className="col-11 fs-6 text-black fw-bold text-center mb-2">
                              Create Post
                           </p>
                        </div>

                        <div className="userDetail">
                           <div className="imgBox" >
                              <ProfileImage userId={loggedInUserId} height='50' width='50' borderRadius='50%' />
                           </div>

                           <div className="nameBox">
                              <div className="d-flex flex-md-row align-items-center">
                                 <span className="fs-6">
                                    {loggedInUserName}
                                    {selectedEmoji && (
                                       <span className="ms-1 d-inline-block fs-14 text-secondary" lick={() => setOpenEmoji(true)}>
                                          {selectedEmoji.emotion}
                                          <img style={{ width: "20px", display: "inline", marginLeft: "5px", marginRight: "5px" }} src={selectedEmoji.icon} alt="icon" />
                                          {selectedEmoji.emotionName}{" "}
                                          <span>{tagMessage}</span>
                                       </span>
                                    )}
                                 </span>
                              </div>

                              <div className="add-location-div">
                                 <i className="fas fa-map-marker-alt" style={{ padding: '3px 3px 3px 0px' }} />
                                 {/* <AddLocation location={location} setLocation={setLocation} /> */}
                                 {selectedAddLocation ?
                                    <>
                                       <small className="text-truncate" style={{ maxWidth: "300px", cursor: 'pointer' }}>{selectedAddLocation.place}</small>
                                       <span className="fs-5 cp" style={{ color: 'rgb(207 99 109)' }} onClick={handleCancleLocation}> <IoCloseCircle /></span>
                                    </>
                                    :
                                    <small className="cp" onClick={() => { setCheckIn(true); setShowCreatePostPopup(false); setCheckinType("text") }}>Add Location</small>
                                 }
                              </div>
                           </div>
                        </div>
                        {/* <p className='text-primary fw-bold cp text-end mb-2'> */}
                        <p className='d-flex justify-content-between align-items-center fw-bold mb-2'>
                           {openEdit ?
                              <span className='cp text-primary fs-14' onClick={() => setOpenEdit(false)}>Done</span>
                              :
                              <span
                                 className={`${(PageContent.length >= 1 && PageContent.length <= 200) ? 'op-0' : ''} cp`}
                                 onClick={handleBgErr}
                              >
                                 <FontAwesomeIcon icon={faPenToSquare} /> edit
                              </span>
                           }
                           {postType !== "scType" && <span className='text-primary cp' onClick={() => setopenInput(!openInput)}>
                              Tag People
                           </span>}
                        </p>

                        {openEdit && <TextPostPopup allbg={allbg} setAllbg={setAllbg} PageContent={PageContent} setPageContent={setPageContent} />}

                        {!openEdit && openInput && <TagPeople setTagmessage={setTagmessage} selectedUser={selectedUser} setSelectedUser={setSelectedUser} config={config} />}

                        {!openEdit && !openInput && PageContent !== "" &&
                           <>
                              <div
                                 className='html_png'
                                 ref={refs}
                                 style={{
                                    backgroundImage: PageContent.length < 200 && selectedBg && selectedBg.img !== '' ? `url(${selectedBg.img})` : '',
                                    backgroundSize: 'cover', // or 'contain' depending on your needs
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    Height: '200px'
                                 }}
                              >
                                 <div
                                    onClick={handleEdit}
                                    className="bg_style"
                                    style={{ overflow: 'hidden' }} // Hide scrollbar
                                 >
                                    <div
                                       className={`postBox position-relative ${!allbg && activeIndex === 0 ? "postBox_1" : "postBox_3"}`}
                                       style={{ overflow: 'hidden' }} // Hide scrollbar
                                    >
                                       <div
                                          className={`text-editor2 d-flex ${PageContent.length < 200 ? `page_edit1` : 'page_edit2'}`}
                                       >
                                          <div
                                             className={`text_opener ${activeIndex === 0 ? 'h-100 m-0 justify-content-start white_pre' : 'm-auto justify-content-start white_pre'} ${PageContent.length < 200 ? 'white_nowrap' : 'white_pre text-start'}`}
                                             dangerouslySetInnerHTML={{ __html: PageContent }}
                                          ></div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="bg_image_head">
                                 {PageContent.length < 200 && <div className="slider-container">
                                    <Slider {...settings}>
                                       {bgImages.map((e, i) => (
                                          <div
                                             key={i}
                                             className="bg-images"
                                             onClick={() => {

                                             }}
                                          >
                                             {e.img === '' ?
                                                <span className="no_bg cp" onClick={() => { handleText(); setSelectedBg(e); setActiveIndex(0); }} >
                                                   <img src='/images/none.jpg' alt="" style={{
                                                      border: activeIndex === i && "2px solid #64A6F0",
                                                   }} />
                                                </span>
                                                :
                                                <img onClick={() => { setSelectedBg(e); setActiveIndex(i); }} src={e.img} alt="" style={{
                                                   border: activeIndex === i && "2px solid #64A6F0",
                                                }} />}
                                          </div>
                                       ))}
                                    </Slider></div>}
                              </div>

                           </>}

                        {!openEdit && <PostSettings />}

                        {!openEdit && <div className='col-8 m-auto'>
                           <button onClick={handleConvertToPng} className='text_post_btn'>{postLoader ? 'posting...' : "post"}</button>
                        </div>}


                     </>}

            </Modal.Body>

         </Modal>
      </>
   );
};

export default TextPreviewPopoup;